import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import Login from "@/view/Login"
import Home from '@/view/Home'
import ARSearch from '@/view/ARSearch'
import Default from '@/view/Default'
import Deliver from '@/view/Deliver'
import DeliverDelay from '@/view/DeliverDelay'
import DeliverSearch from '@/view/DeliverSearch'
import DeliverSearchDetail from '@/view/DeliverSearchDetail'
import ReturnQuery from '@/view/ReturnQuery'
import ReturnQueryDetail from '@/view/ReturnQueryDetail'
import SortFile from '@/view/SortFile'
import InspectionResults from '@/view/InspectionResults'
import OperationMenu from '@/view/OperationMenu'
import Order from '@/view/Order'
import OrderDetailSearch from'@/view/OrderDetailSearch'
import OrderChangeSearch from '@/view/OrderChangeSearch'
import ReplyDeliveryTime from '@/view/ReplyDeliveryTime'
import OrderExecution from '@/view/OrderExecution'
import OrderDetail from '@/view/OrderDetail'
import Reconciliation from '@/view/Reconciliation'
import ReconciliationDetail from '@/view/ReconciliationDetail'
import SupplyAssessment from '@/view/SupplyAssessment'
import DeliverCar from '@/view/DeliverCar'
import DeliverSuccess from '@/view/DeliverSuccess'
import HelpCenter from '@/view/HelpCenter'
import HelpCenterDetail from '@/view/HelpCenterDetail'
import DownloadPrint from '@/view/DownloadPrint'
import BulletinCenter from '@/view/BulletinCenter'
import BulletinCenterDetail from '@/view/BulletinCenterDetail'
import Information from '@/view/Information'

var routes = [
  {
    path: '/',
    redirect: '/Login'
  },
  {
    path: '/Login',
    component: Login,
    meta: {
      title: "登录"
    }
  },
  {
    path: '/Home',
    component: Home,
    redirect: '/Home/Default',
    meta: {
      title: "首页",
    },
    children: [
      {
        name:'Default',
        path: '/Home/Default',
        component: Default,
        meta: {
          title: "首页"
        }
      }, {
        name:'Order',
        path: '/Home/Order',
        component: Order,
        meta: {
          title: "订单查询"
        }
      }, 
      {
        name:'ReplyDeliveryTime',
        path: '/Home/ReplyDeliveryTime',
        component: ReplyDeliveryTime,
        meta: {
          title: "回复交期"
        }
      },
      {
        name:'OrderChangeSearch',
        path: '/Home/OrderChangeSearch',
        component: OrderChangeSearch,
        meta: {
          title: "订单变更查询"
        }
      },
      {
        name:'OrderDetailSearch',
        path: '/Home/OrderDetailSearch',
        component: OrderDetailSearch,
        meta: {
          title: "订单明细查询"
        }
      },{
        name:'OrderDetail',
        path: '/Home/OrderDetail',
        component: OrderDetail,
        meta: {
          title: "订单详情"
        }
      }, {
        name:'Deliver',
        path: '/Home/Deliver',
        component: Deliver,
        meta: {
          title: "发货"
        }
      }, {
        name:'DeliverSearch',
        path: '/Home/DeliverSearch',
        component: DeliverSearch,
        meta: {
          title: "发货查询"
        }
      }, {
        name:'DeliverSearchDetail',
        path: '/Home/DeliverSearchDetail',
        component: DeliverSearchDetail,
        meta: {
          title: "发货单详情"
        }
      }, {     
        name:'ReturnQuery',     
        path: '/Home/ReturnQuery',
        component: ReturnQuery,
        meta: {
          title: "退货查询"
        }
      }, {
        name:'ReturnQueryDetail', 
        path: '/Home/ReturnQueryDetail',
        component: ReturnQueryDetail,
        meta: {
          title: "退货详情"
        }
      }, {
        name:'Reconciliation', 
        path: '/Home/Reconciliation',
        component: Reconciliation,
        meta: {
          title: "对账查询"
        }
      }, {
        name:"ReconciliationDetail",
        path: '/Home/ReconciliationDetail',
        component: ReconciliationDetail,    
        meta: {
          title: "对账详情"
        }
      }, {
        name:"ARSearch",
        path: '/Home/ARSearch',
        component: ARSearch,
        meta: {
          title: "应收查询"
        }
      }, {
        name:"OrderExecution",
        path: '/Home/OrderExecution',
        component: OrderExecution,
        meta: {
          title: "订单执行状况"
        }
      }, {
        name:"InspectionResults",
        path: '/Home/InspectionResults',
        component: InspectionResults,
        meta: {
          title: "检验结果"
        }
      }, {
        name:"SupplyAssessment",
        path: '/Home/SupplyAssessment',
        component: SupplyAssessment,
        meta: {
          title: "供货考核表"
        }
      }, {
        name:"DeliverDelay",
        path: '/Home/DeliverDelay',
        component: DeliverDelay,
        meta: {
          title: "交货延迟分析表"
        }
      }, {
        name:"OperationMenu",
        path: '/Home/OperationMenu',
        component: OperationMenu,
        meta: {
          title: "操作菜单"
        }
      }, {
        name:"SortFile",
        path: '/Home/SortFile',
        component: SortFile,
        meta: {
          title: "分类文件"
        }
      }, {
        name:"DownloadPrint",
        path: '/Home/DownloadPrint',
        component: DownloadPrint,
        meta: {
          title: "进行中订单下载"
        }
      }, {
        name:"DeliverCar",
        path: '/Home/DeliverCar',
        component: DeliverCar,
        meta: {
          title: "发货车"
        }
      }, {
        name:"DeliverSuccess",
        path: '/Home/DeliverSuccess',
        component: DeliverSuccess,
        meta: {
          title: "发货成功"
        }
      }, {
        name:"HelpCenter",
        path: '/Home/HelpCenter',
        component: HelpCenter,
        meta: {
          title: "帮助中心"
        }
      }, {
        name:"HelpCenterDetail",
        path: '/Home/HelpCenterDetail',
        component: HelpCenterDetail,
        meta: {
          title: "文章详情"
        }
      },{
        name:"BulletinCenter",
        path: '/Home/BulletinCenter',
        component: BulletinCenter,
        meta: {
          title: "公告中心"
        }
      },{
        name:"BulletinCenterDetail",
        path: '/Home/BulletinCenterDetail',
        component: BulletinCenterDetail,
        meta: {
          title: "公告详情"
        }
      },{
        name:"Information",
        path:'/Home/Information',
        component: Information,
        meta:{
          title:'消息'
        }
      },{
        name:"OperationMenu",
        path:'/Home/OperationMenu-*',
        component:OperationMenu
      }
    ]
  },
  {
    path: '/DialogChangePwd', //修改密码弹窗组件
    name:'DialogChangePwd',
    // component:() => import('@/components/DialogChangePwd') //DialogChangePwd
    component:(resolve) => require(['@/components/DialogChangePwd'],resolve) //DialogChangePwd
  }
];
/**
 * 用于二开重写页面routes的方法
 * @param {*} routes 路由对象
 * @param {*} componentArray 组件数组
 * @returns 修改后的路由对象
 */
function OverrideHomeRoutes(routes,componentArray){
  if(componentArray&&componentArray.length>0){
    routes[2].children.forEach(route => {
      componentArray.forEach(ca =>{
        if(route.name == ca.name){
          route.component = ca.component;
          return;
        }
      })
    });
  }
  return routes;
}
/**
 * 完整配置路由的方法 开放出来给二开router调用
 * @param {*} routes 路由
 * @param {*} componentArray 组件数组
 * @returns 用于启动的路由
 */
function startrouter(routes,componentArray){
  var overrideroutes = OverrideHomeRoutes(routes,componentArray)
  //vue-router 实例上的 push 方法返回的是 promise 对象，所以传入的参数期望有一个成功和失败的回调，如果省略不写则会报错。
  const originalPush = Router.prototype.push
  Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
  }
  const router = new Router({
    routes: overrideroutes
  })
  router.afterEach((to, from) => {
    if (to.meta.title) {
      document.title = to.meta.title
    } else {
      document.title = "供应商平台"
    }
  })
  return router;
}
var router = startrouter(routes);




export { startrouter,router,routes}