<template>
  <Frame>
    <PageHeader :title="$t('comm.order')" :hasBack="false" />
    <div class="order" style="padding:10px 0;">
      <div class="ordermessage">
        <span class="detialmessage">{{ $t('Order.messagenumber') }}：<el-input :placeholder="$t('Order.inputpls')"
            v-model="billno" clearable style="width:15%;color:#bbbbbb;" size="small"
            @change="inputchange(billno);"></el-input></span>
        <!-- <span class="detialmessage" style="padding-left:100px">{{$t('Order.messagedate')}}：<el-input :placeholder="$t('Order.inputchoose')" suffix-icon="el-icon-date" v-model="date" clearable style="width:30%;color:#bbbbbb;"></el-input></span> -->
        <span class="detialmessage">{{ $t('Order.messagedate') }}：<el-date-picker size="small"
            v-model="billdate" type="daterange" style="width:15%;color:#bbbbbb;" range-separator="~" @change="getTime()" :default-value="timeDefault"
            :start-placeholder="$t('comm.startdate')" :unlink-panels="true" :end-placeholder="$t('comm.enddate')"></el-date-picker></span>
        <span class="detialmessage">{{ $t('Order.compName') }}：<el-input :placeholder="$t('Order.inputpls')"
          v-model="compName" clearable style="width:15%;color:#bbbbbb;" size="small"
          @change="inputchange(compName);"></el-input>
        </span>
        <!-- ADV202307170005 供应商平台-是否含税栏位显示控件优化 -->
        <span class="detialmessage">
          {{ $t('comm.withtaxState') }}:&nbsp;&nbsp;<el-checkbox v-model="iswithtax" @change="changeTaxState()" class="checkBox"></el-checkbox>
        </span>
      </div>
      <div class="ordermessage">
        <span class="detialmessage">{{ $t('Order.checkstate') }}：</span>
        <span style="padding-left:20px">
          <el-checkbox @change="getorderdata(0);" v-model="unsignedconfirmation"
            :label="$t('Order.unsignedconfirmation')"></el-checkbox>
          <el-checkbox @change="getorderdata(0);" v-model="unconfirmedchanges"
            :label="$t('Order.unconfirmedchanges')"></el-checkbox>
          <el-checkbox @change="getorderdata(0);" v-model="confirm" :label="$t('Order.confirm')"></el-checkbox>
          <el-checkbox @change="getorderdata(0);" v-model="unshipped" :label="$t('button.veto')"></el-checkbox>
        </span>
      </div>
      <div class="ordermessage">
        <span class="detialmessage">{{ $t('Order.goodsstate') }}：</span>
        <span style="padding-left:20px">
          <el-checkbox @change="getorderdata(0);" v-model="unshippe" :label="$t('Order.unshipped')"></el-checkbox>
          <el-checkbox @change="getorderdata(0);" v-model="partialDel" :label="$t('Order.partialDel')"></el-checkbox>
          <el-checkbox @change="getorderdata(0);" v-model="allshipments"
            :label="$t('Order.allshipments')"></el-checkbox>
        </span>
        <span style="float:right;padding-right:20px;padding-bottom:20px;margin-top: -16.5px;">
          <el-button type="reset" @click="reset()">{{ $t('button.reset') }}</el-button>
          <el-button type="primary" @click="query()">{{ $t('button.query') }}</el-button>
        </span>
      </div>
      <el-table ref="multipleTable" :data="tableData" :height="tableHeight" style="margin:0;width:100%"
        v-loading="tableLoading" tooltip-effect="dark" @selection-change="handleSelectionChange" :header-cell-style="moneyclass">
        <el-table-column type="selection"></el-table-column>
        <el-table-column prop="billno" :label="$t('Order.messagenumber')">
          <template scope="scope">
          <el-link :underline="false" @click="check(scope.$index, scope.row)">{{ scope.row.billno }}</el-link>
        </template>
        </el-table-column>
        <el-table-column prop="billdate" :label="$t('Order.messagedate')"></el-table-column>
        <el-table-column prop="CompName" :label="$t('Order.compName')"></el-table-column>
        <el-table-column align="right" prop="amount" :label="$t('Order.amount')">
          <template scope="scope"><span class="moneyornumber">{{ scope.row.amount }}</span></template>
        </el-table-column>
        <el-table-column prop="checkstate" :label="$t('Order.checkstate')">
          <template slot-scope="scope">
            <span :style="scope.row.checkstate == 0 ? 'display:block' : 'display:none'">
              <span class="stateborder" style="color:#e54737;">{{ $t('Order.unsignedconfirmation') }}</span>
            </span>
            <span :style="scope.row.checkstate == 1 ? 'display:block' : 'display:none'">
              <span class="stateborder" style="color:#1aa97b;">{{ $t('Order.confirm') }}</span>
            </span>
            <span :style="scope.row.checkstate == 2 ? 'display:block' : 'display:none'">
              <span class="stateborder" style="color:#e54737;">{{ $t('Order.unconfirmedchanges') }}</span>
            </span>
            <span :style="scope.row.checkstate == 3 ? 'display:block' : 'display:none'">
              <span class="stateborder" style="color:rgb(149 149 149);">{{ $t('button.veto') }}</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="goodsstate" :label="$t('Order.goodsstate')">
          <template slot-scope="scope">
            <span :style="scope.row.goodsstate == 0 ? 'display:block' : 'display:none'">
              <img src="../assets/img/border/unshipped.png" style="padding:0 10px 4px 0">
              {{ $t('Order.unshipped') }}</span>
            <span :style="scope.row.goodsstate == 1 ? 'display:block' : 'display:none'">
              <img src="../assets/img/border/partialdel.png" style="padding:0 10px 4px 0">
              {{ $t('Order.partialDel') }}</span>
            <span :style="scope.row.goodsstate == 2 ? 'display:block' : 'display:none'">
              <img src="../assets/img/border/shipped.png" style="padding:0 10px 4px 0">
              {{ $t('Order.shipped') }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('Order.operation')">
          <template slot-scope="scope">
            <el-button class="topbutton" size="mini" type="text" @click="check(scope.$index, scope.row)">
              {{ $t('button.check') }}
            </el-button>
            <el-button v-if="scope.row.CurrentState == 2 &&scope.row.checkstate!=1" class="topbutton" size="mini"
              type="text" @click="affirm(scope.$index, scope.row)">
              {{ $t('button.affirm') }}
            </el-button>
            <el-button v-if="scope.row.CurrentState == 2 &&scope.row.checkstate!=1" class="topbutton" size="mini"
              type="text" @click="veto(scope.$index, scope.row)">
              {{ $t('button.veto') }}
            </el-button>
          </template>
        </el-table-column>
        <!-- <template slot="append">
      <div style="padding:20px 0 10px 50px;">
        <el-button><img src="../assets/img/batchconfirmation.png" class="img">批量确认</el-button>
        <el-button><img src="../assets/img/batchshipment.png" class="img">批量发货</el-button>
        <el-button><img src="../assets/img/batchexport.png" class="img">批量导出</el-button>
      </div>
      </template> -->
      </el-table>
      <div style="width:100%;display:flex;">
        <div class="btnarray" >
          <el-checkbox @change="checkall()" v-model="checked"></el-checkbox>
          <span style="padding-left:15px;">
            <el-button @click="Batchconfirmation()">
              <img src="../assets/img/button/batchconfirmation.png" class="img">
              {{ $t('button.batchconfirmation') }}
            </el-button>
            <el-button @click="batchshipment()">
              <img src="../assets/img/button/batchshipment.png" class="img">
              {{ $t('button.batchshipment') }}
            </el-button>
            <el-button @click="exportdialog()" v-if="$store.state.exportExcelState">
              <img src="../assets/img/button/batchexport.png" class="img">
              {{ $t('button.batchexport') }}
            </el-button>
            <el-button id="print" @click="printdialog()">
              <img src="../assets/img/button/BatchPrint.png" class="img">
              {{ $t('button.batchPrint') }}
              </el-button>
          </span>
        </div>
        <el-pagination ref="pagination" style="width:50%;margin:20px 0 0 0;" @current-change="handleCurrentChange"
          layout="total,prev,pager,next,jumper" :pager-count="5" :current-page.sync="currentPage" :total="totalCount">
        </el-pagination>
      </div>

    </div>
    <el-dialog :visible.sync="batchconfirmation" width="20%" top="400px" :show-close="false" class="batchconfirmation">
      <span><i class="el-icon-success" style="font-size: 16px; color:rgb(26, 169, 123)"></i>   {{ $t('button.confirmationText') }}</span>
    </el-dialog>
    <el-dialog :title="$t('button.exporttitle')" :visible.sync="batchexport" width="25%">
      <el-radio v-model="radio" label="exportTextA" @change="changeRadio($event)" v-if="selectexport">{{ $t('button.exportTextA')}}</el-radio>
      <el-radio v-model="radio" label="exportTextB" @change="changeRadio($event)">{{ $t('button.exportTextB')}}</el-radio>
      <el-select style="width:100%;margin-top: 30px;" class="dialogselect" v-model="exportType"
            :placeholder="typegather.length != 0 ? $t('Order.exportPlaceHolder') : $t('Order.exportNoneholder')">
            <el-option v-for="item in typegather" :key="item.value" :label="item.name" :value="item.value">
            </el-option>
        </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel()">{{ $t('button.cancel') }}</el-button>
        <el-button type="primary" @click="determine(true)">{{ $t('button.determine') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog width="25%" :title="$t('OrderDetail.tip')" :visible.sync="printvisible">
      <el-radio v-model="printRadio" label="PrintTextA" @change="changePrintRadio($event)" v-if="selectprint">{{ $t('button.printTextA')}}</el-radio>
      <el-radio v-model="printRadio" label="PrintTextB" @change="changePrintRadio($event)">{{ $t('button.printTextB')}}</el-radio>
        <el-select style="width:100%;margin-top: 30px;" class="dialogselect" v-model="printType"
            :placeholder="typegather.length != 0 ? $t('OrderDetail.placeholder') : $t('OrderDetail.noneholder')">
            <el-option v-for="item in typegather" :key="item.value" :label="item.name" :value="item.value">
            </el-option>
        </el-select>
        <div slot="footer" class="dialog-footer">
            <el-button @click="determine()" type="primary">{{ $t('button.determine') }}</el-button>
        </div>
    </el-dialog>
  </Frame>
</template>

<script>
import SvgIcon from '../components/SvgIcon'
import { handleShortDate } from '../utils/handleDate.js'
import { Serialize, radioJudgeExcel } from '../utils/utils.js'
import { BillParams, PrintView } from '../utils/ReportPreView.js'
export default {
  name: 'Order',
  components: { SvgIcon },
  data() {
    return {
      tableLoading: false,
      batchexport: false,
      batchconfirmation: false,
      tableHeight: window.innerHeight - 361,
      compName: '',
      billno: '',
      billdate: '',
      timeDefault:'',
      radio: 'exportTextA',
      printRadio:'PrintTextA',
      checked: false,
      startdate: '',
      enddate: '',
      unshipped: false,
      unconfirmedchanges: true,
      confirm: false,
      unsignedconfirmation: true,
      unshippe: true,
      partialDel: true,
      allshipments: false,
      currentPage: 1,
      totalCount: 0,
      pagesize: 10,
      tableData: [],
      selectData: [],
      pageindex: 0,
      confirmtext:'',
      printvisible: false, //使用打印功能
      dialogvisible: false,
      dialogloading: true,
      dialogtitle: '',
      printtemp: '',
      typegather: [],
      progId: 'purPurchaseOrder',
      billOrgId: '',
      billTypeId: '',
      iswithtax:true,
      selectexport:false,
      selectprint:false,
      exportType:'',
      printType: '',
    }
  },
  created() {
    if (this.$route.query.partialDel && this.$route.query.partialDel.toLowerCase()=="true" && this.$route.query.unshippe && this.$route.query.unshippe.toLowerCase()=="true") {
      this.partialDel = true;
      this.unshippe = true;
      this.unsignedconfirmation = false;
      this.unconfirmedchanges = false;
    }
    if (this.$route.query.unsignedconfirmation && this.$route.query.unsignedconfirmation.toLowerCase()=="true" && this.$route.query.unconfirmedchanges && this.$route.query.unconfirmedchanges.toLowerCase()=="true") {
      this.unsignedconfirmation = true;
      this.unconfirmedchanges = true;
      this.partialDel = false;
      this.unshippe = false;
    }
    if (this.$route.query.date && this.$route.query.startdate && this.$route.query.enddate) {
      this.billdate = JSON.parse(this.$route.query.date);
      this.startdate = this.$route.query.startdate;
      this.enddate = this.$route.query.enddate;
    }
    if(this.$route.query.BillNo){
      this.billno = this.$route.query.BillNo;
    }
    if(this.$route.query.checkOption && this.$route.query.checkOption.toLowerCase() == "false"){
      this.unsignedconfirmation = false;
      this.unconfirmedchanges = false;
      this.partialDel = false;
      this.unshippe = false;
    }
    if (sessionStorage.getItem("OrderDetail") && sessionStorage.getItem("OrderDetail") != "null") {
      var history = JSON.parse(sessionStorage.getItem("OrderDetail"));
      this.billno = history.billno;
      this.compName = history.compName;
      this.billdate = history.billdate;
      this.startdate = history.startdate;
      this.enddate = history.enddate;
      this.unshipped = history.unshipped;
      this.unconfirmedchanges = history.unconfirmedchanges;
      this.confirm = history.confirm;
      this.unsignedconfirmation = history.unsignedconfirmation;
      this.unshippe = history.unshippe;
      this.partialDel = history.partialDel;
      this.allshipments = history.allshipments;
      this.pageindex = history.pageindex;
      this.getorderdata(history.pageindex);
      sessionStorage.removeItem("OrderDetail");
    }
    else this.getorderdata(0);
  },
  mounted() {
    let that = this;
    window.onresize = () => {
      if (that.resizeFlag) {
        clearTimeout(that.resizeFlag);
      }
      that.resizeFlag = setTimeout(() => {
        that.tableHeight = window.innerHeight - 361;
        that.resizeFlag = null;
      }, 100);
    }
    this.timeDefault =new Date();
    this.timeDefault.setMonth(new Date().getMonth()-1);

  },
  methods: {
    check(index, scope) {//查看
      sessionStorage.setItem("Order", JSON.stringify({
        pageindex: this.pageindex,
        billno: this.billno,
        compName: this.compName,
        startdate: this.startdate,
        enddate: this.enddate,
        billdate: this.billdate,
        unshipped: this.unshipped,
        unconfirmedchanges: this.unconfirmedchanges,
        confirm: this.confirm,
        unsignedconfirmation: this.unsignedconfirmation,
        unshippe: this.unshippe,
        partialDel: this.partialDel,
        allshipments: this.allshipments
      }))
      this.$router.push({
        path: "/Home/OrderDetail",
        query: {
          DelBillNo: scope.billno,
          operable: scope.CurrentState == 2 && scope.checkstate!=1 // ADV202307120008 新版供应商，当账户主数据有维护组织，那么平台上应该显示该单组织的数据
        }
      })
    },
    inputchange(val) {
      this.getorderdata(0);
    },
    getorderdata(pageindex) {
      this.currentPage = pageindex + 1;
      this.pageindex = pageindex;
      this.tableLoading = true;
      this.axiosInvoke("/SupplierComm/Handler/OrderPurPurchaseHandler.ashx", {
        action: "getorder",
        pageindex: pageindex,
        pagesize: this.pagesize,
        compName: this.compName,
        billno: this.billno,
        compName: this.compName,
        startdate: this.startdate,
        enddate: this.enddate,
        unshipped: this.unshipped,
        unconfirmedchanges: this.unconfirmedchanges,
        confirm: this.confirm,
        unsignedconfirmation: this.unsignedconfirmation,
        unshippe: this.unshippe,
        partialDel: this.partialDel,
        allshipments: this.allshipments,
        billOrgId:'',
        excel: '',
        iswithtax:this.iswithtax
      },
        (res) => {
          var data = eval(res.data);
          this.totalCount = data[0].totalCount;
          data.shift(0);
          this.tableData = data;
          this.tableLoading = false;
        })
    },
    changeTaxState(){
      this.getorderdata(0);
    },
    moneyclass({row,column,rowIndex,columnIndex}){
      if(rowIndex==0&&columnIndex==3){
        return "padding-right:40px;"
      }
    },
    reset() {//重置
      this.billno = "";
      this.compName = "";
      this.billdate = "";
      this.startdate = "";
      this.enddate = "";
      this.unshipped = false;
      this.unconfirmedchanges = true;
      this.confirm = false;
      this.unsignedconfirmation = true;
      this.unshippe = true;
      this.partialDel = true;
      this.allshipments = false;
      this.getorderdata(0);
    },
    query() {//查询
      console.log(this.billdate);
      debugger
      this.getorderdata(0);
      this.currentPage = 1;
    },
    affirm(index, scope) {//确认
      debugger
      var billNo = [];
      var SignSts = [];
      billNo.push(scope.billno);
      SignSts.push(scope.checkstate);
      this.affirmorder(billNo,false,SignSts);
    },
    Batchconfirmation() {//批量确认

      let billnos = [];
      let SignSts = [];
      if (this.selectData.length == 0) {
        this.$store.commit("TIPPOP",{popType:'warning',popMessage: this.$t("DeliverCar.message")});
      }
      else {
        let errbillnos = "";
        this.selectData.forEach((v) => {
          if(v.CurrentState==2) { // ADV202307120008 新版供应商，当账户主数据有维护组织，那么平台上应该显示该单组织的数据
            billnos.push(v.billno); 
            SignSts.push(v.checkstate);
          }  
          else{
            errbillnos += v.billno + " "
          }
        })
        if(errbillnos!=""){
          this.$store.commit("TIPPOP",{popType:'warning',popMessage:this.$t("Order.confirmerr").replace("{0}",errbillnos)});
        }
        if(billnos.length>0){
          this.affirmorder(billnos,true,SignSts);
        }
      }
    },
    affirmorder(billnos, batch,SignSts) {
      this.tableLoading = true;
      this.axiosInvoke("/SupplierComm/Handler/OrderPurPurchaseHandler.ashx", {
        action: "comfirmorder",
        ay: JSON.stringify(billnos),
        say:JSON.stringify(SignSts)
      },
        (res) => {
          var data = eval(res.data);
          this.tableLoading = false;
          this.tableData.forEach((v)=>{
            debugger
                data[0].forEach((dt)=>{
                  debugger
                  if(dt == v.billno){
                    v.checkstate = 1;
                  }
                })
              })
          if (batch) {//批量确认进入的逻辑
            if (data[0].length == billnos.length) {
              this.batchconfirmation = true;
              setTimeout(() => {
                if(this.batchconfirmation)
                this.batchconfirmation = false;
              }, 1500);
            }
            else {
              let errbillnos = "";
              data[1].forEach((v) => {
                errbillnos += v + " ";
              })
              this.$store.commit("TIPPOP",{popType:'warning',popMessage:this.$t("Order.confirmerr2").replace("{0}",errbillnos)});
            }
          }else{//单独确认进入的逻辑
            this.batchconfirmation = true;
            this.tableLoading = false;
            setTimeout(() => {
                if(this.batchconfirmation)
                this.batchconfirmation = false;
              }, 1500);
          }
        })
    },
    veto(index, scope) {//否决
      var billno = scope.billno;
      var orgSignSt = scope.checkstate;
      this.$prompt(this.$t("button.vetoreason"), this.$t("button.orderveto"), {
        confirmButtonText: this.$t("button.determine"),
        cancelButtonText: this.$t("button.cancel"),
      }).then(({ value }) => {
        console.log(value);
        this.tableLoading = true;
        this.axiosInvoke("/SupplierComm/Handler/OrderPurPurchaseHandler.ashx", {
        action: "denyorder",
        supReply:value,
        billno: billno,
        orgSignSt:orgSignSt
        },res=>{
          let data = eval(res.data);
          console.log(data);
          if(data[0].billno==scope.billno){
            scope.checkstate = 3;
            this.tableLoading = false;
            this.$store.commit("TIPPOP",{popType:'success',popMessage:this.$t("Order.vetoorder")});
          }
        });
      });
    },
    batchshipment() {//批量发货
      var billnos = [];
      if (this.selectData.length > 0) {
        var errbillnos = ""
        this.selectData.forEach((v) => {
          if(v.CurrentState != "2"){
            errbillnos += v.billno + ","
          }
          billnos.push({BillNo:v.billno});
        })
        if(errbillnos!=""){
          errbillnos = errbillnos.substring(0,errbillnos.lastIndexOf(","));
          this.$store.commit("TIPPOP",{popType:'warning',popMessage:this.$t("OrderDetailSearch.errcurrentstate").replace("{0}",errbillnos)});
          return;
        }
        sessionStorage.setItem("OrderToDeliverShipment", JSON.stringify(billnos));
        this.$router.push({
          path: "/Home/Deliver",
          query: {
            iswithtax:this.iswithtax,
            // ADV202309070016 新版供应商，发货页面行项状态提示信息增加控制，仅在订单清单批量发货时提示，其他的查询、添加发货车、直接发货后的数据刷新都不再检查和提示
            // BUG202309070027 新版供应商，存在行项状态为正常、转单冻结、终止的订单直接发发货，提示不统一——订单链接跳转访问才检查行项状态和提示
            checkRowState:true, // 检查行项状态，根据此标识为true检查和提示
          }
        })
      } else {
        this.$store.commit("TIPPOP",{popType:'warning',popMessage:this.$t("Deliver.warning")});
      }
    },
    cancel() {//取消
      this.batchexport = false;
    },
    changeRadio(val) {
      this.radio = val;
    },
    changePrintRadio(val){
      this.printRadio = val;
    },
    determine(isexcel) {//确定
      debugger
      if(this.exportType==""&&isexcel){
        this.$store.commit("TIPPOP",{popType:'warning',popMessage:this.$t("comm.selectexport")});
        return;
      }
      if(this.printType==""&&!isexcel){
        this.$store.commit("TIPPOP",{popType:'warning',popMessage:this.$t("comm.selectprint")});
        return;
      }
      this.batchexport = false;
      if((this.radio == "exportTextB"&&isexcel)||(this.printRadio == "PrintTextB"&&!isexcel)){
        this.axiosInvoke("/SupplierComm/Handler/OrderPurPurchaseHandler.ashx", {
        action: "getorder",
        pageindex: 0,
        pagesize: this.pagesize,
        compName: this.compName,
        billno: this.billno,
        compName: this.compName,
        startdate: this.startdate,
        enddate: this.enddate,
        unshipped: this.unshipped,
        unconfirmedchanges: this.unconfirmedchanges,
        confirm: this.confirm,
        unsignedconfirmation: this.unsignedconfirmation,
        unshippe: this.unshippe,
        partialDel: this.partialDel,
        allshipments: this.allshipments,
        excel: true,
        iswithtax:this.iswithtax
      },(res)=>{
        this.printtemp.selectKeys = this.progId + "_selectKeys";
        PrintView(this,isexcel);
      })
      }else{
        PrintView(this,isexcel);
      }
    },
    checkall() {
      if (this.checked) this.$refs.multipleTable.toggleAllSelection();
      else this.$refs.multipleTable.clearSelection();
    },
    //多选处理
    handleSelectionChange(val) {
      this.selectData = val;
      // 部分选中状态先注释，待燕珊确认规格后再作处理——目前只需全选、未选
      if(val.length == 0){ // 未选
        this.checked = false;
      }else
      if (val.length > 0 && val.length == this.tableData.length) { // 全选
        this.checked = true;
      } else { // 未选
        this.checked = false;
      }
    },
    //获取时间
    getTime() {
      this.startdate = handleShortDate(this.billdate[0], "From");
      this.enddate = handleShortDate(this.billdate[1], "To");
      this.getorderdata(0);
    },
    //翻页处理
    handleCurrentChange(val) {
      this.pageindex = val - 1;
      this.getorderdata(val - 1);
    },
    exportdialog(){
      let prams = BillParams(this,true);
      this.batchexport = prams.printvisible;
      debugger
      if(this.selectData.length>0){
        this.selectexport = true;
        this.radio = "exportTextA";
      }else{
        this.selectexport = false;
        this.radio = "exportTextB";
      }
    },
    //打印弹窗对话框
    printdialog() {
      let prams = BillParams(this,true);
      this.printvisible = prams.printvisible;
      if(this.selectData.length>0){
        this.selectprint = true;
        this.printRadio = "PrintTextA";
      }else{
        this.selectprint = false;
        this.printRadio = "PrintTextB";
      }
    }
  }
}
</script>

<style scoped>
.detialmessage {
  color: #5d5d5d;
  font-size: 14px;
  width: 20%;
  padding-left: 10px;
  padding-right: 10px;
}

.moneyornumber {
  padding-right: 40px;
}

.ordermessage {
  padding: 8px 20px;
  white-space: nowrap;
}

.checkBox{
    margin: auto 0;
}

.el-checkbox /deep/ .el-checkbox__label {
  color: #5d5d5d;
  font-size: 14px;
}

.stateborder {
  padding: 2px 8px;
  border-radius: 15px;
  border: 1px solid;
}

.el-table /deep/ th.el-table__cell {
  background-color: #f9f9f9;
}

.el-table /deep/ th.el-table__cell.is-right{
  padding-right: 40px;
}

.el-table /deep/ .el-table__body-wrapper {
  font-size: 13px;
}

.el-table /deep/ .cell {
  overflow: initial;
}

.el-table /deep/ .cell span {
  display: inline-block;
}
.el-table /deep/ thead {
  font-size: 14px;
  color: #5d5d5d;
}

.el-table /deep/ .el-table__header .cell .el-checkbox:first-child {
  padding-left: 5px;
}

.topbutton {
  color: #0177d5;
  font-size: 14px;
}

.topbutton:hover {
  color: #5aacff;
}

.img {
  padding-right: 8px;
  height: 12px;
  width: 12px;
}
.btnarray{
  width:50%;margin:15px 0 0 15px;
}
@media screen and (max-width: 1450px){
  .btnarray .el-button{
  padding:10px 10px;
}
} 

.el-dialog__wrapper /deep/ .el-dialog .el-dialog__header {
  border-bottom: 1px solid rgb(235, 233, 233);
}

.el-dialog__wrapper /deep/ .el-dialog .el-dialog__body {
  border-bottom: 1px solid rgb(235, 233, 233);
}

.batchconfirmation /deep/ .el-dialog .el-dialog__header {
  display: none;
}

.batchconfirmation /deep/ .el-dialog .el-dialog__body {
  text-align: center;
}
</style>
