// The Vue build v'./router'`import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import {router} from './router'
import "../src/css/default.css"
import '../src/assets/icons'
import PageHeader from "./components/PageHeader"
import Frame from "./components/Frame"
import TipPop from "./components/TipPop.vue"
import i18n from '@/locale'
import store from "./store/index"
import VueCookies from "vue-cookies";
import axios from 'axios'
import { axiosInvoke,asyncaxiosInvoke,handlerDetail,recoredPageToJump,getSessionInform} from './utils/utils';
import { renderTooltip } from './utils/LayoutPanel';
import { exportExcel } from './utils/AccessoryHandler';
Vue.component("PageHeader", PageHeader)
Vue.component("Frame", Frame)
Vue.component("TipPop",TipPop)
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(VueCookies);
Vue.prototype.axios = axios;
Vue.prototype.axiosInvoke = axiosInvoke;
Vue.prototype.asyncaxiosInvoke = asyncaxiosInvoke;
Vue.prototype.handlerDetail =handlerDetail;
Vue.prototype.renderTooltip = renderTooltip;
Vue.prototype.recoredPageToJump = recoredPageToJump;
Vue.prototype.getSessionInform = getSessionInform;
Vue.prototype.exportExcel = exportExcel;
if(process.env.NODE_ENV == 'development'){
 axios.defaults.baseURL = '/dev';
}


Vue.filter('MoneyFormat', (value) => { //金额千分位格式
 if(value == "-----") return value;
 var valuearr = value.split('.');
 var arr0 = valuearr[0];
 var arr1 = valuearr[1];
 var more = arr0.length % 3;
 var arr = arr0.substring(0, more);
 for (var i = 0; i < Math.floor(arr0.length / 3); i++) {
 arr += ',' + arr0.substring(more + i * 3, more + (i + 1) * 3);
 }
 if (more == 0) {
 arr = arr.substring(1);
 }
 if (arr1) {
 var data = arr + '.' + arr1;
 } else {
 var data = arr;
 }
 return data;
})

/* eslint-disable no-new */
new Vue({
 el: '#app',
 store,
 router,
 beforeCreate() {
 Vue.prototype.$bus = this
 },
 i18n,
 render: h => h(App)
}).$mount("#app")
