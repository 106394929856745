<template>
  <Frame>
    <PageHeader id="DeliverSearchDetail" :title="this.pageTitle" :hasBack="true" detailType="DeliverSearchDetail" />
    <div class="deliverdetail" style="padding:10px;">
      <div class="contain">
        <div class="message" style="width:99%;">
          <div style="display: flex;">
            <span class="messagetitle">{{ $t('comm.messagetitle') }}</span>
            <el-button type="text" :disabled="edit" :class="edit?'button unoption-icon':'button option-icon'" @click="billedit()"><svg-icon icon-class="edit" />{{$t('button.modification')}}</el-button>
            <el-button type="text" :disabled="edit" :class="edit?'button unoption-icon':'button option-icon'" @click="billdelete()"><svg-icon icon-class="delete" />{{$t('button.delete')}}</el-button>
            <el-button type="text" :disabled="!edit" :class="!edit?'button unoption-icon':'button option-icon'" @click="billsave()"><svg-icon icon-class="save" />{{$t('button.save')}}</el-button>
            <el-button type="text" :disabled="edit" style="width:9%;" :class="edit?'button unoption-icon':'button option-icon'" id="Export" v-if="$store.state.exportExcelDetailState" @click="Export()"> <svg-icon icon-class="export" />{{$t('button.export')}}</el-button>
            <el-button type="text" :disabled="edit" style="width:9%;" :class="edit?'button unoption-icon':'button option-icon'" id="print" @click="printdialog()"><svg-icon icon-class="print" />{{$t('button.print')}}</el-button>
            </div>
          <ul style="padding:0 20px;display: flex;">
            <li class="message">{{ $t('DeliverDetail.messagenumber') }}:{{ billno }}</li>
            <li class="message">{{ $t('DeliverDetail.messagedate') }}：{{ billdate }}</li>
            <li class="message">{{ $t('DeliverDetail.orgName') }}：{{ orgName }}</li>
            <li class="message">{{ $t('DeliverDetail.amount') }}：{{ amount }}</li>
          </ul>
          <ul style="padding:0 20px;display: flex;">
            <li class="message">{{ $t('DeliverDetail.warestate') }}：<span :class="warestateclass">{{ warestate }}</span>
            </li>
            <li class="message">{{ $t('DeliverDetail.cancelstate') }}：<span :class="cancelstateclass">{{ cancelstate}}</span></li>
            <li class="message">{{ $t('DeliverDetail.purchaser') }}：{{ purchaser }}</li>
            <li class="message">{{ $t('DeliverDetail.OAmountWithTax') }}：{{ oAmountWithTax }}</li>
          </ul>
          <ul style="padding:0 20px;display: flex;">
            <li class="message">{{ $t('DeliverDetail.remark') }}：{{ remark }}</li>
          </ul>
        </div>
      </div>
      <ul>
        <li class="messagetitle">{{ $t('comm.detailtitle') }}</li>
      </ul>
      <el-table :data="tableData" :height="tableHeight" v-loading="tableLoading" style="margin:0;width:100%" @row-click="rowclick" :class="edit?'edittable':''">
        <el-table-column width="50" prop="Number" :label="$t('DeliverDetail.number')"></el-table-column>
        <el-table-column prop="MaterialId" :label="$t('DeliverDetail.codeid')"></el-table-column>
        <el-table-column prop="MaterialName" :label="$t('DeliverDetail.name')"></el-table-column>
        <el-table-column prop="MaterialSpec" :label="$t('DeliverDetail.specifications')"></el-table-column>
        <el-table-column prop="CarrySQty" v-if="Tally" align="right" width="150" :label="$t('DeliverDetail.deliverynumber')">
          <template slot-scope="scope">
            <span v-if="!Tally||!scope.row.edit">{{ scope.row.CarrySQty}}&nbsp{{ scope.row.SUnitName }}</span>
            <div v-if="scope.row.edit&&Tally" class="editcell">
              <el-input size="small" class="editinput" @change="editrow(scope.row,'ReceivingSQty',scope.row.CarrySQty)" v-model="scope.row.CarrySQty"></el-input>
              <span style="margin: auto 0;">{{ scope.row.UnitName }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="CarryQty" v-if="$store.state.ShowBasicUnit && Tally" width="150" align="right" :label="$t('DeliverDetail.carrySQty')">
          <template slot-scope="scope">
            <span v-if="!Tally||!scope.row.edit">{{ scope.row.CarryQty}}&nbsp{{ scope.row.UnitName }}</span>
            <div v-if="scope.row.edit&&Tally" class="editcell">
              <el-input size="small" class="editinput" @change="editrow(scope.row,'ReceivingQty',scope.row.CarryQty)" v-model="scope.row.CarryQty"></el-input>
              <span style="margin: auto 0;">{{ scope.row.UnitName }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="ReceivingSQty" align="right"  width="150"
          :label="$t('DeliverDetail.receiptnumber')">
          <template slot-scope="scope">
            <span v-if="Tally||!scope.row.edit">{{ scope.row.ReceivingSQty}}&nbsp{{ scope.row.SUnitName }}</span>
            <div v-if="scope.row.edit&&!Tally" class="editcell">
              <el-input size="small" class="editinput" @change="editrow(scope.row,'ReceivingSQty',scope.row.ReceivingSQty)" v-model="scope.row.ReceivingSQty"></el-input>
              <span style="margin: auto 0;">{{ scope.row.SUnitName }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column  v-if="$store.state.ShowBasicUnit" prop="ReceivingQty" width="150" align="right" :label="$t('DeliverDetail.receivingQty')">
          <template slot-scope="scope">
            <span v-if="Tally||!scope.row.edit">{{ scope.row.ReceivingQty}}&nbsp{{ scope.row.UnitName }}</span>
            <div v-if="scope.row.edit&&!Tally" class="editcell">
              <el-input size="small" class="editinput" @change="editrow(scope.row,'ReceivingQty',scope.row.ReceivingQty)" v-model="scope.row.ReceivingQty"></el-input>
              <span style="margin: auto 0;">{{ scope.row.UnitName }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="ValueSQty" align="right" :label="$t('DeliverDetail.valueSQty')">
          <template slot-scope="scope">
            <span>{{ scope.row.ValueSQty}}&nbsp{{ scope.row.SUnitName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="SPrice" align="right" :label="$t('DeliverDetail.price')"></el-table-column>
        <el-table-column prop="strPrice" align="right" :label="$t('DeliverDetail.subtotal')"></el-table-column>
        <el-table-column prop="Remark" :label="$t('DeliverDetail.remark')" min-width="120">
          <template slot-scope="scope">
            <span v-if="!scope.row.edit">{{ scope.row.Remark}}</span>
            <div v-if="scope.row.edit" class="editcell">
              <el-input size="small" class="editinput" @change="editrow(scope.row,'Remark',scope.row.Remark)" maxlength="400" v-model="scope.row.Remark"></el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="Address" :label="$t('DeliverDetail.address')" min-width="120">
          <template slot-scope="scope">
            <span v-if="!scope.row.edit">{{ scope.row.Address}}</span>
            <div v-if="scope.row.edit" class="editcell" >
                <el-select style="width: 100%;" :loading="selectloading"  @change="changeAddress(scope.row)" size="small"  v-model="scope.row.Uid" placeholder="">
                  <el-option  v-for="item in addresses" :key="item.Uid" v-if="item.OrgId == OrgId"
                  :label="item.Address==''?' ':item.Address" :value="item.Uid" class="option"></el-option>
                </el-select>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog width="25%" :title="$t('OrderDetail.tip')" :visible.sync="printvisible" :append-to-body="true">
      <el-select style="width:100%" class="dialogselect" v-model="printType"
        :placeholder="typegather.length != 0 ? $t('OrderDetail.placeholder') : $t('OrderDetail.noneholder')">
        <el-option v-for="item in typegather" :key="item.value" :label="item.name" :value="item.value">
        </el-option>
      </el-select>
      <div slot="footer" class="dialog-footer">
        <el-button @click="print()" type="primary">{{ $t('button.determine') }}</el-button>
      </div>
    </el-dialog>
  </Frame>
</template>

<script>
import SvgIcon from '../components/SvgIcon'
import { Serialize } from '@/utils/utils';
export default {
  name: 'DeliverSearchDetail',
  components: { SvgIcon },
  data() {
    return {
      pageTitle:this.$t('comm.deliverdetail'),
      edit:false,
      tableHeight: window.innerHeight - 400,
      billno: '',
      billdate: '',
      oAmountWithTax:'',
      amount: '',
      orgName:'',
      warestate: '',
      warestateclass: 'warestate',
      cancelstate: '',
      cancelstateclass: 'warestate',
      purchaser: '',
      remark: '',
      tableLoading: false,
      printvisible: false,
      printType: "",
      printtemp: '',
      typegather: [],
      pageindex: 0,
      tableData: [],
      changeData:[],
      Tally:false,
      CurrId:'',
      addresses:[],
      selectloading:false,
      OrgId:'',
      OldUid:'',
    }
  },
  created() {
    this.billno = this.$route.query.DelBillNo;
    this.edit = eval(this.$route.query.edit);
    if(this.edit){          
      this.pageTitle = this.$t('comm.deliverdetailEdit');
      document.getElementsByClassName('header')[0].childNodes[1].innerText = this.pageTitle; // 通过dom操作更新页面title
    }
  },
  mounted() {
    let that = this;
    window.onresize = () => {
      if (that.resizeFlag) {
        clearTimeout(that.resizeFlag);
      }
      that.resizeFlag = setTimeout(() => {
        that.tableHeight = window.innerHeight - 400;
        that.resizeFlag = null;
      }, 100);
    }
    this.tableLoading = true;
    this.getDeliverDetail();
    this.getAddresses(true);
  },

  methods: {
    getDeliverDetail() {
      this.axiosInvoke("/SupplierComm/Handler/OrderPurPurchaseHandler.ashx", {
        action: "getorderfahuodetail",
        billno: this.billno,
      }, (res) => {
        var data = eval(res.data);
        this.billdate = data[0].BillDate;
        this.orgName = data[0].OrgName;
        this.amount = data[0].OAmount;
        this.OrgId = data[0].OrgId;
        this.CurrId = data[0].CurrId;
        this.oAmountWithTax = data[0].OAmountWithTax;
        switch (data[0].InStoreState) {
          case '0':
            this.warestate = this.$t('DeliverSearch.nowarehousing');
            this.warestateclass = "warestatered";
            break;
          case '1':
            this.warestate = this.$t('DeliverSearch.partialwarehousing');
            this.warestateclass = "warestatered";
            break;
          case '2':
            this.warestate = this.$t('DeliverSearch.allwarehousing');
            break;
        }
        switch (data[0].RetSt) {
          case '0':
            this.cancelstate = this.$t('DeliverSearch.unreturned');
            break;
          case '1':
            this.cancelstate = this.$t('DeliverSearch.partialreturn');
            this.cancelstateclass = "warestatered";
            break;
          case '2':
            this.cancelstate = this.$t('DeliverSearch.fullreturn');
            this.cancelstateclass = "warestatered";
            break;
          case '3':
            this.cancelstate = this.$t('DeliverSearch.allaccrefund');
            this.cancelstateclass = "warestatered";
            break;
        }
        this.purchaser = data[0].PersonName;
        this.remark = data[0].Remark;
        this.Tally = data[0].Tally;
        console.log(data);
        data.shift(0);
        data.forEach(v => {
          v.edit = false;
        });
        this.tableData = data;
        //用于记录收货申请单相同地址的初始地址Uid
        this.OldUid = data[0].Uid;
        this.tableLoading = false;
      })
    },
    Export() {//导出
      let action = 'exportexcel'
      let progId = 'purReceivingOrder'
      //取回当前查询表进行导出
      this.exportExcel(action, progId, this.tableData)
    },
    print() {//打印
      if(this.printType==""){
        this.$store.commit("TIPPOP",{popType:'warning',popMessage:this.$t("comm.selectprint")});
        return;
      }
      this.$cookies.set("purReceivingOrderprintType", this.printType,"365D");
      var theParam = {
        progId: "purReceivingOrder",
        lang: this.printtemp.lang,
        file: this.printType,
        date: '',
        billPrintKind: 0,
        conditions: this.printtemp.conditions
      }
      debugger
      var url = "/functions/report/ReportPreview.aspx?ispdf=1&params=" + encodeURI(Serialize(theParam));
      window.open(url, "_blank");
    },
    printdialog() {
      this.printvisible = true;
      this.axiosInvoke("/SupplierComm/Handler/VueSysHandler.ashx",
        {
          action: "doprintbill",
          BillNo: this.billno,
          ProgId: "purReceivingOrder",
          lang: localStorage.getItem("language") == "CHS"?"zh-CHS":"zh-CHT"
        },
        (res) => {
          var data = eval(res.data);
          this.printtemp = data[0];
          this.typegather = data[0].files;
          this.printType = decodeURIComponent(data[0].printType);
          var printExist = false;
          this.typegather.forEach(v=>{
            if(v.value == this.printType){
              printExist = true;
              return;
            }
          })
          if(!printExist)this.printType = "";
        })
    },
    rowclick(row,column){
      this.tableData.forEach(v=>{
        if(v.edit)v.edit = false;
        return;
      })
      if(this.edit){
        row.edit = true;
      }
    },
    //修改订单信息
    billedit(){
      this.tableLoading = true;
      this.axiosInvoke("/SupplierComm/Handler/OrderPurPurchaseHandler.ashx", {
          action:"ispermitedit",
          ProgId:"purReceivingOrder",
          BillNo:this.billno,
        },(res,errflag)=>{
          this.tableLoading = false;
          if(!errflag){
            this.edit = true;
            this.pageTitle = this.$t('comm.deliverdetailEdit');
            document.getElementsByClassName('header')[0].childNodes[1].innerText = this.pageTitle; // 通过dom操作更新页面title
          }
        });
    },
    //保存订单信息
    billsave(){
      this.edit = false;
      this.tableData.forEach(v=>{
        v.edit = false;
      })
      if(this.tableLoading){
        setTimeout(()=>{
          this.billsave();
        })
      }else{
        if(this.changeData.length){
          this.tableLoading = true;
          this.axiosInvoke("/SupplierComm/Handler/OrderPurPurchaseHandler.ashx", 
        {
          action:'receivingordereditsave',
          BillNo:this.billno,
          editdetail:JSON.stringify(this.changeData)
        },
        (res,errflag)=>{
            this.tableLoading = false;
            if(errflag){
              this.edit = true;
            }else{
              this.getDeliverDetail();
            }
        });
        }
        if(!this.edit){          
          this.pageTitle = this.$t('comm.deliverdetail');
          document.getElementsByClassName('header')[0].childNodes[1].innerText = this.pageTitle; // 通过dom操作更新页面title
        }
      }
    },
    //删除本订单
    billdelete(){
      this.$confirm(this.$t('DeliverSearch.isdelete'),this.$t('comm.tip'),{
        confirmButtonText:this.$t('comm.confirm'),
        cancelButtonText:this.$t('button.Cancel')
      }).then(()=>{
        this.tableLoading =true;
        this.axiosInvoke("/SupplierComm/Handler/OrderPurPurchaseHandler.ashx", {
          action:"ispermitdelete",
          ProgId:"purReceivingOrder",
          BillNo:this.billno},
          (res,errflag)=>{
            this.tableLoading =false;
            if(!errflag){        
              this.$store.commit("TIPPOP",{popType:'success',popMessage:  this.$t("DeliverCar.deleteMessage")});
              //读取上一个页面的搜索记录 回到上一个页面的状态
              sessionStorage.setItem("DeliverSearchDetail",(sessionStorage.getItem("DeliverSearch")));
              sessionStorage.removeItem("DeliverSearch");
              //删除订单后，回到原本的旧页面
              this.$router.push({
                  path: "/Home/DeliverSearch"
              })
            }
          })
      })
    },
     //获取所有可选发货地址
    getAddresses(visible){
      if(visible){
        this.selectloading = true;
      this.addresses = [];
      this.axiosInvoke("/SupplierComm/Handler/VueSysHandler.ashx", 
        {
          action:'getaddresses',
        },
        (res)=>{
          var data = eval(res.data);
          this.addresses = data;
          console.log(this.addresses);
          this.selectloading = false;
        });
      }
    },
    changeAddress(row){
      if(this.$store.state.GenrateDifferentTypes&&this.tableData.length>1){
        this.$confirm(this.$t('DeliverDetail.editaddresses'),this.$t('comm.tip'),{
          confirmButtonText:this.$t('comm.confirm'),
          cancelButtonText:this.$t('button.Cancel')
        }).then(()=>{
          this.OldUid = row.Uid;
          this.addresses.forEach((v)=>{
          if(v.Uid == row.Uid){
            row.AddrId = v.AddrId
            row.Address = v.Address
          }
        })
          this.tableData.forEach(v=>{
            v.AddrId = row.AddrId;
            v.Address = row.Address;
            v.Uid = row.Uid;
            this.AddchangeData(v,"AddrId");
            this.AddchangeData(v,"Address");
          })
      }).catch(()=>{
        row.Uid = this.OldUid;
      }) 
      }else{
        this.addresses.forEach((v)=>{
          if(v.Uid == row.Uid){
            row.AddrId = v.AddrId
            row.Address = v.Address
          }
        })
        this.AddchangeData(row,"AddrId");
        this.AddchangeData(row,"Address");
      }
    },
    editrow(row,fieldName,fieldValue){
      if(fieldName == "ReceivingSQty"||fieldName == "ReceivingQty"){//数量
        fieldValue = String(fieldValue).replace(/,/g,"");
        if(!isNaN(fieldValue)&&Number(fieldValue)>0){
          var ETOB = fieldName == "ReceivingSQty";
          this.tableLoading = true;
          this.axiosInvoke("/SupplierComm/Handler/VueSysHandler.ashx",{
            action:'editpurreceivingorder',
            MaterialId:row.MaterialId,
            curSQty:fieldValue,
            ETOB,
            UnitId:row.UnitId,
            SUnitId:row.SUnitId,
            SPrice:row.SPrice,
            CurrId:this.CurrId,
            OrgId:this.OrgId
          },res=>{
            var data = eval(res.data);
            if(this.Tally){
              row.CarrySQty = data[0].ReceivingSQty;
              row.CarryQty = data[0].ReceivingQty;
            }else{
              row.ReceivingSQty = data[0].ReceivingSQty;
              row.ReceivingQty = data[0].ReceivingQty;
            }
            row.strPrice = data[0].strPrice;
            this.tableLoading = false;
            this.AddchangeData(row,fieldName);
          })
        }else{
          row[fieldName] = "";
          this.$store.commit("TIPPOP",{popType:'warning',popMessage:this.$t("DeliverDetail.numbertip")});
        }
      }
      else if(fieldName == "Remark"){
        this.AddchangeData(row,fieldName);
      }
    },

    AddchangeData(row,fieldName){
      var isExist = false;
      var ReceivingSQty = 0;
      var ReceivingQty = 0;
      if(this.Tally){
        ReceivingSQty = row.CarrySQty;
        ReceivingQty = row.CarryQty;
      }else{
        ReceivingSQty = row.ReceivingSQty;
        ReceivingQty = row.ReceivingQty;
      }
      this.changeData.forEach(v=>{
        if(v.RowCode == row.RowCode){
          v[fieldName] = row[fieldName];
          isExist = true;
          v.ReceivingSQty = ReceivingSQty;
          v.ReceivingQty = ReceivingQty;
          return;
        }
      })
      if(!isExist){
        this.changeData.push(
          {
            [fieldName]:row[fieldName],
            RowCode:row.RowCode,
            ReceivingSQty,
            ReceivingQty,
            });
      }
      console.log(this.changeData);
    }
  }
}
</script>

<style scoped>
ul {
  padding: 0;
}

.message {
  color: #777777;
  font-size: 14px;
  width: 24%;
  padding-right: 1%;
}

.message li {
  display: inline-block;
  text-align: left;
}
.editcell{
  display: flex;
}
.editcell /deep/ .el-input__inner{
  padding: 0 5px;
  min-width: 80px;
}
.editinput{
  padding:0 2%;
  margin: auto 0;
}
.option-icon{
  filter: grayscale(100%);
  padding-right:5%;
}
.unoption-icon{
  filter: grayscale(100%);
  padding-right:5%;
  opacity: 0.5;
}
.option-icon:hover{
  filter: grayscale(0%);
}
.option /deep/ span{
    min-width: 150px;
    max-width: 33vw;
    display: flex;
    word-break: break-all;
    height: auto;
    white-space: pre-wrap;
}
.el-select-dropdown__item{
    height: auto;
}
.messagetitle {
  color: #5d5d5d;
  font-size: 16px;
  width: 60%;
  padding: 0 20px;
}

.button {
  white-space: nowrap;
  color: #5d5d5d;
  font-size: 15px;
  cursor: pointer;
}
.edittable /deep/ .el-table__row{
  background-color:#f4f9ff;
}
.button:hover {
  cursor: pointer;
  color: #0177d5;
}

.warestate {
  color: #777777;
}

.warestatered {
  color: red;
}

.el-table /deep/ th.el-table__cell {
  background-color: #f9f9f9;
}

.el-table /deep/ .el-table__body-wrapper {
  font-size: 13px;
}

.el-table /deep/ thead {
  font-size: 14px;
  color: #777777;
}

.el-table /deep/ .el-table_1_column_1 .cell:first-child {
  padding: 0 0 0 30px;
}

.el-table /deep/ .cell:first-child {
  font-weight: normal;
}
</style>