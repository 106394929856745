/**
 * 导出Excel
 * @param {*} action 
 * @param {*} progId 
 * @param {*} tableData 
 */
function exportExcel(action, progId, tableData,params) {
    //导出Excel
    //如若需要使用iframe进行下载文件，需要考虑参数长度，因为iframe默认为get请求，若参数长度大于3k，则需要用form进行包裹为post请求，
    //form中用input承接参数，form.action只能用于存放url，action的长度限制也是3k，放在input中则几乎没有长度限制了。
    debugger
    if (tableData && tableData.length > 0) {
        let tableDataAy = encodeURIComponent(JSON.stringify(tableData))
        let iframe = document.createElement("iframe");
        iframe.name = "iframe";
        let form = document.createElement("form");
        let input = document.createElement("input");
        input.name= "tableDataAy";
        input.value = tableDataAy;
        form.target = "iframe";
        form.method = "post";
        form.action = encodeURI('/SupplierComm/Handler/SupplierBillHandler.ashx?action='+action+ '&billNo=' + this.billno + '&progId=' + progId);
        form.appendChild(input);
        //导出字段列表传参
        if(params.exportFields && "" != params.exportFields)
        {
            let exportFieldsAy = encodeURIComponent(params.exportFields)
            let input2 = document.createElement("input");
            input2.name = "exportFieldsAy";
            input2.value = exportFieldsAy;
            form.appendChild(input2);
        }
        form.appendChild(iframe);
        form.style.display = "none";
        iframe.style.display = "none";
        document.body.appendChild(form);
        form.submit();
    } else {
        console.log('未获取到数据进行导出！')
    }
}

export { exportExcel }