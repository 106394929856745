<template>
    <el-container v-loading="loading">
        <el-main>
            <div class="header-container">
                <div class="header-info">
                    <el-avatar :size="70" :src="circleUrl"></el-avatar>

                    <div class="header-advertising">
                        <XElTooltip :content="advertising" :overflow="true" placement="bottom" :open-delay="500">
                            <span>{{ advertising }}</span>
                        </XElTooltip>
                        <p>{{ $t('Default.phonenumber') }}：{{ phonenumber }}</p>
                    </div>
                </div>
                <div class="info">
                    <div class="info-box">
                        <span class="box-label">{{ $t('Default.increaseOrder') }}</span>
                        <span class="box-number box-Link" @click="LinkToPage('Order');">{{ OrderNumber.increaseOrder }}</span>
                    </div>
                    <div class="info-box">
                        <span class="box-label">{{ $t('Default.dealOrder') }}</span>
                        <span class="box-number box-Link" @click="LinkToPage('DeliverSearch');">{{ OrderNumber.dealOrder }}</span>
                    </div>
                    <div class="info-box">
                        <span class="box-label">{{ $t('Default.refundOrder') }}</span>
                        <span class="box-number box-Link" @click="LinkToPage('ReturnQuery');">{{ OrderNumber.refundOrder }}</span>
                    </div>
                </div>
            </div>
            <div class="Row">
                <div class="backlog box-background">
                    <span class="box-title">{{ $t('Default.list') }}</span>
                    <div style="width:100%;display:flex;justify-content: center;">
                        <div class="info-box" @click="JumpToOrder('toBeConfirmed')">
                            <span class="box-number" style="border-right:solid 1px #e9e9e9; ">
                                {{ backlog.toBeConfirmed }}
                            </span>
                            <span class="box-label">{{ $t('Default.confirmnumber') }}</span>
                        </div>
                        <div class="info-box" @click="JumpToOrder('toBeDelievered')">
                            <span class="box-number">{{ backlog.toBeDelievered }}</span>
                            <span class="box-label">{{ $t('Default.delivernumber') }}</span>
                        </div>
                    </div>
                </div>
                <bulletin class="message-board box-background" :formData="Information" :type="'Message'" :morebtn="infomorebtn"
                    :viewmore="informmore">
                </bulletin>
            </div>
            <div class="Row">
                <bulletin class="bulletin-board box-background" :formData="Bulletin" :type="'Bulletin'" :morebtn="bulletinbtn"
                    :viewmore="bulletinmore">
                </bulletin>
                <bulletin class="download-board box-background" :formData="Download" :type="'Download'" :morebtn="downloadbtn"
                    :viewmore="downloadmore">
                </bulletin>
            </div>

        </el-main>
    </el-container>
</template>

<script>
import bulletin from "../components/BulletinBoard"
import XElTooltip from '../components/X-el-tooltip.vue'
import { getThisMonth } from "@/utils/utils"
export default {
    components: {
        bulletin, XElTooltip
    },
    data() {
        return {
            advertising: "",
            phonenumber: "",
            circleUrl: require("../assets/img/defaultavatar.png"),
            loading: false,
            OrderNumber: {
                increaseOrder: 0,
                dealOrder: 0,
                refundOrder: 0
            },
            backlog: {
                toBeConfirmed: 0,
                toBeDelievered: 0
            },
            infomorebtn:true,
            bulletinbtn:true,
            downloadbtn:true,
            Download: [],
            Bulletin: [],
            Information: [],

        }
    },
    methods: {
        LinkToPage(pageName){
            let pickdate = getThisMonth(true);
            let daterange = getThisMonth();
            switch(pageName){
                case "Order":
                    this.$router.push({
                        path: "/Home/"+pageName,
                        query: {
                            date: JSON.stringify(pickdate),
                            startdate: daterange[0],
                            enddate: daterange[1],
                            checkOption: false // 都不勾选                            
                        }
                    })
                    break;
                default:
                    this.$router.push({
                        path: "/Home/"+pageName,
                        query: {
                            date: JSON.stringify(pickdate),
                            startdate: daterange[0],
                            enddate: daterange[1]
                        }
                    })
                    break;
            }            
        },
        JumpToOrder(action) {
            let pickdate = getThisMonth(true);
            let daterange = getThisMonth();
            switch (action) {
                case "toBeConfirmed":
                    this.$router.push({
                        path: "/Home/Order",
                        query: {
                            unconfirmedchanges:true,
                            unsignedconfirmation: true
                        }
                    })
                    break;
                case "toBeDelievered":
                    this.$router.push({
                        path: "/Home/Order",
                        query: {
                            unshippe: true,
                            partialDel: true
                        }
                    })
                    break;
                case "increaseOrder":

                    this.$router.push({
                        path: "/Home/Order",
                        query: {
                            date: JSON.stringify(pickdate),
                            startdate: daterange[0],
                            enddate: daterange[1]
                        }
                    })
                    break;
                case "dealOrder":
                    this.$router.push({
                        path: "/Home/DeliverSearch",
                        query: {
                            date: JSON.stringify(pickdate),
                            startdate: daterange[0],
                            enddate: daterange[1]
                        }
                    })
                    break;
                case "refundOrder":
                    this.$router.push({
                        path: "/Home/Order",
                        query: {
                            unshippe: true,
                            partialDel: true
                        }
                    })
                    break;
            }
        },
        downloadmore() {
            this.$router.push({
                path: "/Home/SortFile",
            })
        },
        bulletinmore() {
            this.$router.push({
                path: "/Home/BulletinCenter",
            })
        },
        informmore() {
            this.$router.push({
                path: "/Home/Information",
            })
        },
        async pageLoad() {
            // var options = {background:"rgba(0,0,0,0)"}
            // let loadinstance = Loading.service(options);
            this.loading = true;
            await this.asyncaxiosInvoke("/SupplierComm/Handler/BulletinHandler.ashx?",
                { action: 'getbulletin' },
                (res) => {
                    var allinform = eval(res.data);
                    console.log(1);
                    for (let i = 0; i < allinform[0].length; i++) {
                        allinform[0][i].text = "["+allinform[0][i].CateName+"]" + allinform[0][i].text;
                        allinform[0][i].isnew = eval(allinform[0][i].isnew);
                        allinform[0][i].date = allinform[0][i].date.substring(0, 10);

                    }
                    for (let j = 0; j < allinform[1].length; j++) {
                        allinform[1][j].text = "["+allinform[0][j].CateName+"]" + allinform[1][j].text;
                        allinform[1][j].date = allinform[1][j].date.substring(0, 10);
                        allinform[1][j].isnew = eval(allinform[1][j].isnew);
                    }
                    var data = allinform[0].concat(allinform[1]);
                    data = data.sort((a, b) => {
                        return b.updatetime - a.updatetime;
                    });
                    console.log(data);
                    if(data.length<5)this.bulletinbtn = false;
                    this.Bulletin = data;
                });

            this.axiosInvoke("/SupplierComm/Handler/BulletinHandler.ashx?",
                { action: 'getnewdownload' },
                (res) => {
                    console.log(2)
                    var newdownload = eval(res.data);
                    if(newdownload.length<5)this.downloadbtn = false;
                    this.Download = newdownload;
                });
            this.axiosInvoke("/SupplierComm/Handler/OrderPurPurchaseHandler.ashx",
                { action: "getdeliverandreturn"},
                (res) => {
                    var data = eval(res.data);
                    console.log(3);
                    this.OrderNumber.increaseOrder = data[0].increaseOrder;
                    this.OrderNumber.dealOrder = data[0].dearlOrderCount;
                    this.OrderNumber.refundOrder = data[0].refundOrderCount;
                    this.backlog.toBeDelievered = data[0].toBeDelievered;
                    this.backlog.toBeConfirmed = data[0].toBeConfirmed;
                    this.advertising = data[0].AdMessage;
                    this.phonenumber = data[0].TelNo;
                    this.circleUrl = data[0].AdImage;
                })
            this.axiosInvoke("/SupplierComm/Handler/BulletinHandler.ashx?", {
                action: "getallinformation",
                pageindex:0,
                pagesize: 4
            }, (res) => {
                var data = eval(res.data);
                data.shift();
                debugger
                if(data.length <4)this.infomorebtn = false;
                data.forEach(v => {
                        switch (v.Type) {
                            case 'purPurchaseOrder':
                                v.name = this.$t("Default.haveaneworder").replace("{0}", v.BillNo);
                                break;
                            case 'bluePrint':
                                v.name = this.$t('comm.order') + "[" + v.BillNo + "]" + this.$t('Default.printhavenew');
                                break;
                            case 'purPurchaseOrderChange':
                                v.name = this.$t("Default.haveanewchange").replace("{0}", v.BillNo);
                                break;
                        }
                        this.Information.push({text:v.name,date:v.Date,billno:v.BillNo});
                    });
            })
            // loadinstance.close();
            this.loading = false;
        }
    },
    created() {
        this.pageLoad();
    },

}
</script>
<style scoped>
::v-deep .el-container {
    min-width: 1440px;
}

.el-container /deep/ .el-main {
    margin: 0;
    background-color: #e9e9e9;
}

::v-deep .el-main {
    margin: 0;
    padding: 0;
}

.header-container {
    width: 100%;
    margin: 1vh 0;
    display: flex;
    height: 20vh;
    background-image: url(../assets/img/MainPage/headerinfo.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
}

.header-info {
    display: flex;
    margin: auto 0 auto 5%;
    width: 50%;
}

.header-advertising {
    color: #5d5d5d;
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    padding-left: 6%;
    height: 5rem;
    margin: auto 0;
}

.header-advertising span {
    font-size: 1.34rem;
    margin: 0;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.header-advertising p {
    font-size: 15px;
    margin: 0;
    width: 100%;
}

.info {
    width: 45%;
    display: flex;
    margin: auto 0;
}

.info-box {
    color: #5d5d5d;
    display: flex;
    flex-wrap: wrap;
    width: 33.3%;
    padding-bottom: 1.5vh;
}

.backlog .info-box:hover {
    cursor: pointer;
    color: #3466cc;
}

.box-label {
    text-align: center;
    font-size: 1.1rem;
    width: 100%;
}

.box-number {
    text-align: center;
    border-right: solid 1px #e9e9e9;
    font-size: 1.5rem;
    margin-top: 0.9375rem;
    width: 100%;
}
.box-Link{
    cursor: pointer;
}

::v-deep .el-header {
    padding: 0;
    margin: 0;
}

.Row {
    width: 100%;
    display: flex;
    margin-bottom: 1.5vh;
}

.backlog {
    display: flex;
    flex-wrap: wrap;
    width: 55.5%;
    height: 23vh;
    /* max-height: 11.2rem; */
    margin: 0 0.8% 0 1.6%;

}

.box-background {
    background-image: url(../assets/img/MainPage/backlog.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
}

.backlog .box-number {
    border: none;
}

.backlog .box-label {
    font-size: 17px;
}

.box-title {
    margin: 1vh 0 0 2rem;
    color: #5d5d5d;
    text-align: left;
    font-size: 1.15rem;
    width: 100%;
}

.message-board {
    width: 40%;
    height: 23vh;
    /* max-height: 11.2rem; */
    margin: 0 1.6% 0 0.8%;
}

.bulletin-board {
    padding-top: 1vh;
    width: 55.5%;
    height: 39vh;
    /* max-height: 17.5rem; */
    margin: 0 0.8% 0 1.6%;
}

.download-board {
    padding-top: 1vh;
    width: 40%;
    height: 39vh;
    /* max-height: 17.5rem; */
    margin: 0 1.6% 0 0.8%;
}
</style>