<template>
    <Frame>
        <PageHeader :title="$t('comm.message')" :hasBack="false" />
        <div>
            <div class="download-form">
                <el-table :data="tableData" style="width: 100%" :height="tableHeight" v-loading="tableLoading">
                    <el-table-column prop="name" :label="$t('BulletinCenter.maintitle')">
                        <template slot-scope="scope">
                            <el-link :underline="false" @click="viewDetail(scope.$index, scope.row)">
                                {{ scope.row.name }}
                            </el-link>
                        </template>
                    </el-table-column>
                    <el-table-column prop="Date" :label="$t('BulletinCenter.sendtime')">
                    </el-table-column>
                    <el-table-column prop="option" :label="$t('HelpCenter.operation')" width="100">
                        <template slot-scope="scope">
                            <el-link :underline="false" @click="viewDetail(scope.$index, scope.row)" type="primary">
                                {{ $t('button.check') }}
                            </el-link>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="pagination">
                <el-pagination ref="pagination" style="padding-left:50%;width:50%;" @current-change="handleCurrentChange"
                    layout="total,prev,pager,next,jumper" :pager-count="5" :current-page.sync="currentPage"
                    :total="totalCount">
                </el-pagination>
            </div>
        </div>
    </Frame>
</template>

<script>
export default {
    name: "HelpCenter",
    data() {
        return {
            tableData: [],
            value: '',
            searchinput: '',
            tableLoading: false,
            totalCount: 0,
            currentPage: 3,
            pageindex: 0,
            pagesize: 10,
            tableHeight: window.innerHeight - 247,
        }
    },
    mounted() {
        let that = this
        window.onresize = () => {
            if (that.resizeFlag) {
                clearTimeout(that.resizeFlag);
            }
            that.resizeFlag = setTimeout(() => {
                that.tableHeight = window.innerHeight - 247
                that.resizeFlag = null;
            }, 100);
        }
        if(sessionStorage.getItem("informationPage")){
            debugger
            var currentpage = parseInt(sessionStorage.getItem("informationPage"));
            this.getinformation(currentpage);
            sessionStorage.removeItem("informationPage");
        }else{
            this.getinformation(0);
        }
    },
    methods: {
        getinformation(pageindex) {
            this.tableLoading = true;
            this.axiosInvoke("/SupplierComm/Handler/BulletinHandler.ashx?",
                {
                    action: "getallinformation",
                    pageindex,
                    pagesize: this.pagesize
                }, (res) => {
                    let data = eval(res.data);
                    console.log(data);
                    this.totalCount = data[0].totalcount;
                    this.currentPage = pageindex+1;
                    data.forEach(v => {
                        switch (v.Type) {
                            case 'purPurchaseOrder':
                                v.name = this.$t("Default.haveaneworder").replace("{0}", v.BillNo);
                                break;
                            case 'bluePrint':
                                v.name = this.$t('comm.order') + "[" + v.BillNo + "]" + this.$t('Default.printhavenew');
                                break;
                            case 'purPurchaseOrderChange':
                                v.name = this.$t("Default.haveanewchange").replace("{0}", v.BillNo);
                                break;
                        }
                    });
                    data.shift();
                    this.tableData = data;
                    this.tableLoading = false;
                }
            )
        },
        handleCurrentChange(val) {
            debugger
            this.currentPage = val;
            this.pageindex = val - 1;
            this.getinformation(val - 1);
        },
        viewDetail(index, row) {
            this.axiosInvoke("/SupplierComm/Handler/VueSysHandler.ashx",{
              action: "getorderdetail",
              billno: row.BillNo,
            }, (res) => {
            console.log(res.data);
            var data = eval(res.data);
            if(data.length==0){
              this.$store.commit("TIPPOP",{popType:'error',popMessage:this.$t("OrderDetail.orderauthority").replace("{0}",row.BillNo)});
            }else{
                this.$router.push({
              path: "/Home/OrderDetail",
              query: {
              DelBillNo: row.BillNo,
              }
            })
            sessionStorage.setItem("informationPage",this.currentPage-1);
            }})
            
        }
    }
}
</script>

<style scoped>
.el-container /deep/.el-main {
    padding: 0;
    margin: 0;
}

.download-form {
    width: 95%;
    padding: 25px 2.5%;
}

.pagination {
    width: 100%;
}
</style>