<template>
  <Frame>
    <PageHeader :title="$t('comm.orderdetail')" :hasBack="true" detailType="OrderDetail" />
    <div class="orderdetail" style="padding:10px;">
      <div class="contain">
        <div class="message" style="width:100%;">
          <ul style="display:flex">
            <li class="messagetitle">{{ $t('comm.messagetitle') }}</li>
            <div class="buttonArray">
              <li v-if="operable"  class="button-confirm" id="confirm" @click="confirm()">
              <svg-icon icon-class="confirm" />{{$t('button.confirm')}}</li>
              <li v-if="operable"  class="button option-icon" id="veto" @click="veto()">
              <svg-icon  icon-class="deny" />{{$t('button.veto')}}</li>
              <li class="button option-icon" id="" @click="getVaryData()">{{$t('OrderVary.searchVaryData')}}</li>
              <li  v-show="SCM78" class="button option-icon" id="download" @click="download('viewall')">
              <svg-icon  icon-class="downloaddraw" />{{ $t('button.download') }}</li>
              <li  v-show="SCM78" class="button option-icon" id="downloadchange" @click="download('viewchange')">
              <svg-icon  icon-class="downloadchange" />{{ $t('button.downloadchange') }}</li>
              <li  class="button option-icon" id="Export" @click="Export()" v-if="$store.state.exportExcelDetailState">
              <svg-icon  icon-class="export" />{{$t('button.export')}}</li>
              <li  class="button option-icon" id="print" @click="printdialog()">
              <svg-icon  icon-class="print" />{{$t('button.print')}}</li>
            </div>
          </ul>
          <ul style="padding:0 20px;display: flex;">
            <li class="message">{{ $t('OrderDetail.messagenumber') }}:{{ billno }}</li>
            <li class="message">{{ $t('OrderDetail.messagedate') }}：{{ billdate }}</li>
            <li class="message">{{ $t('OrderDetail.compName') }}：{{ compName }}</li>
            <li class="message">{{ $t('OrderDetail.amount') }}：{{ amount }}</li>
          </ul>
          <ul style="padding:0 20px;display: flex;">
            <li class="message">{{ $t('OrderDetail.checkstate') }}：
            <span id="checkstate" v-if="SignSt=='0'" style="color:#f9463f">{{$t('Order.unsignedconfirmation')}}</span>
            <span id="checkstate" v-if="SignSt=='1'" style="color:#777777">{{$t('Order.confirm')}}</span>
            <span id="checkstate" v-if="SignSt=='2'" style="color:#f9463f">{{$t('Order.unconfirmedchanges')}}</span>
            <span id="checkstate" v-if="SignSt=='3'" style="color:#f9463f">{{$t('button.veto')}}</span>
            </li>
            <li class="message">{{ $t('OrderDetail.goodsstate') }}：<span id="goodsstate" style="color:#f9463f">{{
    goodsstate
}}</span></li>
            <li class="message">{{ $t('OrderDetail.purchaser') }}：{{ purchaser }}</li>
            <li class="message">{{ $t('OrderDetail.OAmountWithTax') }}：{{ oAmountWithTax }}</li>
          </ul>
          <ul style="padding:0 20px;display: flex;">
            <li class="message">{{ $t('OrderDetail.remark') }}：{{ remark }}</li>
          </ul>
        </div>
      </div>
      <ul>
        <li class="messagetitle">{{ $t('comm.detailtitle') }}</li>
      </ul>
      <el-table :data="tableData" style="margin:0;width:100%;color:#777777;font-size:14px;" :height="tableHeight"
        v-loading="tableLoading">
        <el-table-column prop="number" width="60" :label="$t('OrderDetail.rowNo')"></el-table-column>
        <el-table-column prop="codeid" :label="$t('OrderDetail.codeid')"></el-table-column>
        <el-table-column prop="name" :label="$t('OrderDetail.name')"></el-table-column>
        <el-table-column prop="specifications" :label="$t('OrderDetail.specifications')"></el-table-column>
        <el-table-column prop="changenumber" align="right" :label="$t('OrderDetail.changenumber')">
          <template slot-scope="scope">
            <span>{{ scope.row.changenumber }}&nbsp{{ scope.row.SUnitName }}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="$store.state.ShowBasicUnit" prop="Quantity" align="right" :label="$t('OrderDetail.Quantity')">
          <template slot-scope="scope">
            <span>{{ scope.row.Quantity }}&nbsp{{ scope.row.UnitName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="freenumber" align="right" :label="$t('OrderDetail.freenumber')">
          <template slot-scope="scope">
            <span>{{ scope.row.freenumber }}&nbsp{{ scope.row.SUnitName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="nonumber" align="right" :label="$t('OrderDetail.nonumber')">
          <template slot-scope="scope">
            <span>{{ scope.row.nonumber }}&nbsp{{ scope.row.SUnitName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="price" align="right" :label="$t('OrderDetail.price')"></el-table-column>
        <el-table-column prop="taxamount" align="right" :label="$t('OrderDetail.taxamount')"></el-table-column>
        <el-table-column prop="deldate" :label="$t('OrderDetail.deldate')"></el-table-column>
        <el-table-column v-if="$store.state.ShowMTO" prop="MTONo" :label="$t('OrderDetailSearch.txtMTONo')"></el-table-column>
        <el-table-column prop="BatchNo" :label="$t('OrderDetailSearch.txtBatchNo')"></el-table-column>
        <el-table-column prop="remark" :label="$t('OrderDetail.remark')"></el-table-column>
        <el-table-column prop="address" :label="$t('OrderDetail.address')"></el-table-column>
        <el-table-column prop="RowState" :label="$t('OrderDetail.rowState')"></el-table-column>
      </el-table>
    </div>
    <el-dialog width="25%" :title="$t('OrderDetail.tip')" :visible.sync="printvisible" :append-to-body="true">
      <el-select style="width:100%" class="dialogselect" v-model="printType"
        :placeholder="typegather.length != 0 ? $t('OrderDetail.placeholder') : $t('OrderDetail.noneholder')">
        <el-option v-for="item in typegather" :key="item.value" :label="item.name" :value="item.value">
        </el-option>
      </el-select>
      <div slot="footer" class="dialog-footer">
        <el-button @click="print()" type="primary">{{ $t('button.determine') }}</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="dialogtitle" :visible.sync="dialogvisible">
      <el-table v-loading="dialogloading" :data="dialogdata" @select-all="handleSelectAll"
        @selection-change="handleSelectionChange" height="50vh" ref="dialogview">
        <el-table-column type="selection"></el-table-column>
        <el-table-column property="Number" :label="$t('comm.number')" width="100"></el-table-column>
        <el-table-column property="accessoryName" :label="$t('Download.accessoryname')"></el-table-column>
        <el-table-column property="date" :label="$t('Download.recupdate')"></el-table-column>
        <el-table-column prop="option" :label="$t('Download.operation')" width="100">
          <template slot-scope="scope">
            <el-link :underline="false" @click="DownLoadPrint(scope.$index, scope.row)" type="primary">
              {{ $t('Download.clickdownload') }}
            </el-link>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="DownLoadSelect">{{ $t('Download.downloadall') }}</el-button>
      </div>
    </el-dialog>
  </Frame>
</template>

<script>
import SvgIcon from '../components/SvgIcon'
import { Serialize,uploaddowninfo, downloadprint } from '../utils/utils'
export default {
  name: 'OrderDetail',
  components: { SvgIcon },
  data() {
    return {
      tableLoading: true,
      tableHeight: window.innerHeight - 390,
      compName: '',
      billno: '',
      billdate: '',
      oAmountWithTax:'',
      amount: '',
      checkstate: '',
      goodsstate: '',
      purchaser: '',
      SignSt:'',
      remark: '无',
      tableData: [],
      printvisible: false,
      dialogvisible: false,
      dialogloading: true,
      dialogtitle: '',
      printType: '',
      printtemp: '',
      dialogdata: [],
      typegather: [],
      diaselect: [],
      SCM78:false,
      operable:false,
    }
  },
  created() {
    this.billno = this.$route.query.DelBillNo;
    if(this.$route.query.operable){
      this.operable = eval(this.$route.query.operable);
    }
    this.loadpage();
  },
  mounted() {
    let that = this;
    window.onresize = () => {
      if (that.resizeFlag) {
        clearTimeout(that.resizeFlag);
      }
      that.resizeFlag = setTimeout(() => {
        that.tableHeight = window.innerHeight - 390;
        that.resizeFlag = null;
      }, 100);
    }
  },
  methods: {
    loadpage(){
      this.axiosInvoke("/SupplierComm/Handler/VueSysHandler.ashx",
      {
        action: "getorderdetail",
        billno: this.billno,
      }, (res) => {
        console.log(res.data);
        var data = eval(res.data);
        // this.tableData = data;
        this.compName = data[0].CompName;
        this.billdate = data[0].billdate;
        this.oAmountWithTax = data[0].OAmountWithTax;
        this.amount = data[0].amount;
        this.checkstate = data[0].checkstate;
        this.operable = data[0].operable;
        this.SignSt = data[0].SignSt;
     
        if (data[0].goodsstate == 0) this.goodsstate = this.$t('Order.unshipped');
        else if (data[0].goodsstate == 1) this.goodsstate = this.$t('Order.partialDel');
        else this.goodsstate = this.$t('Order.shipped');
        this.purchaser = data[0].purchaser;
        this.remark = data[0].remark;
        if(data[0].SCM78){
          this.SCM78 = true;
        }
        data.shift(0);
        this.tableData = data;
        this.tableLoading = false;
        console.log(data);
      })
    },
    confirm() {//确认签收
      let billnos = [this.billno];
      let SignSt = [this.SignSt];
      debugger
      this.axiosInvoke("/SupplierComm/Handler/OrderPurPurchaseHandler.ashx", {
        action: "comfirmorder",
        ay: JSON.stringify(billnos),
        say:JSON.stringify(SignSt)
        },
        (res) => {
          let data = eval(res.data);
          console.log(data);
          if(data[0][0]==this.billno){
            this.operable = false;
            this.loadpage();
            this.$store.commit("TIPPOP",{popType:'success',popMessage:this.$t("OrderDetail.orderconfirmed")});
          }
        })
    },
    veto() {//否决
      let billnos = [this.billno];
      debugger
      this.$prompt(this.$t("button.vetoreason"), this.$t("button.orderveto"), {
        confirmButtonText: this.$t("button.determine"),
        cancelButtonText: this.$t("button.cancel"),
      }).then(({ value }) => {
        console.log(value);
        this.tableLoading = true;
        this.axiosInvoke("/SupplierComm/Handler/OrderPurPurchaseHandler.ashx", {
        action: "denyorder",
        supReply:value,
        billno: this.billno,
        orgSignSt:this.SignSt
        },res=>{
          let data = eval(res.data);
          console.log(data);
          if(data[0].billno==this.billno){
            this.loadpage();
            this.tableLoading = false;
            this.$store.commit("TIPPOP",{popType:'success',popMessage:this.$t("Order.vetoorder")});
          }
        });
      });
    },
    getVaryData(){
      debugger
      sessionStorage.setItem("OrderDetail", JSON.stringify({
        pageindex: this.pageindex,
        billno: this.billno,
        hasBack: true,
        isDetialHref: true // 通过详情页访问，标识为true,用于后台查询作区分过滤条件
      }))
      this.$router.push({
        path: "/Home/OrderChangeSearch"
      })
    },
    download(action) {
      debugger
      this.dialogvisible = true;
      this.dialogloading = true;
      if(action == "viewall"){
                this.dialogtitle = this.$t('Download.viewalldrawings') + "(" + this.billno + ")";
            }else{
                this.dialogtitle = this.$t('Download.viewchangedrawings') + "(" + this.billno + ")";
            }

      this.axiosInvoke("/SupplierComm/Handler/DownprintHandler.ashx", {
        action: action,
        billNo: this.billno
      },
      (res) => {
        var data = eval(res.data);
        for (let i = 0; i < data.length; i++) {
          data[i].Number = i + 1;
        }
        this.dialogdata = data;
        this.dialogloading = false;
        this.$refs.dialogview.toggleAllSelection();
        this.diaselect = this.dialogdata;
        console.log(data);
      })
    },
    DownLoadPrint(index, row) {
      //下载图纸
      let that = this;
      debugger
      downloadprint(row.accessoryId, row.accessoryName);
      uploaddowninfo(this.billno, row.accessoryId, row.accessoryName, that);
    },
    DownLoadSelect() {
      let that = this;
      debugger
      if (that.diaselect.length > 0) {
        for (let i = 0; i < that.diaselect.length; i++) {
          downloadprint(that.diaselect[i].accessoryId, that.diaselect[i].accessoryName);
          uploaddowninfo(that.billno, that.diaselect[i].accessoryId, that.diaselect[i].accessoryName, that);
        }
      } else 
      this.$store.commit("TIPPOP",{popType:'warning',popMessage: this.$t('comm.unselectwarning')});
    },
    handleSelectAll(val) {
      this.diaselect = val;
    },
    handleSelectionChange(val) {
      this.diaselect = val;
    },
    Export() {
      //导出Excel
      let action = 'exportexcel'
      let progId = 'purPurchaseOrder'
      //取回当前查询表进行导出
      this.exportExcel(action, progId, this.tableData)
    },
    printdialog() {
      this.printvisible = true;
      this.axiosInvoke("/SupplierComm/Handler/VueSysHandler.ashx",
        {
          action: "doprintbill",
          BillNo: this.billno,
          ProgId: "purPurchaseOrder",
          lang: localStorage.getItem("language") == "CHS"?"zh-CHS":"zh-CHT"
        },
        (res) => {
          var data = eval(res.data);
          this.printtemp = data[0];
          this.typegather = data[0].files;
          this.printType = decodeURIComponent(data[0].printType);
          var printExist = false;
          this.typegather.forEach(v=>{
            if(v.value == this.printType){
              printExist = true;
              return;
            }
          })
          if(!printExist)this.printType = "";
        })
    },
    print() {//打印
      if(this.printType==""){
        this.$store.commit("TIPPOP",{popType:'warning',popMessage:this.$t("comm.selectprint")});
        return;
      }
      this.$cookies.set("purPurchaseOrderprintType", this.printType,"365D");
      var theParam = {
        progId: "purPurchaseOrder",
        lang: this.printtemp.lang,
        file: this.printType,
        date: '',
        billPrintKind: 0,
        conditions: this.printtemp.conditions
      }
      debugger
      var url = "/functions/report/ReportPreview.aspx?ispdf=1&params=" + encodeURI(Serialize(theParam));
      window.open(url, "_blank");
    }
  }
}
</script>

<style scoped>
ul {
  padding: 0;
}

li {
  list-style: none;
  text-align: left;
}

.el-select-dropdown__item {
  display: flex;
}

.messagetitle {
  color: #5d5d5d;
  font-size: 16px;
  width: 20%;
  padding: 0 20px;
}

.button {
  color: #5d5d5d;
  font-size: 15px;
  cursor: pointer;
  white-space: nowrap;
}
.buttonArray{
  width: calc( 70% - 40px );
    justify-content: flex-end;
    display: flex;
}
.button-confirm{
   color: #0177d5;
  font-size: 15px;
  cursor: pointer;
  padding-right: 5%;
  white-space: nowrap;
}
.button:hover {
  cursor: pointer;
  color: #0177d5;
}

.message {
  color: #777777;
  font-size: 14px;
  width: 24%;
  padding-right: 1%;

}

.option-icon{
  filter: grayscale(100%);
  padding-right:5%;
}
.option-icon:hover{
  filter: grayscale(0%);
}
.el-table /deep/ th.el-table__cell {
  background-color: #f9f9f9;
}

.el-table /deep/ .el-table__body-wrapper {
  font-size: 13px;
}

.el-table /deep/ thead {
  font-size: 14px;
  color: #777777;
}

.el-table /deep/ .el-table_1_column_1 .cell:first-child {
  padding: 0 0 0 30px;
}

.el-table /deep/ .cell:first-child {
  font-weight: normal;
}
</style>
