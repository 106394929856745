<template>
  <el-container>
    <img class="backgroundimg" :src="backgroundimg" />
    <div style="height:16vh;width: 100%;"></div>
    <el-main  @keyup.enter.navite="keyDown">
      <p class="title">{{ title }}</p>
      <el-input :placeholder="$t('Login.database')" maxlength="15" v-model="userinfo.database"><svg-icon slot="prefix"
          icon-class="database" /></el-input>
      <el-input :placeholder="$t('Login.userId')" maxlength="20" v-model="userinfo.userId"><svg-icon slot="prefix"
          icon-class="account" /></el-input>
      <el-input :placeholder="$t('Login.password')" v-model="userinfo.password" show-password><svg-icon slot="prefix"
          icon-class="password" /></el-input>
      <div class="operation">
        <el-checkbox v-model="reminfo" size="mini">{{ $t('Login.rempsaaword') }}</el-checkbox>
        <el-select v-model="lang" @change="changelang" size="mini">
          <el-option v-for="item in langinfo" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <el-button @click="doLogin" @keydown.enter.native.prevent="notopration" type="primary">{{ $t('Login.login') }}</el-button>
      <!-- // ADV202306050013 新版供应商，调整移除环境配置，不做插件的安装功能  -->
      <div class="screentip">{{ $t('Login.screentip') }}</div>
    </el-main>
    <DialogChangePwd ref="dialog" :userId="userinfo.userId" :groupId="userinfo.database" :changeSucess="changesucess"></DialogChangePwd>
    <el-footer>
      <div class="copyright">{{ webSupCopyRight }} &nbsp;&nbsp;<span @click="linkHref();">{{ internetContentProvider }}</span></div>
    </el-footer>
  </el-container>
</template>
  
<script>
import SvgIcon from "../components/SvgIcon"
import { decrypt, encrypt } from "../utils/encrypt";
import { loadAndUpdateVersion } from "../utils/utils";
import DialogChangePwd from '@/components/DialogChangePwd';
export default {
  components: {
    SvgIcon,
    DialogChangePwd
  },
  name: 'Login',
  props: {
    msg: String
  },
  data() {
    return {
      loginDate:'',
      reminfo: false,
      relogin: false, //是否强制登录
      unCheckPwdValidateDate: false, //不验证密码有效期（false默认验证）
      langinfo: [{
        value: 'CHS',
        label: '中文简体(切换)'
      }, {
        value: 'CHT',
        label: '中文繁体(切換)'
      }], // 新版供应不再支持英文版，已跟燕珊沟通确认——2023-06-08    
      // ADV202306050013 新版供应商，调整移除环境配置，不做插件的安装功能
      userinfo: {
        database: "",
        userId: "",
        password: ""
      },
      value: "CHS",
      lang: "",
      enter:true,
      title:this.$t('Login.title'),
      backgroundimg:require('../assets/img/Login/background.png'),
      webSupCopyRight:'',
      internetContentProvider:'',
      internetContentProviderHref:''
    }
  },
  created() {
    this.keyDown();
  },
  mounted() {
    this.lang = (localStorage.getItem("language")) ? localStorage.getItem("language") : "中文简体(切换)";
    this.value = (localStorage.getItem("language")) ? localStorage.getItem("language") : "CHS";
    if(localStorage.getItem("PCBackgroundImg")&&localStorage.getItem("PCBackgroundImg")!=""){
            this.backgroundimg = localStorage.getItem("PCBackgroundImg");
          }
    this.getUserConfig();
  },
  methods: {
    keyDown() {
      document.onkeydown = ev => {
        if (this.$route.path == "/Login" && typeof(ev.key)!='undefined' && ev.key.toLowerCase() === 'enter' &&  this.$refs.dialog.dialogFormVisible == false && this.enter) //enter键触发登录
        {
          console.log(this.$route.path);
          this.doLogin();
        }
      }
    },
    //检查三个输入框是否为空
    checkEmpty() {
      if (!this.userinfo.database) {
        this.$store.commit("TIPPOP",{popType:'error',popMessage:this.$t("Login.databaseText")});
        return false;
      } else if (!this.userinfo.userId) {
        this.$store.commit("TIPPOP",{popType:'error',popMessage:this.$t("Login.userIdText")});
        return false;
      } else if (!this.userinfo.password) {
        this.$store.commit("TIPPOP",{popType:'error',popMessage:this.$t("Login.passwordText")});
        return false;
      } else return true;
    },
    notopration(){},
    //登录按钮触发的事件
    doLogin() {
      if (this.checkEmpty()) {
        var encrypwd = encrypt(this.userinfo.password);
        var rememberme = "";
        if (this.reminfo) {
          rememberme = "on"
          // if (!(this.userinfo.database == this.$cookies.get("database") && this.userinfo.password == this.$cookies.get("password") && this.userinfo.userId == this.$cookies.get("userId"))) {
          //   this.$cookies.set("database", this.userinfo.database, "365D",'/','',false,'HttpOnly');
          //   this.$cookies.set("webUserId", this.userinfo.userId, "365D");
          //   this.$cookies.set("password", encrypwd,"365D");
          // }
          }
        //else{
        //   if(this.$cookies.get("password")){
        //     this.$cookies.remove("password");
        //   }
        // }
        // this.$cookies.set("reminfo", this.reminfo, "365D");
        const loading = this.$loading({
          lock: true,
          text: this.$t('Login.loginload'),
          spinner: 'el-icon-loading',
          background: 'rgba(0,0,0,0.7)'
        });
        this.axiosInvoke("/SupplierComm/handler/SysHandler.ashx?action=login&platform=supplier",
          {
            groupId: this.userinfo.database,
            userId: this.userinfo.userId,
            password: this.userinfo.password,
            relogin: this.relogin, //是否强制登录
            unCheckPwdValidateDate: this.unCheckPwdValidateDate, //不验证密码有效期
            language: this.value == "CHS"?"zh-CHS":"zh-CHT",
            remembernum:'on',
            rememberme
          },
          (res) => {
            loading.close();
            localStorage.setItem('language', this.value);
            this.$cookies.set("remainpassword", encrypwd,"365D");
            if (res.data != "") {
              //===========根据返回结果判断是否登录成功、重复登录或其他情况============
              var responseText = res.data
              var list = responseText.split('|<_nbsjson_>|');
              var obj = eval("(" + responseText + ")");
              if (list.length == 1 && obj.success) {
                this.turnToDefault();
                sessionStorage.setItem("KeepAlive", "KeepAlive");
                console.log(obj);
              }
              else {
                switch (obj.errorCode) {
                  case "pwdRemind": //密码过期提示
                    {
                      var msg = obj.reason
                      this.ConfirmBox(msg, this.$t('Home.changepassword'), 'changePassword'); //提示内容，提示标题，触发事件
                    } break;
                    case "pwdChangeRequired": //密码已过期提示
                    {
                      var msg = obj.errors.reason
                      this.ConfirmBox(msg, this.$t('Home.changepassword'), 'passwordHasExpired'); //提示内容，提示标题，触发事件
                    } break;
                  case "initialPassword": //强制修改默认密码
                    {
                      var msg = obj.reason
                      this.ConfirmBox(msg, this.$t('Home.changepassword'), 'initialPassword'); //提示内容，提示标题，触发事件
                    } break;
                  case "userExist": //当前帐号已经在其他地方登录，如果您强制登录对方将退出，是否强制登录？
                    {
                      var msg = this.$t("Login.userExist");
                      this.ConfirmBox(msg, this.$t('Login.prompt'), 'reLogin'); //提示内容，提示标题，触发事件
                    } break;
                  case "userLogined": //当前浏览器已经有一个登录用户
                    {
                      var msg = this.$t("Login.alreadyLogined").format(obj.groupId, obj.userId);
                      this.ConfirmBox(msg, this.$t('Login.prompt'), 'reLogin'); //提示内容，提示标题，触发事件
                    }
                    break;
                  case "needcertificate":
                    // 没有证书信息，请重新下载证书
                    // downloadCertificate(obj.errors.reason);
                    break;
                  default:
                    // if (obj.errors && obj.errors.reason) {
                    //     Ext.Msg.alert(CapWeb.ML.Login.failure, obj.errors.reason);
                    // }
                    break;
                }
              }
            }
          }
        )
      }
    },
    changesucess(newpwd){
      this.unCheckPwdValidateDate = true;
      this.userinfo.password = newpwd;
      this.doLogin();
    },
    //弹出提示窗
    ConfirmBox(msg, promptTxt, doSth) {
      this.enter = false;
      let confirm = this.$confirm(msg, promptTxt, {
        confirmButtonText: this.$t('button.determine'), //确定按钮
        cancelButtonText: this.$t('button.cancel'), //取消按钮
        type: "warning",
        distinguishCancelAndClose: true, //设置true才会把右上角X和取消区分开来
        closeOnClickModal: false
      });
      confirm.then(() => {
        //确认通过执行逻辑
        // console.log("确认了！")
        switch (doSth) {
          case 'reLogin':
            {
              this.enter = true;
              this.relogin = true; //强制登录
              this.doLogin();
            }
            break;
          case 'passwordHasExpired':
          case 'changePassword':
          case 'initialPassword':
            {
              //显示修改密码弹窗
              this.enter = true;
              this.$refs.dialog.isPwdRemind = true
              this.$refs.dialog.dialogFormVisible = true 
            }
            break;
        }
      })
      confirm.catch((e) => {
        if (e == 'cancel') {
          //确认不通过执行逻辑
          // console.log("取消了！")
          switch (doSth) {
            case 'changePassword': // 修改密码选择为否时，不验证密码有效期，执行登录动作
              {
                this.unCheckPwdValidateDate = true; //不验证密码有效期
                this.doLogin();
              }
              break;
          }
        } else if (e == 'close') {
          //右上角X执行逻辑
          // console.log("关闭了！")
        }
      })
    },
    turnToDefault() {
      this.axiosInvoke("/SupplierComm/Handler/VueSysHandler.ashx", {
          action: "checkaccount"
        },
          (res,errflag) => {
            if(!errflag) this.$router.push("/Home");
          })
      
    },
    //获取账套信息
    getUserConfig() {
      const cookieload = this.$loading({
          lock: true,
          text: this.$t('Login.cookieload'),
          spinner: 'el-icon-loading',
          background: 'rgba(0,0,0,0.7)'
        });
      this.axiosInvoke("/SupplierComm/Handler/VueSysHandler.ashx", {
        action: "getuserconfig",
      },
        res => {
          cookieload.close();
          let data = eval(res.data);
          console.log(data);
          this.loginDate = data[0].loginDate;
          this.userinfo.database = data[0].groupId;
          this.userinfo.userId = data[0].userId;
          this.userinfo.password = data[0].xPassword;
          this.reminfo = data[0].rememberme=="on"?true:false;
          if(data[1].Title&&data[1].Title!=""){
            this.title = data[1].Title;
          }
          if(data[1].BackgroundImg&&data[1].BackgroundImg!=""){
            this.backgroundimg = data[1].BackgroundImg;
            localStorage.setItem("PCBackgroundImg",data[1].BackgroundImg);
          }else{
            this.backgroundimg = require('../assets/img/Login/background.png');
            localStorage.removeItem("PCBackgroundImg");
          }
          if(data[1].WebSupCopyRight && data[1].WebSupCopyRight!="")
          {
            this.webSupCopyRight = data[1].WebSupCopyRight;
          }
          if(data[1].InternetContentProvider && data[1].InternetContentProvider!="")
          {
            this.internetContentProvider = data[1].InternetContentProvider;
          }
          if(data[1].InternetContentProviderHref && data[1].InternetContentProviderHref!="")
          {
            this.internetContentProviderHref = data[1].InternetContentProviderHref;
          }
          loadAndUpdateVersion(this);
        })
    },
    linkHref(){
      if(this.internetContentProviderHref && this.internetContentProviderHref !=""){
        window.open(this.internetContentProviderHref);
      }
    },
    //语言选择时触发的事件
    changelang(value) {
      this.langinfo.filter((x) => {
        if (x.value == value) {
          this.lang = x.label;
          this.value = x.value;
          if (value == 'CHT') localStorage.setItem('language', 'CHT');
          if (value == 'CHS') localStorage.setItem('language', 'CHS');
          if (value == 'En') localStorage.setItem('language', 'En');
          this.$router.go(0);
        }
      })
    },
    configselect(value) {
      console.log(value);
    }
  }
}

String.prototype.format = function () {
  if (arguments.length == 0) return this;
  for (var s = this, i = 0; i < arguments.length; i++) {
    s = s.replace(new RegExp("\\{" + i + "\\}", "g"), arguments[i]);
  }
  return s;
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-container {
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  /* background-image: url(../assets/img/Login/background.png);
  background-size: cover;
  background-repeat: no-repeat; */
  padding: 0;
  margin: 0;
}

.el-main {
  text-align: center;
}

.operation {
  width: 384px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto 20px auto;
}

.el-button {
  width: 382px;
}

.el-main {
  background-image: url(../assets/img/Login/containerbg.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 590px;
  height: 475px;
  padding: 0;
  border-radius: 8px;
  flex: none;
}

.title {
  font-size: 31.5px;
  color: #0375f9;
  font-family: "siyuanmedium";
  margin: 37px 0 37px 0;
}

.el-input {
  width: 384px;
  margin-bottom: 20px;
  font-size: 19px;
  color: #999999;
}

.el-input /deep/ .el-input__inner {
  border-radius: 0;
  font-family: "siyuanregular";
}

.el-input /deep/ .el-input__inner::-webkit-input-placeholder {
  color: #999999;
}

.svg-icon {
  width: 1.15rem;
  height: 1.15rem;
  margin: 0.6rem 0.1rem;
}

.el-checkbox {
  zoom: 115.5%;
  color: #0375f9;
  font-family: "siyuanregular";
}

.el-select /deep/.el-input__inner {
  width: 8.3rem;
  text-align: right;
  padding: 0;
  border: none;
  background-color: rgba(255, 255, 255, 0);
  color: #999999;
  font-size: 16px;
  font-family: "siyuanregular";
}

.el-select /deep/.el-icon-arrow-up:before {
  content: none;
}

.el-button {
  border: none;
  border-radius: 0;
  padding: 5px;
  font-size: 22px;
  font-family: "siyuanregular";
  background-image: url(../assets/img/Login/loginbtn.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.environment {
  margin: 12px auto 10px;
  width: 384px;
  text-align: right;
}

.environment /deep/ .el-input__inner {
  width: 5.3rem;
  font-size: 15px;
  font-family: "siyuanregular";
  color: #0375f9;
}

.copyright {
  color: #ffffff;
  font-family: "siyuanregular";
  font-size: 18px;
  margin-top: 7vh;
}

.copyright span {
  color: #ffffff;
  font-family: "siyuanregular";
  font-size: 18px;
  cursor: pointer;
}
.screentip {
  color: #999999;
  padding-top: 30px;
}
.backgroundimg{
  position: fixed;
  height: 100vh;
  width: 100vw;
  min-width: 1080px;
  min-height: 600px;
  z-index: -999;
}
</style>
  