<template>
  <Frame>
    <PageHeader :title="$t('comm.reconciliationsearch')" :hasBack="false" />
    <div class="reconciliationsearch" style="padding:10px 0 0;">
      <div class="reconciliationmessage">
        <span class="detialmessage">{{ $t('ReconciliationSearch.messagenumber') }}：<el-input
            :placeholder="$t('ReconciliationSearch.inputpls')" v-model="billno" clearable
            style="width:20%;color:#bbbbbb;" @change="search(0)" size="small"></el-input></span>
        <span class="detialmessage" >{{ $t('ReconciliationSearch.messagedate') }}：
          <el-date-picker style="width:20%;" v-model="myDate" type="daterange" range-separator="~" :unlink-panels="true" size="small"
            :start-placeholder="$t('comm.startdate')" :end-placeholder="$t('comm.enddate')" @change="gettime()" :default-value="timeDefault">
          </el-date-picker>
        </span>
        <span class="detialmessage">{{ $t('ReconciliationSearch.compName') }}：<el-input
            :placeholder="$t('ReconciliationSearch.inputpls')" v-model="compName" clearable
            style="width:20%;color:#bbbbbb;" @change="search(0)" size="small"></el-input></span>
        <span style="float:right;padding-right:20px;padding-bottom:20px;">
          <el-button type="reset" @click="reset()" style="padding: 8px 20px;">{{ $t('button.reset') }}</el-button>
          <el-button type="primary" @click="search(0)" style="padding: 8px 20px;">{{ $t('button.query') }}</el-button>
        </span>
      </div>
      <el-table ref="multipleTable" :data="tableData" :height="tableHeight" style="margin:0;width:100%"
        tooltip-effect="dark" v-loading="tableLoading" @selection-change="handleSelectionChange"
        :header-cell-style="moneyclass">
        <el-table-column type="selection"></el-table-column>
        <el-table-column prop="BillNo" :label="$t('ReconciliationSearch.messagenumber')">
          <template slot-scope="scope">
            <el-link :underline="false" @click="check(scope.$index, scope.row)">
              {{ scope.row.BillNo }}</el-link>
          </template></el-table-column>
        <el-table-column prop="BillDate" :label="$t('ReconciliationSearch.messagedate')"></el-table-column>
        <el-table-column prop="CompName" :label="$t('ReconciliationSearch.compName')"></el-table-column>
        <el-table-column prop="ConditionName" :label="$t('ReconciliationSearch.payment')"></el-table-column>
        <el-table-column prop="OAmountWithTax" align="right" width="170" :label="$t('ReconciliationSearch.taxamount')">
          <template scope="scope"><span class="moneyornumber">{{ scope.row.OAmountWithTax }}</span></template>
        </el-table-column>
        <el-table-column prop="Remark" :label="$t('ReconciliationSearch.remark')"></el-table-column>
        <el-table-column width="100" :label="$t('ReconciliationSearch.operation')">
          <template slot-scope="scope">
            <el-link :underline="false" @click="check(scope.$index, scope.row)" type="primary">
              {{ $t('button.check') }}
            </el-link>
          </template>
        </el-table-column>
      </el-table>
      <div class="bottom">
        <el-checkbox style="margin:auto 0;" v-model="checked" @change="checkall"></el-checkbox>
        <span style="width: calc( 50% - 15px ); padding-left:15px;">
          <el-button @click="exportdialog()"  v-if="$store.state.exportExcelState" >
            <img style="height:12px;width:12px" src="../assets/img/button/batchexport.png" class="img">{{ $t('button.batchexport') }}</el-button>
          <el-button icon="el-icon-print" id="print" @click="printdialog()">
            <img style="height:12px;width:12px" src="../assets/img/button/BatchPrint.png" class="img">{{ $t('button.batchPrint') }}</el-button>
        </span>
        <el-pagination style="width:45%;margin:auto 0;" @current-change="handleCurrentChange"
          layout="total,prev,pager,next,jumper" :pager-count="5" :current-page.sync="currentPage" :total="totalCount">
        </el-pagination>
      </div>
    </div>
    <el-dialog :title="$t('button.exporttitle')" :visible.sync="batchexport" width="25%">
      <el-radio v-model="radio" label="exportTextA" @change="changeRadio($event)" v-if="selectexport">{{ $t('button.exportTextA')}}</el-radio>
      <el-radio v-model="radio" label="exportTextB" @change="changeRadio($event)">{{ $t('button.exportTextB')}}</el-radio>
      <el-select style="width:100%;margin-top: 30px;" class="dialogselect" v-model="exportType"
            :placeholder="typegather.length != 0 ? $t('Order.exportPlaceHolder') : $t('Order.exportNoneholder')">
            <el-option v-for="item in typegather" :key="item.value" :label="item.name" :value="item.value">
            </el-option>
        </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel()">{{ $t('button.cancel') }}</el-button>
        <el-button type="primary" @click="determine(true)">{{ $t('button.determine') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog width="25%" :title="$t('OrderDetail.tip')" :visible.sync="printvisible">
      <el-radio v-model="printRadio" label="PrintTextA" @change="changePrintRadio($event)" v-if="selectprint">{{ $t('button.printTextA')}}</el-radio>
      <el-radio v-model="printRadio" label="PrintTextB" @change="changePrintRadio($event)">{{ $t('button.printTextB')}}</el-radio>
        <el-select style="width:100%;margin-top: 30px;" class="dialogselect" v-model="printType"
            :placeholder="typegather.length != 0 ? $t('OrderDetail.placeholder') : $t('OrderDetail.noneholder')">
            <el-option v-for="item in typegather" :key="item.value" :label="item.name" :value="item.value">
            </el-option>
        </el-select>
        <div slot="footer" class="dialog-footer">
            <el-button @click="determine()" type="primary">{{ $t('button.determine') }}</el-button>
        </div>
    </el-dialog>
  </Frame>
</template>

<script>
import SvgIcon from '../components/SvgIcon'
import { handleShortDate } from '@/utils/handleDate';
import { radioJudgeExcel } from '@/utils/utils';
import { BillParams, PrintView } from '@/utils/ReportPreView';
export default {
  name: 'ReconciliationSearch',
  components: { SvgIcon },
  data() {
    return {
      batchexport: false,
      radio: 'exportTextA',
      printRadio:'PrintTextA',
      tableHeight: window.innerHeight - 285,
      billno: '',
      compName:'',
      myDate: '',
      datefrom: '',
      dateto: '',
      pagesize: 10,
      pageindex: 0,
      currentPage: 1,
      totalCount: 0,
      checked: false,
      tableLoading: false,
      tableData: [],
      selectData: [],
      timeDefault:'',
      printvisible: false, //使用打印功能
      dialogvisible: false,
      dialogloading: true,
      dialogtitle: '',
      printType: '',
      // printData:[],
      printtemp: '',
      typegather: [],
      progId: 'ApPurCheckReqBill',
      billOrgId: '',
      billTypeId: '',
      selectexport:false,
      exportType:'',
      printType: '',
      selectprint:false,
    }
  },
  created() {
    if (sessionStorage.getItem("ReconciliationDetail") && sessionStorage.getItem("ReconciliationDetail") != "null") {
      var pageinfo = JSON.parse(sessionStorage.getItem("ReconciliationDetail"));
      this.billno = pageinfo.billno;
      this.compName = pageinfo.compName;
      this.myDate = pageinfo.myDate;
      this.datefrom = pageinfo.datefrom;
      this.dateto = pageinfo.dateto;
      this.pageindex = pageinfo.pageindex;
    }
    if(document.body.clientWidth<1327){
      this.tableHeight = window.innerHeight - 310;
    }
  },
  mounted() {
    this.getWebSupConfig();
    let that = this;
    window.onresize = () => {
      if (that.resizeFlag) {
        clearTimeout(that.resizeFlag);
      }
      that.resizeFlag = setTimeout(() => {
        if(document.body.clientWidth<1327){
      that.tableHeight = window.innerHeight - 310;
    }else{
      that.tableHeight = window.innerHeight - 285;
    }
        that.resizeFlag = null;
      }, 100);
    }
    this.timeDefault =new Date();
    this.timeDefault.setMonth(new Date().getMonth()-1);
    this.search(this.pageindex);
  },
  methods: {
    getWebSupConfig(){
      this.axiosInvoke("/SupplierComm/Handler/OrderPurPurchaseHandler.ashx", {
        action: "getconfigreqbill"
        },res=>{
          this.progId = eval(res.data); // 通过后台检查平台配置信息进行获取不同对账单
        });
    },
    search(pageindex) {
      this.tableLoading = true;
      this.axiosInvoke("/SupplierComm/Handler/OrderPurPurchaseHandler.ashx", {
        action: "getreconciliation",
        pageindex,
        pagesize: this.pagesize,
        datefrom: this.datefrom,
        dateto: this.dateto,
        billno: this.billno,
        compName: this.compName,
        excel:""
      },
        (res) => {
          var data = eval(res.data);
          this.totalCount = data[0].totalCount;
          this.currentPage = pageindex + 1;
          data.shift();
          this.tableData = data;
          this.tableLoading = false;
          console.log(data);
        })
    },
    //获取时间
    gettime() {
      if(this.myDate){
        this.datefrom = handleShortDate(this.myDate[0], "From");
        this.dateto = handleShortDate(this.myDate[1], "To");
      }else{
        this.datefrom = "";
        this.dateto="";
      }
     
      this.search(0);
    },
    exportdialog(){
      let prams = BillParams(this,true);
      this.billOrgId = prams.billOrgId;
      this.billTypeId = prams.billTypeId;
      this.batchexport = prams.printvisible;
      if(this.selectData.length>0){
        this.selectexport = true;
        this.radio = "exportTextA";
      }else{
        this.selectexport = false;
        this.radio = "exportTextB";
      }
    },
    changeRadio(val) {
      this.radio = val;
    },
    changePrintRadio(val){
      this.printRadio = val;
    },
    checkall() {
      if (this.checked) this.$refs.multipleTable.toggleAllSelection();
      else this.$refs.multipleTable.clearSelection();
    },
    handleSelectionChange(val) {
      this.selectData = val;
      console.log(this.selectData);
      if (val.length == this.tableData.length) {
        this.checked = true;
      } else {
        this.checked = false;
      }
    },
    handleCurrentChange(val) {
      this.search(val - 1);
      this.pageindex = val-1;
      this.checked = false;
      this.selectData = [];
    },
    reset() {//重置
      this.billno = "";
      this.myDate = "";
      this.compName = "";
      this.datefrom = "";
      this.dateto = "";
      this.pageindex = 0;
      this.search(0);
    },
    moneyclass({row,column,rowIndex,columnIndex}){
      if(rowIndex==0&&columnIndex==5){
        return "padding-right:40px;"
      }
    },
    query() {//查询
      this.tableData;
      for (var i = 0; i < this.tableData.length; i++) {
      }
    },
    check(index, scope) {//查看
      sessionStorage.setItem("Reconciliation", JSON.stringify({
        billno: this.billno,
        compName:this.compName,
        myDate: this.myDate,
        datefrom: this.datefrom,
        dateto: this.dateto,
        pageindex: this.pageindex
      }))
      this.$router.push({
        path: "/Home/ReconciliationDetail",
        query: {
          DelBillNo: scope.BillNo,
        }
      })
    },
    cancel() {//取消
      this.batchexport = false;
    },
    determine(isexcel) {//确定
      if(this.exportType==""&&isexcel){
        this.$store.commit("TIPPOP",{popType:'warning',popMessage:this.$t("comm.selectexport")});
        return;
      }
      if(this.printType==""&&!isexcel){
        this.$store.commit("TIPPOP",{popType:'warning',popMessage:this.$t("comm.selectprint")});
        return;
      }
      this.batchexport = false;
      if((this.radio == "exportTextB"&&isexcel)||(this.printRadio == "PrintTextB"&&!isexcel)){
        this.axiosInvoke("/SupplierComm/Handler/OrderPurPurchaseHandler.ashx", {
          action: "getreconciliation",
          pageindex:0,
          pagesize: 2147483647,
          datefrom: this.datefrom,
          dateto: this.dateto,
          billno: this.billno,
          compName: this.compName,
          excel:true
      },(res)=>{
        this.printtemp.selectKeys = this.progId + "_selectKeys";
        PrintView(this,isexcel);
      })
      }else{
        PrintView(this,isexcel);
      }
    },
    //打印弹窗对话框
    printdialog() {
      let prams = BillParams(this,true);
      this.printvisible = prams.printvisible;
      if(this.selectData.length>0){
        this.selectprint = true;
        this.printRadio = "PrintTextA";
      }else{
        this.selectprint = false;
        this.printRadio = "PrintTextB";
      }
    }
  }
}
</script>

<style scoped>
.detialmessage {
  color: #5d5d5d;
  font-size: 14px;
  width: 20%;
  padding-left: 10px;
  padding-right: 10px;
}

.reconciliationmessage {
  padding: 10px 20px;
}

.el-checkbox /deep/ .el-checkbox__label {
  color: #5d5d5d;
  font-size: 14px;
}
.moneyornumber {
  padding-right: 40px;
  display: flex;
  justify-content: flex-end;
}
.el-table /deep/ th.el-table__cell {
  background-color: #f9f9f9;
}

.el-table /deep/ .el-table__body-wrapper {
  font-size: 13px;
}

.el-table /deep/ thead {
  font-size: 14px;
  color: #5d5d5d;
}

.el-table /deep/ .el-table__header .cell .el-checkbox:first-child {
  padding-left: 5px;
}

.topbutton {
  color: #777777;
  font-size: 14px;
}

.img {
  padding-right: 8px;
}

.el-dialog__wrapper /deep/ .el-dialog .el-dialog__header {
  border-bottom: 1px solid rgb(235, 233, 233);
}

.el-dialog__wrapper /deep/ .el-dialog .el-dialog__body {
  border-bottom: 1px solid rgb(235, 233, 233);
}

.bottom {
  display: flex;
  width: calc(100% - 15px);
  padding: 10px 0 0 15px;
}
</style>
