<template>
    <Frame>
      <PageHeader :title="$t('comm.returnQuery')" :hasBack="false" />
      <div class="returnQuery" style="padding:10px 0 0;">
        <div class="delivermessage">
          <span class="detialmessage">{{ $t('ReturnQuery.billDate') }}：
            <el-date-picker v-model="billdate" type="daterange" style="width:15%;color:#bbbbbb;" range-separator="~"
              @change="getTime()" :start-placeholder="$t('comm.startdate')" :end-placeholder="$t('comm.enddate')"
              :unlink-panels="true" :default-value="timeDefault" size="small">
            </el-date-picker></span>
          <span class="detialmessage">{{ $t('ReturnQuery.billNo') }}：<el-input
              :placeholder="$t('ReturnQuery.inputpls')" v-model="billno" clearable style="width:15%;color:#bbbbbb;"
              @change="inputchange(billno)" size="small"></el-input></span>
          <span class="detialmessage">{{ $t('ReturnQuery.orgName') }}：<el-input
              :placeholder="$t('ReturnQuery.inputpls')" v-model="orgName" clearable style="width:15%;color:#bbbbbb;"
              @change="inputchange(orgName)" size="small"></el-input></span>
        </div>
        <div class="delivermessage">
          <span style="float:right;padding-right:20px;padding-bottom:20px;">
            <el-button type="reset" @click="reset()">{{ $t('button.reset') }}</el-button>
            <el-button type="primary" @click="query()">{{ $t('button.query') }}</el-button>
          </span>
        </div>
        <el-table ref="multipleTable" :data="tableData" :height="tableHeight" style="margin:0;width:100%"
          tooltip-effect="dark" v-loading="tableLoading" @selection-change="handleSelectionChange">
          <el-table-column type="selection"></el-table-column>
          <el-table-column prop="billdate" :label="$t('ReturnQuery.billDate')"></el-table-column>
          <el-table-column prop="billno" :label="$t('ReturnQuery.billNo')">
            <template scope="scope"><el-link :underline="false" @click="check(scope.$index, scope.row)">
              {{ scope.row.billno }}</el-link></template>
          </el-table-column>
          <el-table-column prop="OrgName" :label="$t('ReturnQuery.orgName')"></el-table-column>
          <el-table-column prop="purchaser" :label="$t('ReturnQuery.purchaser')"></el-table-column>
          <el-table-column prop="oAmountWithTax" :label="$t('ReturnQuery.oAmountWithTax')"></el-table-column>
          <el-table-column :label="$t('ReturnQuery.operation')" :width="100">
            <template slot-scope="scope">
              <el-button class="topbutton" size="mini" type="text" @click="check(scope.$index, scope.row)">{{
                  $t('button.check')
              }}</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="width:100%;display:flex;">
          <div style="width:35%; padding-left:15px;bottom:30px;">
            <el-checkbox v-model="checked" @change="checkall"></el-checkbox>
            <span style="padding-left:15px;">
              <el-button @click="exportdialog()" v-if="$store.state.exportExcelState">
                <img src="../assets/img/button/batchexport.png" style="width: 12px;height:12px;" class="img">{{$t('button.batchexport')}}</el-button>
              <el-button icon="el-icon-print" id="print" @click="printdialog()">
                <img src="../assets/img/button/BatchPrint.png" style="width: 12px;height:12px;" class="img">{{$t('button.batchPrint')}}</el-button>
            </span>
          </div>
          <el-pagination style="width:50%;margin:auto 0;" @current-change="handleCurrentChange" ref="pageination"
            layout="total,prev,pager,next,jumper" :pager-count="5" :current-page.sync="currentPage" :total="totalCount">
          </el-pagination>
        </div>
      </div>
      <el-dialog :title="$t('button.exporttitle')" :visible.sync="batchexport" width="25%">
      <el-radio v-model="radio" label="exportTextA" @change="changeRadio($event)" v-if="selectexport">{{ $t('button.exportTextA')}}</el-radio>
      <el-radio v-model="radio" label="exportTextB" @change="changeRadio($event)">{{ $t('button.exportTextB')}}</el-radio>
      <el-select style="width:100%;margin-top: 30px;" class="dialogselect" v-model="exportType"
            :placeholder="typegather.length != 0 ? $t('Order.exportPlaceHolder') : $t('Order.exportNoneholder')">
            <el-option v-for="item in typegather" :key="item.value" :label="item.name" :value="item.value">
            </el-option>
        </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel()">{{ $t('button.cancel') }}</el-button>
        <el-button type="primary" @click="determine(true)">{{ $t('button.determine') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog width="25%" :title="$t('OrderDetail.tip')" :visible.sync="printvisible">
      <el-radio v-model="printRadio" label="PrintTextA" @change="changePrintRadio($event)" v-if="selectprint">{{ $t('button.printTextA')}}</el-radio>
      <el-radio v-model="printRadio" label="PrintTextB" @change="changePrintRadio($event)">{{ $t('button.printTextB')}}</el-radio>
        <el-select style="width:100%;margin-top: 30px;" class="dialogselect" v-model="printType"
            :placeholder="typegather.length != 0 ? $t('OrderDetail.placeholder') : $t('OrderDetail.noneholder')">
            <el-option v-for="item in typegather" :key="item.value" :label="item.name" :value="item.value">
            </el-option>
        </el-select>
        <div slot="footer" class="dialog-footer">
            <el-button @click="determine()" type="primary">{{ $t('button.determine') }}</el-button>
        </div>
    </el-dialog>
    </Frame>
  </template>
  
  <script>
  import SvgIcon from '../components/SvgIcon'
  import { handleShortDate } from '../utils/handleDate.js'
  import { BillParams, PrintView } from '@/utils/ReportPreView'
  export default {
    name: 'ReturnQuery',
    components: { SvgIcon },
    data() {
      return {
        batchexport: false,
        radio: 'exportTextA',
        printRadio:'PrintTextA',
        tableHeight: window.innerHeight - 332,
        billno: '',
        orgName: '',
        billdate: '',
        startdate: '',
        enddate: '',
        checked: '',
        pageindex: 0,
        tableLoading: true,
        currentPage: 1,
        totalCount: 0,
        pagesize: 10,
        timeDefault:'',
        tableData: [],
        selectData: [],
        printvisible: false, //使用打印功能
        dialogvisible: false,
        dialogloading: true,
        dialogtitle: '',
        printType: '',
        // printData:[],
        printtemp: '',
        typegather: [],
        progId: 'purReceivingOrder',
        billOrgId: '',
        billTypeId: '',
        exportType:'',
        selectprint:false,
        selectexport:false,
        BillType:'Return'
      }
    },
    created() {
      if (this.$route.query.date && this.$route.query.startdate && this.$route.query.enddate) {
        this.billdate = JSON.parse(this.$route.query.date);
        this.startdate = this.$route.query.startdate;
        this.enddate = this.$route.query.enddate;
      }
      //打开页面时，判断是否为详情页面返回，若是则读取进入详情页面前保存的当前页面信息
      debugger
      if (sessionStorage.getItem("ReturnQueryDetail") && sessionStorage.getItem("ReturnQueryDetail") != "null") {
        var pageinfo = JSON.parse(sessionStorage.getItem("ReturnQueryDetail"));
        this.pageindex = pageinfo.pageindex;
        this.startdate = pageinfo.startdate;
        this.enddate = pageinfo.enddate;
        this.billdate = pageinfo.billdate;
        this.billno = pageinfo.billno;
        this.orgName = pageinfo.orgName;
  
        //已经读取完毕 清除session以免下次进入时有记录
        sessionStorage.removeItem("ReturnQueryDetail");
        this.getReturnQuery(pageinfo.pageindex);
      } else
        this.getReturnQuery(0);
    },
    mounted() {
      let that = this;
      window.onresize = () => {
        if (that.resizeFlag) {
          clearTimeout(that.resizeFlag);
        }
        that.resizeFlag = setTimeout(() => {
          that.tableHeight = window.innerHeight - 332;
          that.resizeFlag = null;
        }, 100);
      }
      this.timeDefault =new Date();
      this.timeDefault.setMonth(new Date().getMonth()-1);
    },
    methods: {
      check(index, scope) {//查看详情
        sessionStorage.setItem("ReturnQuery", JSON.stringify({
          //点击查看详情时，保留当前页面信息，返回时再次加载该页面信息
          startdate: this.startdate,
          enddate: this.enddate,
          billno: this.billno,
          orgName: this.orgName,
          billdate: this.billdate,
          pageindex: this.pageindex
        }))
        this.$router.push({
          path: "/Home/ReturnQueryDetail",
          query: {
            DelBillNo: scope.billno,
          }
        })
      },
      changeRadio(val) {
        this.radio = val;
      },
      changePrintRadio(val){
        this.printRadio = val;
      },
      getReturnQuery(pageindex) {
        //获取页面数据
        this.pageindex = pageindex;
        this.currentPage = pageindex + 1;
        this.tableLoading = true;
        this.axiosInvoke("/SupplierComm/Handler/OrderPurPurchaseHandler.ashx", {
          action: "getreturnquery",
          pageindex: pageindex,
          pagesize: this.pagesize,
          startdate: this.startdate,
          enddate: this.enddate,
          billno: this.billno,
          orgName: this.orgName,
          excel: ""
        }, (res) => {
          console.log(res.data);
          var data = eval(res.data);
          this.totalCount = data[0].totalCount;
          data.shift(0);
          this.tableData = data;
          this.tableLoading = false;
        })
      },
      inputchange(val) {
        this.getReturnQuery(0);
      },
      reset() {//重置
        this.billno = "";
        this.orgName = "";
        this.billdate = "";
        this.startdate = "";
        this.enddate = "";
        this.getReturnQuery(0);
      },
      query() {//查询
        this.getReturnQuery(0);
      },
      //获取时间
      getTime() {
        if(this.billdate){
          this.startdate = handleShortDate(this.billdate[0], "From");
          this.enddate = handleShortDate(this.billdate[1], "To");
        }
        else{
          this.startdate = "";
          this.enddate = "";
        }
        this.getReturnQuery(0);
      },
      handleSelectionChange(val) {
        this.selectData = val;
        console.log(this.selectData);
        if (val.length == this.tableData.length) {
          this.checked = true;
        } else {
          this.checked = false;
        }
      },
      checkall() {
        if (this.checked) this.$refs.multipleTable.toggleAllSelection();
        else this.$refs.multipleTable.clearSelection();
      },
      cancel() {//取消
        this.batchexport = false;
      },
      determine(isexcel) {//确定
      debugger
      if(this.exportType==""&&isexcel){
        this.$store.commit("TIPPOP",{popType:'warning',popMessage:this.$t("comm.selectexport")});
        return;
      }
      if(this.printType==""&&!isexcel){
        this.$store.commit("TIPPOP",{popType:'warning',popMessage:this.$t("comm.selectprint")});
        return;
      }
        this.batchexport = false;
        if((this.radio == "exportTextB"&&isexcel)||(this.printRadio == "PrintTextB"&&!isexcel)){
          this.axiosInvoke("/SupplierComm/Handler/OrderPurPurchaseHandler.ashx", {
            action: "getreturnquery",
            pageindex: this.pageindex,
            pagesize: this.pagesize,
            startdate: this.startdate,
            enddate: this.enddate,
            billno: this.billno,
            orgName: this.orgName,
            excel: true
        },(res)=>{
          this.printtemp.selectKeys = this.progId + "_selectKeys";
          PrintView(this,isexcel);
        })
        }else{
        PrintView(this,isexcel);
        }
      },
      handleCurrentChange(val) {
        //翻页时触发的方法
        this.pageindex = val - 1;
        this.getReturnQuery(val - 1);
      },
      exportdialog(){
        let prams = BillParams(this,true);
        this.billOrgId = prams.billOrgId;
        this.billTypeId = prams.billTypeId;
        this.batchexport = prams.printvisible;
        debugger
        if(this.selectData.length>0){
          this.selectexport = true;
          this.radio = "exportTextA";
        }else{
          this.selectexport = false;
          this.radio = "exportTextB";
        }
      },
      //打印弹窗对话框
    printdialog() {
      let prams = BillParams(this,true);
      this.printvisible = prams.printvisible;
      if(this.selectData.length>0){
        this.selectprint = true;
        this.printRadio = "PrintTextA";
      }else{
        this.selectprint = false;
        this.printRadio = "PrintTextB";
      }
    }
    }
  }
  </script>
  
  <style scoped>
  .detialmessage {
    color: #5d5d5d;
    font-size: 14px;
    width: 20%;
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .delivermessage {
    padding: 10px 20px;
  }
  
  .el-checkbox /deep/ .el-checkbox__label {
    color: #5d5d5d;
    font-size: 14px;
  }
  
  .el-table /deep/ .cell {
    overflow: initial;
  }
  
  .el-table /deep/ th.el-table__cell {
    background-color: #f9f9f9;
  }
  
  .el-table /deep/ .el-table__body-wrapper {
    font-size: 13px;
  }
  
  .el-table /deep/ thead {
    font-size: 14px;
    color: #5d5d5d;
  }
  
  .el-table /deep/ .el-table__header .cell .el-checkbox:first-child {
    padding-left: 5px;
  }
  
  .stateborder {
    padding: 4px 8px;
    border-radius: 15px;
    border: 1px solid;
  }
  
  .topbutton {
    color: #0177d5;
    font-size: 14px;
  }
  
  .img {
    padding-right: 8px;
  }
  
  .el-dialog__wrapper /deep/ .el-dialog .el-dialog__header {
    border-bottom: 1px solid rgb(235, 233, 233);
  }
  
  .el-dialog__wrapper /deep/ .el-dialog .el-dialog__body {
    border-bottom: 1px solid rgb(235, 233, 233);
  }
  </style>
  