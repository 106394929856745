<template>
  <Frame>
    <PageHeader :title="$t('comm.supplyassessment')" :hasBack="false" />
    <div class="supplyassessment" style="padding:10px 10px 0;">
      <div class="supplymessage">
        <el-form :model="rulerform" :rules="rules">
          <el-form-item prop="date" :label="$t('SupplyAssessment.messagedate') + ':'">
            <el-date-picker v-model="rulerform.date" type="daterange" style="color:#bbbbbb;" range-separator="~"
              @change="getTime()" :start-placeholder="$t('comm.startdate')" :unlink-panels="true"
              :end-placeholder="$t('comm.enddate')" :default-value="timeDefault" size="small"></el-date-picker>
          </el-form-item>
          <el-form-item :label="$t('SupplyAssessment.product') + ':'" style="padding-left: 50px;">
            <el-input :placeholder="$t('SupplyAssessment.inputpls')" v-model="product" clearable @change="search(0)"
              style="color:#bbbbbb;" size="small"></el-input>
          </el-form-item>
        </el-form>
        <span style="float:right;padding:6px 20px 14px 0;">
          <el-button type="reset" @click="reset()" style="padding: 8px 20px;">{{ $t('button.reset') }}</el-button>
          <el-button type="primary" @click="query()" style="padding: 8px 20px;">{{ $t('button.query') }}</el-button>
        </span>
      </div>
      <el-table ref="multipleTable" :data="tableData" :height="tableHeight" style="margin:0;width:100%"
        tooltip-effect="dark" v-loading="tableLoading">
        <el-table-column prop="YearMonth" :label="$t('SupplyAssessment.yearmonth')"></el-table-column>
        <el-table-column prop="MaterialId" :label="$t('SupplyAssessment.codeid')"></el-table-column>
        <el-table-column prop="MaterialName" :label="$t('SupplyAssessment.name')"></el-table-column>
        <el-table-column prop="MaterialSpec" :label="$t('SupplyAssessment.specifications')"></el-table-column>
        <el-table-column prop="PurNum" align="right" :label="$t('SupplyAssessment.purchasenumber')"></el-table-column>
        <el-table-column prop="DeliveryNum" align="right" min-width="100" :label="$t('SupplyAssessment.deliverablenumber')"></el-table-column>
        <el-table-column prop="ReciveNum" align="right" :label="$t('SupplyAssessment.receiptnumber')"></el-table-column>
        <el-table-column prop="BackNum" align="right" :label="$t('SupplyAssessment.cancelnumber')"></el-table-column>
        <el-table-column prop="DelayNum" align="right" :label="$t('SupplyAssessment.overduenumber')"></el-table-column>
        <el-table-column prop="SpecialNum" align="right" :label="$t('SupplyAssessment.specialnumber')"></el-table-column>
        <el-table-column prop="CheckBackNum" align="right" :label="$t('SupplyAssessment.rejectionnumber')"></el-table-column>
        <el-table-column prop="DelayRate" min-width="120" :label="$t('SupplyAssessment.overdueproportion')"></el-table-column>
        <el-table-column prop="BackRate" min-width="120" :label="$t('SupplyAssessment.cancelproportion')"></el-table-column>
      </el-table>
      <div>
        <el-pagination style="padding-left:50%;width:50%;" @current-change="handleCurrentChange"
          layout="total,prev,pager,next,jumper" :pager-count="5" :current-page="currentPage" :total="totalCount">
        </el-pagination>
      </div>
    </div>
  </Frame>
</template>

<script>
import SvgIcon from '../components/SvgIcon'
import { handleShortDate } from '@/utils/handleDate';
export default {
  name: 'SupplyAssessment',
  components: { SvgIcon },
  data() {
    return {
      tableHeight: window.innerHeight - 273,
      product: '',
      date: '',
      rulerform: {
        date: "",
      },
      startdate: '',
      enddate: '',
      pageindex: 0,
      pagesize: 10,
      currentPage: 1,
      totalCount: 0,
      tableLoading: false,
      timeDefault:'',
      tableData: [],
      rules: {
        date: [
          { required: true, message: "11111", trigger: 'change' }
        ]
      }
    }
  },
  created() {
    this.defaultDatePicker();
  },
  mounted() {
    let that = this;
    window.onresize = () => {
      if (that.resizeFlag) {
        clearTimeout(that.resizeFlag);
      }
      that.resizeFlag = setTimeout(() => {
        that.tableHeight = window.innerHeight - 273;
        that.resizeFlag = null;
      }, 100);
    }
    this.timeDefault =new Date();
    this.timeDefault.setMonth(new Date().getMonth()-1);
    this.search(0);
  },
  methods: {
    search(pageindex) {
      if (!this.rulerform.date) {
        this.$store.commit("TIPPOP",{popType:'error',popMessage: this.$t('Report.withouttime')});
        return;
      }
      this.tableLoading = true;
      this.currentPage = pageindex + 1;
      this.axiosInvoke("/SupplierComm/Handler/VueReportHandler.ashx", {
        action: 'qmassessalsrpt',
        startdate: this.startdate,
        enddate: this.enddate,
        material: this.product,
        pagesize: this.pagesize,
        pageindex,
      }, (res,errflag) => {
        if(errflag) {this.tableLoading = false; this.tableData = null; return;}        
        var data = eval(res.data);
        this.totalCount = data[0].totalCount;
        data.shift();
        this.tableData = data;
        this.tableLoading = false;
      })
    },
    defaultDatePicker() {
      var date = new Date();
      var datebegin = new Date(new Date(date.getFullYear() + "-" + (date.getMonth() + 1) + "-01"));
      if (date.getMonth() != 11)
        var dateend = new Date(new Date(date.getFullYear() + "-" + (date.getMonth() + 2) + "-01") - 24 * 3600 * 1000);
      else var dateend = new Date(new Date(date.getFullYear + "-12-31"));
      this.startdate = handleShortDate(datebegin, "From");
      this.enddate = handleShortDate(dateend, "From");
      this.rulerform.date = [datebegin, dateend];
    },
    getTime() {
      this.startdate = handleShortDate(this.rulerform.date[0], "From");
      this.enddate = handleShortDate(this.rulerform.date[1], "To");
      this.search(0);
    },
    handleCurrentChange(val) {
      this.search(val - 1);
    },
    reset() {//重置
      this.product = "";
      this.rulerform.date = "";
      this.startdate = "";
      this.currentPage = 1;
      this.enddate = "";
      this.defaultDatePicker();
      this.search(0);
    },
    query() {//查询
      this.search(0);
    },
    //加载el-tooltip信息，鼠标聚焦时显示完整信息
    renderTitle(h, { column }) {
      let titleValue = '';
      let columnRealWidth = column.realWidth;
      switch (column.property) {
        case 'YearMonth':
          titleValue = this.$t("SupplyAssessment.yearmonth");
          break;
        case 'MaterialId':
          titleValue = this.$t("SupplyAssessment.codeid");
          break;
        case 'MaterialName':
          titleValue = this.$t("SupplyAssessment.name");
          break;
        case 'MaterialSpec':
          titleValue = this.$t("SupplyAssessment.specifications");
          break;
        case 'PurNum':
          titleValue = this.$t("SupplyAssessment.purchasenumber");
          break;
        case 'DeliveryNum':
          titleValue = this.$t("SupplyAssessment.deliverablenumber");
          break;
        case 'ReciveNum':
          titleValue = this.$t("SupplyAssessment.receiptnumber");
          break;
        case 'BackNum':
          titleValue = this.$t("SupplyAssessment.cancelnumber");
          break;
        case 'DelayNum':
          titleValue = this.$t("SupplyAssessment.overduenumber");
          break;
        case 'SpecialNum':
          titleValue = this.$t("SupplyAssessment.specialnumber");
          break;
        case 'CheckBackNum':
          titleValue = this.$t("SupplyAssessment.rejectionnumber");
          break;
        case 'DelayRate':
          titleValue = this.$t("SupplyAssessment.overdueproportion");
          break;
        case 'BackRate':
          titleValue = this.$t("SupplyAssessment.cancelproportion");
          break;
        default:
          titleValue = 'ARSearch默认栏位信息（测试）'
          break;
      }
      return this.renderTooltip(h, titleValue, columnRealWidth)
    }
  }
}
</script>

<style scoped>
.detialmessage {
  color: #5d5d5d;
  font-size: 14px;
}

.supplymessage {
  padding: 10px 20px 0 10px;
  display: flex;
  justify-content: space-between;
}

.el-form {
  width: 80%;
  display: flex;
  flex-wrap: nowrap;
}

.el-form /deep/ .el-form-item {
  display: flex;
  margin-bottom: 0;
}

.el-checkbox /deep/ .el-checkbox__label {
  color: #5d5d5d;
  font-size: 14px;
}

.el-table /deep/ .el-table__header .cell {
  overflow: hidden;
  white-space: nowrap;
}

.el-table /deep/ th.el-table__cell {
  background-color: #f9f9f9;
}

.el-table /deep/ .el-table__body-wrapper {
  font-size: 13px;
}

.el-table /deep/ thead {
  font-size: 14px;
  color: #5d5d5d;
}
.el-form /deep/.el-form-item__label{
  white-space: nowrap;
}
.topbutton {
  color: #777777;
  font-size: 14px;
}
</style>
