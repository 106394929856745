<template>
    <Frame>
        <PageHeader :title="$t('comm.bulletin')" :hasBack="false" />
        <div>
            <div class="search-row">
                <p class="label">{{ $t('BulletinCenter.maintitle') }}:</p><el-input size="small" v-model="searchinput"
                    :placeholder="$t('HelpCenter.inputpls')" @change="search(0)"></el-input>
                <p class="label">{{ $t('HelpCenter.type') }}:</p><el-select size="small" v-model="value"
                    @change="search(0)">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"
                        :disabled="item.disabled">
                    </el-option>
                </el-select>
                <el-button @click="resetInput" plain>{{ $t('button.reset') }}</el-button>
                <el-button @click="search(0)" type="primary">{{ $t('button.search') }}</el-button>
            </div>
            <div class="download-form">
                <el-table :data="tableData" style="width: 100%" :height="tableHeight" v-loading="loading">
                    <el-table-column prop="CateName" width="200" :label="$t('Download.type')">
                    </el-table-column>
                    <el-table-column prop="name" :label="$t('BulletinCenter.maintitle')">
                        <template slot-scope="scope">
                            <el-link :underline="false" index="/Home/BulletinCenter/BulletinCenterDetail"
                                @click="viewDetail(scope.$index, scope.row)">
                                {{ scope.row.name}}
                            </el-link>
                        </template>
                    </el-table-column>
                    <el-table-column prop="date" :label="$t('Download.date')">
                    </el-table-column>
                    <el-table-column prop="option" :label="$t('HelpCenter.operation')" width="100">
                        <template slot-scope="scope">
                            <el-link :underline="false" index="/Home/BulletinCenter/BulletinCenterDetail"
                                @click="viewDetail(scope.$index, scope.row)" type="primary">
                                {{ $t('button.check')}}
                            </el-link>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="pagination">
                <el-pagination style="padding-left:50%;width:50%;" @current-change="handleCurrentChange"
                    layout="total,prev,pager,next,jumper" :pager-count="5" :current-page="currentPage"
                    :total="totalCount">
                </el-pagination>
            </div>
        </div>
    </Frame>
</template>

<script>
export default {
    name: "HelpCenter",
    data() {
        return {
            options: [{ value: 'AllBulletin', label: this.$t('comm.alltype') },
            { value: 'SysBulletin', label: this.$t('BulletinCenter.sysbulletin') },
            { value: 'SalBulletin', label: this.$t('BulletinCenter.salbulletin') },
            { value: 'EpBulletin', label: this.$t('BulletinCenter.epbulletin') }],
            tableData: [],
            value: '',
            searchinput: '',
            loading: false,
            totalCount: 0,
            currentPage: 1,
            pageindex: 0,
            tableHeight: window.innerHeight - 312,
        }
    },
    created() {
        if (sessionStorage.getItem("BulletinCenterDetail") && sessionStorage.getItem("BulletinCenterDetail") != "null") {
            debugger
            var history = JSON.parse(sessionStorage.getItem("BulletinCenterDetail"));
            this.value = history.value;
            this.searchinput = history.searchinput;
            this.pageindex = history.pageindex;
            this.search(this.pageindex);
            sessionStorage.removeItem("BulletinCenterDetail");
        } else this.search(0);
    },
    mounted() {
        let that = this
        window.onresize = () => {
            if (that.resizeFlag) {
                clearTimeout(that.resizeFlag);
            }
            that.resizeFlag = setTimeout(() => {
                that.tableHeight = window.innerHeight - 312;
                that.resizeFlag = null;
            }, 100);
        }
    },
    methods: {
        resetInput() {
            this.value = "";
            this.searchinput = "";
        },
        search(pageindex) {
            //搜索按钮的方法
            this.currentPage = pageindex + 1;
            this.loading = true;
            this.axiosInvoke("/SupplierComm/Handler/VueSysHandler.ashx", {
                action: "getbulletin",
                keyword: this.searchinput,
                typeId: this.value,
                pageindex: pageindex,
                pagesize: 10
            }, (res) => {
                //console.log(res.data);
                var data = eval("(" + res.data + ")");
                this.totalCount = data[0].totalCount;
                data.shift(0);
                this.tableData = data;
                this.loading = false;
                console.log(data);
            })
            //this.searchinput  输入框的内容 this.value 选择框的内容
        },
        viewDetail(index, row) {
            debugger
            sessionStorage.setItem("BulletinCenter", JSON.stringify({
                value: this.value,
                searchinput: this.searchinput,
                pageindex: this.pageindex
            }))
            this.$router.push({
                path: "/Home/BulletinCenterDetail",
                query: {
                    Title: row.name,
                    BillNo: row.BillNo,
                }
            })
            console.log(index, row)//index 行号 row 行内容
        },
        handleCurrentChange(val) {
            this.pageindex = val - 1;
            this.search(val - 1);
        }
    }
}
</script>

<style scoped>
.el-container /deep/.el-main {
    padding: 0;
    margin: 0;
}

.el-input {
    width: 22%;
}

.el-input__inner {
    font-family: "siyuanregular";
    margin: auto 0;
}

.el-input__inner::placeholder {
    font-family: "siyuanregular";
}

.el-select .el-input {
    width: 100%;
}

.el-select {
    width: 10%;
    margin-right: 33%;
}

.label {
    margin: auto 0;
    text-align: right;
    width: 9%;
    padding-right: 1%;
}


.search-row {
    height: 32px;
    margin: 1.5rem 0;
    width: 100%;
    display: flex;
}

.search-row .el-button {
    padding: 9px 15px !important;
}

.download-form {
    width: 95%;
    padding: 20px 2.5%;
}

.pagination {
    width: 100%;
}
</style>