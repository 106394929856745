<template>
  <Frame>
    <PageHeader :title="$t('comm.reconciliationdetail')" :hasBack="true" detailType="ReconciliationDetail" />
    <div class="reconciliationdetail" style="padding:10px;">
      <div class="contain">
        <div class="message" style="width:100%;">
          <ul>
            <li class="messagetitle">{{ $t('comm.messagetitle') }}</li>
            <li style="width:9%;" class="button option-icon" id="Export" @click="Export()" v-if="$store.state.exportExcelDetailState"> <svg-icon
                icon-class="export" />{{ $t('button.export') }}</li>
            <li style="width:9%;" class="button option-icon" id="print" @click="printdialog()"><svg-icon
                icon-class="print" />{{ $t('button.print') }}</li>
          </ul>
          <ul style="padding:0 20px;display: flex;">
            <li class="message">{{ $t('ReconciliationDetail.messagenumber') }}:{{ billno }}</li>
            <li class="message">{{ $t('ReconciliationDetail.messagedate') }}：{{ billdate }}</li>
            <li class="message">{{ $t('ReconciliationDetail.purchaser') }}：{{ purchaser }}</li>
          </ul>
          <ul style="padding:0 20px;display: flex;">
            <li class="message">{{ $t('ReconciliationDetail.amount') }}：{{ amount }}</li>
            <li class="message">{{ $t('ReconciliationDetail.taxes') }}：{{ taxes }}</li>
            <li class="message">{{ $t('ReconciliationDetail.taxamount') }}：{{ taxamount }}</li>
          </ul>
          <ul style="padding:0 20px;display: flex;">
            <li class="message">{{ $t('ReconciliationDetail.remark') }}：{{ remark }}</li>
          </ul>
        </div>
      </div>
        <div class="messagetitle" style="padding: 0 16px 20px;">{{ $t('comm.detailtitle') }}</div>
      <el-table :data="tableData" :height="tableHeight" style="margin:0;width:100%" v-loading="tableLoading">
        <el-table-column prop="Number" width="60" :label="$t('ReconciliationDetail.number')"></el-table-column>
        <el-table-column prop="MaterialId" :label="$t('ReconciliationDetail.codeid')"></el-table-column>
        <el-table-column prop="MaterialName" :label="$t('ReconciliationDetail.name')"></el-table-column>
        <el-table-column prop="MaterialSpec" :label="$t('ReconciliationDetail.specifications')"></el-table-column>
        <el-table-column prop="SQuantity" align="right" :label="$t('ReconciliationDetail.pricenumber')">
          <template slot-scope="scope">
            <span>{{ scope.row.SQuantity }}&nbsp{{ scope.row.SUnitName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="SPrice" align="right"  :label="$t('ReconciliationDetail.price')"></el-table-column>
        <el-table-column prop="OAmountWithTax" align="right"  :label="$t('ReconciliationDetail.taxamount')"></el-table-column>
        <el-table-column prop="FromSourceTag" min-width="120" :label="$t('ReconciliationDetail.sourcetype')"></el-table-column>
        <el-table-column prop="FromBillNo" :label="$t('ReconciliationDetail.sourcenumber')"></el-table-column>
        <el-table-column prop="FromPurPurchaseOrder" :label="$t('ReconciliationDetail.purnumber')"></el-table-column>
        <el-table-column prop="Remark" :label="$t('ReconciliationDetail.remark')"></el-table-column>
      </el-table>
    </div>
    <el-dialog width="25%" :title="$t('OrderDetail.tip')" :visible.sync="printvisible" :append-to-body="true">
      <el-select style="width:100%" class="dialogselect" v-model="printType"
        :placeholder="typegather.length != 0 ? $t('OrderDetail.placeholder') : $t('OrderDetail.noneholder')">
        <el-option v-for="item in typegather" :key="item.value" :label="item.name" :value="item.value">
        </el-option>
      </el-select>
      <div slot="footer" class="dialog-footer">
        <el-button @click="print()" type="primary">{{ $t('button.determine') }}</el-button>
      </div>
    </el-dialog>
  </Frame>
</template>

<script>
import {Serialize} from '../utils/utils'
import SvgIcon from '../components/SvgIcon'
export default {
  name: 'ReconciliationDetail',
  components: { SvgIcon },
  data() {
    return {
      progId:'ApPurCheckReqBill',
      tableHeight: window.innerHeight - 390,
      billno: '',
      billdate: '',
      amount: '',
      taxes: '',
      taxamount: '',
      purchaser: '',
      remark: '',
      tableLoading: false,
      tableData: [],
      typegather:[],
      printvisible:false,
      printType: '',
      printtemp: '',
    }
  },
  created() {
    this.billno = this.$route.query.DelBillNo;
  },
  mounted() {
    let that = this;
    window.onresize = () => {
      if (that.resizeFlag) {
        clearTimeout(that.resizeFlag);
      }
      that.resizeFlag = setTimeout(() => {
        that.tableHeight = window.innerHeight - 390;
        that.resizeFlag = null;
      }, 100);
    }
    this.getDetail();
  },
  methods: {
    Export() {//导出
      let action = 'exportexcel';
      let progId = this.progId; // 'ApPurCheckReqBill'
      //取回当前查询表进行导出
      this.exportExcel(action, progId, this.tableData)
    },
    print() {//打印
      if(this.printType==""){
        this.$store.commit("TIPPOP",{popType:'warning',popMessage:this.$t("comm.selectprint")});
        return;
      }
      this.$cookies.set(this.progId+"printType", this.printType,"365D");
      var theParam = {
        progId: this.progId,  //"ApPurCheckReqBill",
        lang: this.printtemp.lang,
        file: this.printType,
        date: '',
        billPrintKind: 0,
        conditions: this.printtemp.conditions
      }
      var url = "/functions/report/ReportPreview.aspx?ispdf=1&params=" + encodeURI(Serialize(theParam));
      window.open(url, "_blank");
    },
    printdialog() {
      this.printvisible = true;
      this.axiosInvoke("/SupplierComm/Handler/VueSysHandler.ashx",
        {
          action: "doprintbill",
          BillNo: this.billno,
          ProgId: this.progId, //"ApPurCheckReqBill",
          lang: localStorage.getItem("language") == "CHS"?"zh-CHS":"zh-CHT"
        },
        (res) => {
          var data = eval(res.data);
          this.printtemp = data[0];
          this.typegather = data[0].files;
          this.printType = decodeURIComponent(data[0].printType);
          var printExist = false;
          this.typegather.forEach(v=>{
            if(v.value == this.printType){
              printExist = true;
              return;
            }
          })
          if(!printExist)this.printType = "";
        })
    },
    getDetail() {
      this.tableLoading = true;
      this.axiosInvoke(
        "/SupplierComm/Handler/VueSysHandler.ashx", {
        action: 'getreconciliationdetail',
        billno: this.billno
      }, (res) => {
        var data = eval(res.data);
        this.progId = data[0].ProgId;
        this.billdate = data[0].BillDate;
        this.purchaser = data[0].PersonName;
        this.amount = data[0].OAmount;
        this.taxes = data[0].OTax;
        this.taxamount = data[0].OAmountWithTax;
        this.remark = data[0].Remark;
        data.shift(0);
        this.tableData = data;
        this.tableLoading = false;
        console.log(data);
      }
      );
    }
  }
}
</script>

<style scoped>
.message ul {
  list-style: none;
  padding: 0;
  display: flex;
}

.message li {
  text-align: left;
  list-style: none;
}

.messagetitle {
  color: #5d5d5d;
  font-size: 16px;
  width: 70%;
  padding: 0 20px;
}

.button {
  color: #5d5d5d;
  font-size: 15px;
  cursor: pointer;
}

.message {
  color: #777777;
  font-size: 14px;
  width: 30%;
  padding-right: 2%;
}

.el-table /deep/ th.el-table__cell {
  background-color: #f9f9f9;
}

.el-table /deep/ .el-table__body-wrapper {
  font-size: 13px;
}

.el-table /deep/ thead {
  font-size: 14px;
  color: #777777;
}

.el-table /deep/ .el-table_1_column_1 .cell:first-child {
  padding: 0 0 0 30px;
}

.el-table /deep/ .cell:first-child {
  font-weight: normal;
}
.option-icon{
  filter: grayscale(100%);
  padding-right:5%;
}
.option-icon:hover{
  filter: grayscale(0%);
}
.button:hover {
  cursor: pointer;
  color: #0177d5;
}
</style>
