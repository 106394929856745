<template>
  <Frame>
    <PageHeader :title="$t('comm.inspectionresults')" :hasBack="false" />
    <div class="inspectionresults" style="padding:10px 10px 0;">
      <div class="inspectionmessage">
        <span class="detialmessage">{{ $t('InspectionResults.messagedate') }}：
          <el-date-picker v-model="checkdate" type="daterange" style="width:26%;color:#bbbbbb;" range-separator="~"
            @change="getTime()" :start-placeholder="$t('comm.startdate')" :end-placeholder="$t('comm.enddate')"
            :unlink-panels="true" :default-value="timeDefault" size="small">
          </el-date-picker></span>
        <span class="detialmessage" style="padding-left:4%">{{ $t('InspectionResults.product') }}：<el-input
            :placeholder="$t('InspectionResults.inputPls')" v-model="material" clearable
            style="width:22%;color:#bbbbbb;" @change="search(0)" size="small"></el-input></span>
        <span class="detialmessage" style="padding-left:4%">{{ $t('InspectionResults.sourcenumber') }}：<el-input
            :placeholder="$t('InspectionResults.inputpls')" v-model="frombillno" clearable
            style="width:22%;color:#bbbbbb;" @change="search(0)" size="small"></el-input></span>
      </div>
      <div class="inspectionmessage">
        <span style="float:right;padding-right:20px;padding-bottom:20px;">
          <el-button type="reset" @click="reset()" style="padding: 8px 20px;">{{ $t('button.reset') }}</el-button>
          <el-button type="primary" @click="query()" style="padding: 8px 20px;">{{ $t('button.query') }}</el-button>
        </span>
      </div>
      <el-table ref="multipleTable" :data="tableData" :height="tableHeight" style="margin:0;width:100%"
        tooltip-effect="dark" v-loading="tableloading">
        <el-table-column prop="CheckDate" :label="$t('InspectionResults.messagedate')"></el-table-column>
        <el-table-column prop="MaterialId" :label="$t('InspectionResults.codeid')"></el-table-column>
        <el-table-column prop="MaterialName" :label="$t('InspectionResults.name')"></el-table-column>
        <el-table-column prop="MaterialSpec" :label="$t('InspectionResults.specifications')"></el-table-column>
        <el-table-column v-if="RDC006" prop="CarrySQty" align="right" :label="$t('InspectionResults.deliverynumber')">
          <template slot-scope="scope">
            <span>{{ scope.row.CarrySQty}}&nbsp{{ scope.row.SUnitName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="ReceivingQty" align="right" :label="$t('InspectionResults.receiptnumber')">
          <template slot-scope="scope">
            <span>{{ scope.row.ReceivingQty}}&nbsp{{ scope.row.SUnitName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="GradeSQty" align="right" :label="$t('InspectionResults.passnumber')">
          <template slot-scope="scope">
            <span>{{ scope.row.GradeSQty}}&nbsp{{ scope.row.SUnitName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="BadnessSQty" align="right" :label="$t('InspectionResults.nonumber')">
          <template slot-scope="scope">
            <span>{{ scope.row.BadnessSQty}}&nbsp{{ scope.row.SUnitName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="CheckResult" :label="$t('InspectionResults.result')">
          <template slot-scope="scope">
            <span :style="scope.row.CheckResult == 0 ? 'display:block' : 'display:none'">
            </span>
            <span :style="scope.row.CheckResult == 1 ? 'display:block' : 'display:none'">
              <span  style="color:#1aa97b;">{{ $t('Report.qualified') }}</span>
            </span>
            <span :style="scope.row.CheckResult == 2 ? 'display:block' : 'display:none'">
              <span  style="color:#e54737;">{{ $t('Report.disqualification') }}</span>
            </span>
            <span :style="scope.row.CheckResult == 3 ? 'display:block' : 'display:none'">
              <span  style="color:#317dc9;">{{ $t('Report.other') }}</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="FromBillNo" :label="$t('InspectionResults.sourcenumber')">
          <template scope="scope">
            <el-link :underline="false" @click=" ToOrderDetail(scope.row.FromBillNo)">
            {{ scope.row.FromBillNo }}</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination style="padding-left:50%;width:50%;" @current-change="handleCurrentChange"
          layout="total,prev,pager,next,jumper" :pager-count="5" :current-page.sync="currentPage" :total="totalCount">
        </el-pagination>
      </div>
    </div>
  </Frame>
</template>

<script>
import SvgIcon from '../components/SvgIcon'
import { handleShortDate } from '@/utils/handleDate';
export default {
  name: 'InspectionResults',
  components: { SvgIcon },
  data() {
    return {
      tableHeight: window.innerHeight - 335,
      checkdate: '',
      pagesize: 10,
      pageindex: 0,
      tableloading: false,
      totalCount: 0,
      currentPage: 1,
      material: "",
      startdate: "",
      enddate: "",
      timeDefault:"",
      RDC006:false,
      frombillno: "",
      tableData: [{
        date: '检验日期',
        sourcenumber: '来源订单号',
        id: '产品代码',
        name: '产品名称',
        specifications: '产品规格',
        deliverynumber: '送货数量',
        receiptnumber: '收货数量',
        passnumber: '合格数量',
        nonumber: '不合格数',
        result: '判定结果'
      }]
    }
  },
  created(){
    this.getSessionInform("InspectionResults",(v)=>{
      this.checkdate=v.checkdate
      this.startdate=v.startdate
      this.enddate=v.enddate
      this.pageindex=v.pageindex
      this.currentPage=v.currentPage
      this.pagesize=v.pagesize
      this.material=v.material
      this.frombillno=v.frombillno
    })
  },
  mounted() {
    let that = this;
    window.onresize = () => {
      if (that.resizeFlag) {
        clearTimeout(that.resizeFlag);
      }
      that.resizeFlag = setTimeout(() => {
        that.tableHeight = window.innerHeight - 335;
        that.resizeFlag = null;
      }, 100);
    }
    this.timeDefault =new Date();
    this.timeDefault.setMonth(new Date().getMonth()-1);
    this.search(this.pageindex);
  },
  methods: {
    reset() {//重置
      this.product = "";
      this.checkdate = "";
      this.startdate = "";
      this.enddate = "";
      this.material="",
      this.frombillno = "";
      this.sourcenumber = "";
      this.currentPage = 1;
      this.search(0);
    },
    getTime() {
      if(this.checkdate){
        this.startdate = handleShortDate(this.checkdate[0], "From");
        this.enddate = handleShortDate(this.checkdate[1], "To");
      }
      else{
        this.startdate = "";
        this.enddate = "";
      }
      this.search(0);
    },
    search(pageindex) {
      this.pageindex = pageindex;
      this.tableloading = true;
      this.axiosInvoke("/SupplierComm/Handler/VueReportHandler.ashx", {
        action: 'purreceivingorderdetail',
        pageindex,
        pagesize: this.pagesize,
        frombillno: this.frombillno,
        material: this.material,
        startdate: this.startdate,
        enddate: this.enddate
      }, (res,errflag) => {
        if(errflag) { this.tableloading = false; this.tableData = null; return;}
        var data = eval(res.data);
        console.log(data);
        this.totalCount = data[0].totalCount;
        this.currentPage = pageindex + 1;
        this.RDC006 = eval(data[0].RDC006);
        data.shift();
        this.tableData = data;
        this.tableloading = false;
      })
    },
    query() {//查询
      this.search(0);
    },
    handleCurrentChange(val) {
      this.pageindex = val - 1;
      this.search(val - 1);
    },
      //跳转到orderDetail
      ToOrderDetail(billno){
            this.recoredPageToJump({
              checkdate:this.checkdate,
              startdate:this.startdate,
              enddate:this.enddate,
              pageindex:this.pageindex,
              currentPage: this.currentPage,
              pagesize: this.pagesize,
              material:this.material,
              frombillno:this.frombillno,
            },"InspectionResults",billno,"OrderDetail");
        },
    //加载el-tooltip信息，鼠标聚焦时显示完整信息
    renderTitle(h, { column }) {
      let titleValue = '';
      let columnRealWidth = column.realWidth;
      switch (column.property) {
        case 'CheckDate':
          titleValue = this.$t("InspectionResults.messagedate");
          break;
        case 'MaterialId':
          titleValue = this.$t("InspectionResults.codeid");
          break;
        case 'MaterialName':
          titleValue = this.$t("InspectionResults.name");
          break;
        case 'MaterialSpec':
          titleValue = this.$t("InspectionResults.specifications");
          break;
        case 'CarrySQty':
          titleValue = this.$t("InspectionResults.deliverynumber");
          break;
        case 'ReceivingQty':
          titleValue = this.$t("InspectionResults.receiptnumber");
          break;
        case 'GradeSQty':
          titleValue = this.$t("InspectionResults.passnumber");
          break;
        case 'BadnessSQty':
          titleValue = this.$t("InspectionResults.nonumber");
          break;
        case 'CheckResult':
          titleValue = this.$t("InspectionResults.result");
          break;
        case 'FromBillNo':
          titleValue = this.$t("InspectionResults.sourcenumber");
          break;
        default:
          titleValue = 'ARSearch默认栏位信息（测试）'
          break;
      }
      return this.renderTooltip(h, titleValue, columnRealWidth)
    }
  }
}
</script>

<style scoped>
.detialmessage {
  color: #5d5d5d;
  font-size: 14px;
}

.inspectionmessage {
  padding: 10px 20px;
}
.el-checkbox /deep/ .el-checkbox__label {
  color: #5d5d5d;
  font-size: 14px;
}

.el-table /deep/ .el-table__header .cell {
  overflow: hidden;
  white-space: nowrap;
}

.el-table /deep/ th.el-table__cell {
  background-color: #f9f9f9;
}

.el-table /deep/ .el-table__body-wrapper {
  font-size: 13px;
}

.el-table /deep/ thead {
  font-size: 14px;
  color: #5d5d5d;
}

.topbutton {
  color: #777777;
  font-size: 14px;
}
</style>
