<template>
  <div id="app">
    <router-view></router-view>
    <el-dialog class="errordialog" :visible="dialog.isShow" :before-close="handleClose" :title="dialog.title">
      <el-collapse v-model="activeNames">
        <el-collapse-item class="errorBox" name="1">
          <template slot="title">
            <div class="errormsg" v-html="dialog.message"></div>
          </template>
          <div class="dialoginfo" v-html="dialog.info"></div>
        </el-collapse-item>
      </el-collapse>
    </el-dialog>
    <TipPop></TipPop>
  </div>
</template>

<script>
import { mapState,mapMutations } from "vuex"
export default {
  name: 'App',
  computed:{
    ...mapState(["dialog"])
  },
  data(){
    return{
      activeNames:["0"]
    }
  },
  methods:{
    handleClose(){
      this.activeNames=["0"];
      if(this.dialog.timer){
        clearTimeout(this.dialog.timer);
        this.$router.push(
          {path:'/Login'}
        );
      }
      this.$store.commit("ERRORTIP",false)
    }
  },
  watch:{
    "$route.path":{
      handler:function(newval,oldval){
        sessionStorage.setItem("frompath",oldval);
      }
    }
  }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.errorBox .el-collapse-item__header{
  height: auto;
  max-height: 60vh;
  line-height:normal;
}

.errormsg{
  width: 95%;
  height: 100%;
  display: block;
  overflow: auto;
  white-space: nowrap;
  /* text-overflow: ellipsis; */
}
.dialoginfo{
  max-height: 60vh;
  overflow: auto;
}
body{
    margin: 0;
    padding: 0;
    font-family: "siyuanregular";
    background-color: #E9ECF3;
}
body,html{
  height: 100%;
}
@font-face {
    font-family: "siyuanmedium";
    src: url(./assets/fonts/siyuanCN-Medium.otf);
    font-family: "siyuanregular";
    src:url(./assets/fonts/siyuanCN-Regular.otf)
}
@media screen and (max-width: 1160px){
  body{
    width: 1160px;
  }
}

.el-table__body-wrapper::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}
.el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #a1a3a9;
  border-radius: 3px;
}
.el-table__body-wrapper{
  width: 100%;
}
.el-table::before{
  height: 0;
}
.el-table .el-table__cell {
  padding: 10px 0;
}
</style>
