<template>
    <Frame>
      <PageHeader :title="$t('comm.returnDetail')" :hasBack="true" detailType="ReturnQueryDetail" />
      <div class="returnDetail" style="padding:10px;">
        <div class="contain">
          <div class="message" style="width:100%;">
            <ul>
              <li class="messagetitle">{{ $t('comm.messagetitle') }}</li>
              <li style="width:9%;" class="button option-icon" id="Export" v-if="$store.state.exportExcelDetailState" @click="Export()"> <svg-icon icon-class="export" />{{ $t('button.export') }}</li>
              <li style="width:9%;" class="button option-icon" id="print" @click="printdialog()"><svg-icon icon-class="print" />{{ $t('button.print') }}</li>
            </ul>
            <ul style="padding:0 20px;display: flex;">
              <li class="message">{{ $t('ReturnDetail.billNo') }}:{{ billno }}</li>
              <li class="message">{{ $t('ReturnDetail.billDate') }}：{{ billdate }}</li>
              <li class="message">{{ $t('ReturnDetail.orgName') }}：{{ orgName }}</li>
              <li class="message">{{ $t('ReturnDetail.purchaser') }}：{{ purchaser }}</li>
            </ul>
            <ul style="padding:0 20px;display: flex;">
              <li class="message">{{ $t('ReturnDetail.amount') }}：{{ amount }}</li>   
              <li class="message">{{ $t('ReturnDetail.OAmountWithTax') }}：{{ oAmountWithTax }}</li>           
            </ul>
            <ul style="padding:0 20px;display: flex;">
              <li class="message">{{ $t('ReturnDetail.remark') }}：{{ remark }}</li>
            </ul>
          </div>
        </div>
        <ul>
          <li class="messagetitle">{{ $t('comm.returnDetail') }}</li>
        </ul>
        <el-table :data="tableData" :height="tableHeight" v-loading="tableLoading" style="margin:0;width:100%">
          <el-table-column width="50" prop="Number" :label="$t('ReturnDetail.number')"></el-table-column>
          <el-table-column prop="MaterialId" :label="$t('ReturnDetail.codeid')"></el-table-column>
          <el-table-column prop="MaterialName" :label="$t('ReturnDetail.name')"></el-table-column>
          <el-table-column prop="MaterialSpec" :label="$t('ReturnDetail.specifications')"></el-table-column>
          <el-table-column prop="ReceivingSQty" minWidth="120" align="right"
            :label="$t('ReturnDetail.receiptnumber')">
            <template slot-scope="scope">
              <span>{{ scope.row.ReceivingSQty}}&nbsp{{ scope.row.SUnitName }}</span>
            </template>
          </el-table-column>
          <el-table-column v-if="$store.state.ShowBasicUnit" prop="ReceivingQty" minWidth="120" align="right" :label="$t('ReturnDetail.receivingQty')">
            <template slot-scope="scope">
              <span>{{ scope.row.ReceivingQty}}&nbsp{{ scope.row.UnitName }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="ValueSQty" align="right" :label="$t('ReturnDetail.valueSQty')">
            <template slot-scope="scope">
              <span>{{ scope.row.ValueSQty}}&nbsp{{ scope.row.SUnitName }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="PresentSQty" align="right" :label="$t('ReturnDetail.presentSQty')">
            <template slot-scope="scope">
              <span v-if="scope.row.PresentSQty && scope.row.PresentSQty!=''">{{ scope.row.PresentSQty}}&nbsp{{ scope.row.SUnitName }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="SPrice" align="right" :label="$t('ReturnDetail.price')"></el-table-column>
          <el-table-column prop="strPrice" align="right" :label="$t('ReturnDetail.subtotal')"></el-table-column>
          <el-table-column prop="OAmountWithTax" :label="$t('ReturnDetail.OAmountWithTax')"></el-table-column>
          <el-table-column prop="BackReasonName" :label="$t('ReturnDetail.backReasonName')"></el-table-column>
          <el-table-column prop="Remark" :label="$t('ReturnDetail.remark')"></el-table-column>
        </el-table>
      </div>
      <el-dialog width="25%" :title="$t('OrderDetail.tip')" :visible.sync="printvisible" :append-to-body="true">
        <el-select style="width:100%" class="dialogselect" v-model="printType"
          :placeholder="typegather.length != 0 ? $t('OrderDetail.placeholder') : $t('OrderDetail.noneholder')">
          <el-option v-for="item in typegather" :key="item.value" :label="item.name" :value="item.value">
          </el-option>
        </el-select>
        <div slot="footer" class="dialog-footer">
          <el-button @click="print()" type="primary">{{ $t('button.determine') }}</el-button>
        </div>
      </el-dialog>
    </Frame>
  </template>
  
  <script>
  import SvgIcon from '../components/SvgIcon'
  import { Serialize } from '@/utils/utils';
  export default {
    name: 'ReturnDetail',
    components: { SvgIcon },
    data() {
      return {
        tableHeight: window.innerHeight - 390,
        billno: '',
        billdate: '',
        oAmountWithTax:'',
        amount: '',
        orgName:'',
        warestate: '',
        warestateclass: 'warestate',
        cancelstate: '',
        cancelstateclass: 'warestate',
        purchaser: '',
        remark: '',
        tableLoading: false,
        printvisible: false,
        printType: "",
        printtemp: '',
        typegather: [],
        pageindex: 0,
        tableData: [],
        BillType:'Return'
      }
    },
    created() {
      this.billno = this.$route.query.DelBillNo;
    },
    mounted() {
      let that = this;
      window.onresize = () => {
        if (that.resizeFlag) {
          clearTimeout(that.resizeFlag);
        }
        that.resizeFlag = setTimeout(() => {
          that.tableHeight = window.innerHeight - 390;
          that.resizeFlag = null;
        }, 100);
      }
      this.tableLoading = true;
      this.getReturnDetail();
    },
  
    methods: {
      getReturnDetail() {
        debugger
        this.axiosInvoke("/SupplierComm/Handler/OrderPurPurchaseHandler.ashx", {
          action: "getorderfahuodetail",
          billno: this.billno,
          isReturnOrder : true
        }, (res) => {
          var data = eval(res.data);
          this.billdate = data[0].BillDate;
          this.orgName = data[0].OrgName;
          this.amount = data[0].OAmount;
          this.oAmountWithTax = data[0].OAmountWithTax
          this.purchaser = data[0].PersonName;
          this.remark = data[0].Remark;
          // this.Tally = data[0].Tally;
          console.log(data);
          data.shift(0);
          this.tableData = data;
          this.tableLoading = false;
        })
      },
      Export() {//导出
        let action = 'exportexcel'
        let progId = 'purReceivingOrder'
        //取回当前查询表进行导出
        this.exportExcel(action, progId, this.tableData)
      },
      print() {//打印
        if(this.printType==""){
        this.$store.commit("TIPPOP",{popType:'warning',popMessage:this.$t("comm.selectprint")});
        return;
      }
        this.$cookies.set(this.BillType + "ReturnpurReceivingOrderprintType", this.printType,"365D");
        var theParam = {
          progId: "purReceivingOrder",
          lang: this.printtemp.lang,
          file: this.printType,
          date: '',
          billPrintKind: 0,
          conditions: this.printtemp.conditions
        }
        debugger
        var url = "/functions/report/ReportPreview.aspx?ispdf=1&params=" + encodeURI(Serialize(theParam));
        window.open(url, "_blank");
      },
      printdialog() {
        this.printvisible = true;
        this.axiosInvoke("/SupplierComm/Handler/VueSysHandler.ashx",
          {
            action: "doprintbill",
            BillNo: this.billno,
            ProgId: "purReceivingOrder",
            lang: localStorage.getItem("language") == "CHS"?"zh-CHS":"zh-CHT",
            BillType:this.BillType,
          },
          (res) => {
            var data = eval(res.data);
            this.printtemp = data[0];
            this.typegather = data[0].files;
            this.printType = decodeURIComponent(data[0].printType);
            var printExist = false;
            this.typegather.forEach(v=>{
              if(v.value == this.printType){
                printExist = true;
                return;
              }
            })
            if(!printExist)this.printType = "";
            })
      },
    }
  }
  </script>
  
  <style scoped>
  ul {
    padding: 0;
  }
  
  .message {
    color: #777777;
    font-size: 14px;
    width: 24%;
    padding-right: 1%;
  }
  
  .message li {
    display: inline-block;
    text-align: left;
  }
  
  .option-icon{
    filter: grayscale(100%);
    padding-right:5%;
  }
  .option-icon:hover{
    filter: grayscale(0%);
  }
  .messagetitle {
    color: #5d5d5d;
    font-size: 16px;
    width: 80%;
    padding: 0 20px;
  }
  
  .button {
    color: #5d5d5d;
    font-size: 15px;
    cursor: pointer;
  }
  
  .button:hover {
    cursor: pointer;
    color: #0177d5;
  }
  
  .warestate {
    color: #777777;
  }
  
  .warestatered {
    color: red;
  }
  
  .el-table /deep/ th.el-table__cell {
    background-color: #f9f9f9;
  }
  
  .el-table /deep/ .el-table__body-wrapper {
    font-size: 13px;
  }
  
  .el-table /deep/ thead {
    font-size: 14px;
    color: #777777;
  }
  
  .el-table /deep/ .el-table_1_column_1 .cell:first-child {
    padding: 0 0 0 30px;
  }
  
  .el-table /deep/ .cell:first-child {
    font-weight: normal;
  }
  </style>