<template>
    <el-dialog :visible.sync="tippop.visible" width="25%" :before-close="handleClose" :show-close="false"
    top="38vh" custom-class="tippop">
        <span style="font-size: 18px;margin-bottom: 30px;"><i v-if="tippop.popType=='success'" style="color:rgb(26, 169, 123);" class="el-icon-success"></i>
            <i v-if="tippop.popType=='warning'" style="color:#E6A23C" class="el-icon-warning"></i>
            <i v-if="tippop.popType=='error'" style="color:rgb(229, 71, 55)" class="el-icon-error"></i>{{ tippop.popMessage }}</span>
    </el-dialog>
</template>
<script>
//  提示信息弹窗 调用方法
//  传入参数 String popType 提示类型（success，warning, error）; String popMessage 提示内容。
//  调用语法 this.$store.commit("TIPPOP",{popType:'success',popMessage:"提示信息"});
import { mapState,mapMutations } from "vuex"
export default {
    computed:{
    ...mapState(["tippop"])
    },
    data(){
        return{
        }
    },
    methods:{
        handleClose(){
            var tippop = {close:true};
            this.$store.commit("TIPPOP",tippop);
        }
    },
    watch:{
        "$store.state.tippop":{
            handler:function(){
                if(this.$store.state.tippop.visible){
                    setTimeout(()=>{
                        var tippop = {close:true};
                        this.$store.commit("TIPPOP",tippop);
                    },3000)
                }
               
            }
        }
    }
}
</script>
<style>
.tippop .el-dialog__header{
    padding: 0;
}
.tippop i{
    font-size: 20px;
    padding-right: 2%;
}
</style>
