<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <span id="titleText">{{ cardinform.titleText }}</span>
        <el-button @click="childmethod()" v-if="morebtn" style="float: right; padding-top: 0" type="text">{{
    $t('comm.viewmore')
}}</el-button>
      </div>
      <div :class="cardclass" v-if="empty">
        <svg-icon :icon-class="cardinform.svgicon"></svg-icon>
        <p style="font-size:14px;color:#777777;">{{ cardinform.tips }}</p>
      </div>
      <div v-if="!empty" v-for="fd in formData.slice(0, infolistlength)" @click="jumptopage(fd)" class="card-inform">
        <div style="width:5%;display: flex;padding-top: 0.6%;min-width: 6px;">
          <!-- <div v-if="fd.isnew" class="newTipDot"></div> -->
        </div>
        <div class="inform-row" :style="padding">
          <XElTooltip :content="fd.text" :overflow="true" :open-delay="500" placement="bottom">
            <span class="inform-text">{{ fd.text }}</span>
          </XElTooltip>
          <span class="inform-date">{{ fd.date }}</span>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import XElTooltip from './X-el-tooltip.vue';
export default {
  props: {
    type: String,
    formData: Array,
    morebtn: Boolean,
    viewmore: Function
  },
  components:{XElTooltip},
  data() {
    return {
      cardclass: "card-empty",
      empty: true,
      infolistlength: 4,
      padding: "",
      cardinform: {
        titleText: "",
        tips: "",
        svgicon: ""
      }
    }
  },
  watch: {
    formData: function (newdata, olddata) {
      if (newdata.length == 0) this.empty = true;
      else this.empty = false;
    }
  },
  methods: {
    childmethod() {
      this.viewmore();
    },
    jumptopage(fd) {
      debugger
      switch (this.type) {
        case "Bulletin":
          this.$router.push({
            path: "/Home/BulletinCenterDetail",
            query: {
              Title: fd.text.substring(fd.text.indexOf("]") + 1),
              BillNo: fd.billno,
            }
          })
          this.axiosInvoke('/Functions/PublishNotice.ashx',{
            action:'readnotice',
            billNo:fd.billno
          },(res)=>{
            console.log(res.data);
          })
          break;
        case "Download":
          this.$router.push({
            path: "/Home/SortFile",
            query: {
              Title: fd.text,
            }
          })
          break;
        case "Message":
            this.axiosInvoke("/SupplierComm/Handler/VueSysHandler.ashx",{
              action: "getorderdetail",
              billno: fd.billno,
            }, (res) => {
            console.log(res.data);
            var data = eval(res.data);
            if(data.length==0){
              this.$store.commit("TIPPOP",{popType:'error',popMessage:this.$t("OrderDetail.orderauthority").replace("{0}",fd.billno)});
            }else{
              this.$router.push({
              path: "/Home/OrderDetail",
              query: {
                DelBillNo: fd.billno,
              }
            })
            }})
            break;

      }
    },
    checktype() {
      if (this.type == "Download") {
        this.cardinform.titleText = this.$t('Default.newestdownload');
        this.cardinform.tips = this.$t('Default.nonewestdownload');
        this.cardinform.svgicon = "newlydownload";
      } else if (this.type == "Bulletin") {
        this.cardinform.titleText = this.$t('Default.bulletin');
        this.cardinform.tips = this.$t('Default.nobulletin');
        this.cardinform.svgicon = "bulletin";
      } else if (this.type == "Message") {
        this.cardinform.titleText = this.$t('Default.information');
        this.cardinform.tips = this.$t('Default.noinformation');
        this.cardinform.svgicon = "inform";
        this.padding = "padding:0.9vh 0";
        this.infolistlength = 3;
        this.cardclass = "card-message-empty";
      }
    },
  },
  mounted() {
    this.checktype();
    if (this.formData && this.formData.length > 0) {
      this.empty = false;
    }
  }

}
</script>

<style scoped>
::v-deep .el-card {
  height: 100%;
  border: none;
  background-color: rgba(0, 0, 0, 0);
  box-shadow: none;
}

.el-card /deep/ .el-card__header {
  height: 6vh;
  position: relative;
  padding: 1vh 5% 0 5%;
  border: none;
}

.el-card /deep/ .el-card__body {
  display: block;
  flex-wrap: wrap;
  padding:0 5px;
  height: 75%;
  justify-content: space-around;
}

.card-header {
  background-color: white;
  width: 90%;
  position: absolute;
  height: 6vh;
  display: flex;
  justify-content: space-between;
}

.card-header span {
  color: #5d5d5d;
  text-align: left;
  font-size: 1.15rem;
}

.card-empty {
  text-align: center;
  margin: 60px 0;
}

.card-message-empty {
  text-align: center;
}

.svg-icon {
  width: 3rem;
  height: 3rem;
  filter: drop-shadow(rgb(0, 0, 0) 0 0);
}

.card-inform {
  display: flex;
  flex-wrap: nowrap;
  width: 95%;
  padding: 0 5% 0 0;
  cursor: pointer;
}

.card-inform:hover .inform-row {
  color: #3466cc;
}

.newTipDot {
  height: 6px;
  width: 6px;
  margin: auto;
  background-color: red;
  border-radius: 50%;
}

.inform-row {
  color: #5d5d5d;
  width: 95%;
  display: flex;
  flex-wrap: nowrap;
  border-top: solid 1px #e7e7e7;
  padding: 2.1vh 0;
}

.inform-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 16px;
  width: 75%;
  text-align: left;
}


.inform-date {
  text-align: right;
  font-size: 15px;
  width: 25%;
}
</style>