<template>
  <Frame>
    <PageHeader :title="$t('comm.orderdetailsearch')" :hasBack="false" />
    <div class="order" style="padding:10px 0;">
      <div class="ordermessage">
        <span class="detialmessage">{{ $t('OrderDetailSearch.txtBillNo') }}：<el-input :placeholder="$t('Order.inputpls')"
            v-model="billno" clearable style="width:15%;color:#bbbbbb;" size="small"
            @change="inputchange(billno);"></el-input></span>
        <!-- <span class="detialmessage" style="padding-left:100px">{{$t('Order.messagedate')}}：<el-input :placeholder="$t('Order.inputchoose')" suffix-icon="el-icon-date" v-model="date" clearable style="width:30%;color:#bbbbbb;"></el-input></span> -->
        <span class="detialmessage">{{ $t('OrderDetailSearch.txtBillDate') }}：<el-date-picker size="small"
            v-model="billdate" type="daterange" style="width:15%;color:#bbbbbb;" range-separator="~" @change="getTime()" :default-value="timeDefault"
            :start-placeholder="$t('comm.startdate')" :unlink-panels="true" :end-placeholder="$t('comm.enddate')"></el-date-picker></span>
        <span class="detialmessage">{{ $t('OrderDetailSearch.txtCompName') }}：<el-input :placeholder="$t('Order.inputpls')"
            v-model="compName" clearable style="width:15%;color:#bbbbbb;" size="small"
            @change="inputchange(compName);"></el-input></span>
        <span class="detialmessage">{{ $t('OrderDetailSearch.txtMaterialName') }}：<el-input :placeholder="$t('Order.inputpls')"
          v-model="materialName" clearable style="width:15%;color:#bbbbbb;" size="small"
          @change="inputchange(materialName);"></el-input></span>
      </div>
      <div class="ordermessage">
        <span class="detialmessage" style="padding-right: 0;">{{ $t('Order.goodsstate') }}：</span>
        <span class="detialmessage" style="width: 20%;">
          <el-checkbox @change="getorderdata(0);" v-model="unshippe" :label="$t('Order.unshipped')"></el-checkbox>
          <el-checkbox @change="getorderdata(0);" v-model="partialDel" :label="$t('Order.partialDel')"></el-checkbox>
          <el-checkbox @change="getorderdata(0);" v-model="allshipments"
            :label="$t('Order.allshipments')"></el-checkbox>
        </span>
        <span v-if="$store.state.ShowMTO" class="detialmessage">{{ $t('OrderDetailSearch.txtMTONo') }}：<el-input :placeholder="$t('Order.inputpls')"
          v-model="mtoNo" clearable style="width:15%;color:#bbbbbb;" size="small"
          @change="inputchange(mtoNo);"></el-input></span>
          <span class="detialmessage" style="padding-left: 20px;">{{ $t('OrderDetailSearch.txtBatchNo') }}：<el-input :placeholder="$t('Order.inputpls')"
              v-model="batchNo" clearable style="width:15%;color:#bbbbbb;" size="small"
              @change="inputchange(batchNo);"></el-input>
          </span>
          <!-- ADV202307170005 供应商平台-是否含税栏位显示控件优化 -->
          <span class="detialmessage">
            {{ $t('comm.withtaxState') }}:&nbsp;&nbsp;<el-checkbox v-model="iswithtax" @change="changeTaxState()" class="checkBox"></el-checkbox>
          </span>
      </div>
      <div class="ordermessage">
        <!-- // ADV202309060006  供应商平台-订单详情查询增加行项状态，同时控制如果行项状态为终止、转单冻结不允许勾选转发货 -->
        <span class="detialmessage" style="padding-right: 0;">{{ $t('OrderDetailSearch.txtRowState') }}：</span>
        <span class="detialmessage" style="width: 20%;">
          <el-checkbox @change="getorderdata(0);" v-model="rowState1" :label="$t('OrderDetailSearch.txtRowState1')"></el-checkbox>
          <el-checkbox @change="getorderdata(0);" v-model="rowState2" :label="$t('OrderDetailSearch.txtRowState2')"></el-checkbox>
          <el-checkbox @change="getorderdata(0);" v-model="rowState3" :label="$t('OrderDetailSearch.txtRowState3')"></el-checkbox>
        </span>
        <span style="float:right;padding:10px 20px 10px 0;margin-top: -16.5px;">
          <el-button type="reset" @click="reset()">{{ $t('button.reset') }}</el-button>
          <el-button type="primary" @click="query()">{{ $t('button.query') }}</el-button>
        </span>
      </div>
      <el-table ref="multipleTable" :data="tableData" :height="tableHeight" style="margin:0;width:100%"
        v-loading="tableLoading" tooltip-effect="dark" @selection-change="handleSelectionChange" :header-cell-style="moneyclass">
        <el-table-column type="selection" :selectable="checkboxT"></el-table-column>
        <el-table-column prop="BillNo" :label="$t('OrderDetailSearch.txtBillNo')" style="padding-right:50px"></el-table-column>
        <el-table-column prop="BillDate" :label="$t('OrderDetailSearch.txtBillDate')" style="padding-right:50px"></el-table-column>
        <el-table-column prop="CompName" :label="$t('OrderDetailSearch.txtCompName')" style="padding-right:50px"></el-table-column>
        <el-table-column prop="MaterialId" :label="$t('OrderDetailSearch.txtMaterialId')" style="padding-right:50px"></el-table-column>
        <el-table-column prop="MaterialName" :label="$t('OrderDetailSearch.txtMaterialName')" style="padding-right:50px"></el-table-column>
        <el-table-column prop="MaterialSpec" :label="$t('OrderDetailSearch.txtMaterialSpec')" style="padding-right:50px"></el-table-column>
        <el-table-column prop="SQuantity" align="right" :label="$t('OrderDetailSearch.txtSQuantity')" style="padding-right:50px">
          <template slot-scope="scope">
            <span>{{ scope.row.SQuantity }}&nbsp{{ scope.row.SUnitName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="Quantity" align="right" :label="$t('OrderDetailSearch.txtQuantity')" style="padding-right:50px" v-if="$store.state.ShowBasicUnit">
          <template slot-scope="scope">
            <span>{{ scope.row.Quantity }}&nbsp{{ scope.row.UnitName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="TotalSQty" align="right" :label="$t('OrderDetailSearch.txtTotalSQty')" style="padding-right:50px">
          <template slot-scope="scope">
            <span>{{ scope.row.TotalSQty }}&nbsp{{ scope.row.SUnitName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="UnTransSQty" align="right" :label="$t('OrderDetailSearch.txtUnTransSQty')" style="padding-right:50px">
          <template slot-scope="scope">
            <span>{{ scope.row.UnTransSQty }}&nbsp{{ scope.row.SUnitName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="SPrice" align="right" :label="$t('OrderDetailSearch.txtSPrice')" style="padding-right:50px"></el-table-column>
        <el-table-column prop="OAmountWithTax" align="right" :label="$t('OrderDetailSearch.txtOAmountWithTax')" style="padding-right:50px"></el-table-column>
        <el-table-column prop="DeliveryDate" :label="$t('OrderDetailSearch.txtDeliveryDate')" style="padding-right:50px"></el-table-column>
        <el-table-column v-if="$store.state.ShowMTO" prop="MTONo" :label="$t('OrderDetailSearch.txtMTONo')" style="padding-right:50px"></el-table-column>
        <el-table-column prop="BatchNo" :label="$t('OrderDetailSearch.txtBatchNo')" style="padding-right:50px"></el-table-column>
        <el-table-column prop="UnTransSQty" :label="$t('OrderDetailSearch.txtBillState')">
          <template slot-scope="scope">
            <span :style="scope.row.DeliverState == $t('Order.unshipped') ? 'display:block' : 'display:none'">
              <img src="../assets/img/border/unshipped.png" style="padding:0 10px 4px 0">
              {{ $t('Order.unshipped') }}</span>
            <span :style="scope.row.DeliverState == $t('Order.partialDel') ? 'display:block' : 'display:none'">
              <img src="../assets/img/border/partialdel.png" style="padding:0 10px 4px 0">
              {{ $t('Order.partialDel') }}</span>
            <span :style="scope.row.DeliverState == $t('Order.shipped') ? 'display:block' : 'display:none'">
              <img src="../assets/img/border/shipped.png" style="padding:0 10px 4px 0">
              {{ $t('Order.shipped') }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="RowState" :label="$t('OrderDetailSearch.txtRowState')" style="padding-right:50px"></el-table-column> 
        <el-table-column prop="Remark" :label="$t('OrderDetailSearch.txtRemark')" style="padding-right:50px"></el-table-column>        
      </el-table>
      <div style="width:100%;display:flex;">
        <div style="width:45%;margin:15px 0 0 15px;">
          <el-checkbox @change="checkall()" v-model="checked"></el-checkbox>
          <span style="padding-left:15px;">
            <el-button @click="batchshipment()">
              <img src="../assets/img/button/batchshipment.png" class="img">
              {{ $t('button.batchshipment') }}
            </el-button>
            <el-button @click="BatchExport()">
              <img src="../assets/img/button/batchexport.png" class="img">
              {{ $t('button.batchexport') }}
            </el-button>
          </span>
        </div>
        <el-pagination ref="pagination" style="width:50%;margin:20px 0 0 0;" @current-change="handleCurrentChange"
          layout="total,prev,pager,next,jumper" :pager-count="5" :current-page.sync="currentPage" :total="totalCount">
        </el-pagination>
      </div>

    </div>
    <el-dialog :visible.sync="batchconfirmation" width="20%" top="400px" :show-close="false" class="batchconfirmation">
      <span><i class="el-icon-success" style="font-size: 16px; color:rgb(26, 169, 123)"></i>   {{ $t('button.confirmationText') }}</span>
    </el-dialog>
    <el-dialog :title="$t('button.exporttitle')" :visible.sync="batchexport" width="40%" top="400px">
      <el-radio v-model="radio" label="exportTextA" @change="changeRadio($event)">{{ $t('button.exportTextA')}}</el-radio>
      <el-radio v-model="radio" label="exportTextB" @change="changeRadio($event)">{{ $t('button.exportTextB')}}</el-radio>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel()">{{ $t('button.cancel') }}</el-button>
        <el-button type="primary" @click="determine()">{{ $t('button.determine') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog width="25%" :title="$t('OrderDetail.tip')" :visible.sync="printvisible" :append-to-body="true">
        <el-select style="width:100%" class="dialogselect" v-model="printType"
            :placeholder="typegather.length != 0 ? $t('OrderDetail.placeholder') : $t('OrderDetail.noneholder')">
            <el-option v-for="item in typegather" :key="item.value" :label="item.name" :value="item.value">
            </el-option>
        </el-select>
        <div slot="footer" class="dialog-footer">
            <el-button @click="print()" type="primary">{{ $t('button.determine') }}</el-button>
        </div>
    </el-dialog>
  </Frame>
</template>

<script>
import SvgIcon from '../components/SvgIcon'
import { handleShortDate } from '../utils/handleDate.js'
import { Serialize, radioJudgeExcel } from '../utils/utils.js'
import { BillParams, PrintView } from '../utils/ReportPreView.js'
export default {
  name: 'OrderDetailSearch',
  components: { SvgIcon },
  data() {
    return {
      tableLoading: false,
      batchexport: false,
      batchconfirmation: false,
      tableHeight: window.innerHeight - 370,
      billno: '',
      materialName: '',
      compName:'',
      batchNo: '',
      mtoNo: '',
      billdate: '',
      timeDefault:'',
      radio: 'exportTextA',
      checked: false,
      startdate: '',
      enddate: '',
      unshipped: false,
      unconfirmedchanges: true,
      confirm: false,
      unsignedconfirmation: true,
      unshippe: true,
      partialDel: true,
      allshipments: false,
      rowState1: true,
      rowState2: false,
      rowState3: false,
      currentPage: 1,
      totalCount: 0,
      pagesize: 10,
      tableData: [],
      selectData: [],
      pageindex: 0,
      confirmtext:'',
      printvisible: false, //使用打印功能
      dialogvisible: false,
      dialogloading: true,
      dialogtitle: '',
      printType: '',
      printtemp: '',
      typegather: [],
      progId: 'purPurchaseOrder',
      billOrgId: '',
      billTypeId: '',
      iswithtax:true,
    }
  },
  created() {
    if (this.$route.query.partialDel && this.$route.query.unshippe) {
      this.partialDel = true;
      this.unshippe = true;
      this.unsignedconfirmation = false;
      this.unconfirmedchanges = false;
    }
    if (this.$route.query.unsignedconfirmation&&this.$route.query.unconfirmedchanges) {
      this.unsignedconfirmation = true;
      this.unconfirmedchanges = true;
      this.partialDel = false;
      this.unshippe = false;
    }
    if (this.$route.query.date && this.$route.query.startdate && this.$route.query.enddate) {
      this.billdate = JSON.parse(this.$route.query.date);
      this.startdate = this.$route.query.startdate;
      this.enddate = this.$route.query.enddate;
    }
    if(this.$route.query.BillNo){
      this.billno = this.$route.query.BillNo;
    }
    if (sessionStorage.getItem("OrderDetailSearch") && sessionStorage.getItem("OrderDetailSearch") != "null") {
      var history = JSON.parse(sessionStorage.getItem("OrderDetailSearch"));
      this.billno = history.billno;
      this.materialName = history.materialName;
      this.compName = history.compName;
      this.batchNo = history.batchNo;
      this.mtoNo = history.mtoNo;
      this.billdate = history.billdate;
      this.startdate = history.startdate;
      this.enddate = history.enddate;
      this.unshipped = history.unshipped;
      this.unconfirmedchanges = history.unconfirmedchanges;
      this.confirm = history.confirm;
      this.unsignedconfirmation = history.unsignedconfirmation;
      this.unshippe = history.unshippe;
      this.partialDel = history.partialDel;
      this.allshipments = history.allshipments;
      this.pageindex = history.pageindex;
      this.getorderdata(history.pageindex);
      sessionStorage.removeItem("OrderDetailSearch");
    }
    else this.getorderdata(0);
  },
  mounted() {
    let that = this;
    window.onresize = () => {
      if (that.resizeFlag) {
        clearTimeout(that.resizeFlag);
      }
      that.resizeFlag = setTimeout(() => {
        that.tableHeight = window.innerHeight - 370;
        that.resizeFlag = null;
      }, 100);
    }
    this.timeDefault =new Date();
    this.timeDefault.setMonth(new Date().getMonth()-1);

  },
  methods: {
    inputchange(val) {
      this.getorderdata(0);
    },
    getorderdata(pageindex) {
      this.currentPage = pageindex + 1;
      this.tableLoading = true;
      this.axiosInvoke("/SupplierComm/Handler/OrderPurPurchaseHandler.ashx", {
        action: "getorderdetail",
        //BUG202306060049 新版供应商，订单详情查询只过滤掉未生效和作废的数据
        // isDetial:true, // 详情查询目前不需要共用列表查询的条件，单独分离出来，故无需再传该字段
        pageindex: pageindex,
        pagesize: this.pagesize,
        billno: this.billno,
        materialName: this.materialName,
        compName: this.compName,
        batchNo: this.batchNo,
        mtoNo: this.mtoNo,
        startdate: this.startdate,
        enddate: this.enddate,
        unshipped: this.unshipped,
        unconfirmedchanges: this.unconfirmedchanges,
        confirm: this.confirm,
        unsignedconfirmation: this.unsignedconfirmation,
        unshippe: this.unshippe, //未发货
        partialDel: this.partialDel, // 部分发货
        allshipments: this.allshipments, // 全部发货
        // ADV202309060006  供应商平台-订单详情查询增加行项状态，同时控制如果行项状态为终止、转单冻结不允许勾选转发货
        rowState1:this.rowState1, // 正常
        rowState2:this.rowState2, // 终止
        rowState3:this.rowState3, // 转单冻结
        excel: '',
        iswithtax:this.iswithtax,
      },
        (res) => {
          var data = eval(res.data);
          this.totalCount = data[0].totalCount;
          data.shift(0);
          let patten=/(\d{4})(\d{2})(\d{2})/
          data.forEach((v)=>{
            v.BillDate = String(v.BillDate).replace(patten, "$1-$2-$3")
            let UnTransSQty = Number(v.UnTransSQty.replace(/,/g,""));
            let TotalSQty = Number(v.TotalSQty.replace(/,/g,""));
            if(UnTransSQty == TotalSQty && TotalSQty > 0){
              v.DeliverState = this.$t('Order.unshipped');
            }else if(UnTransSQty < TotalSQty && UnTransSQty > 0){
              v.DeliverState = this.$t('Order.partialDel');
            }else if(UnTransSQty <= 0){
              v.DeliverState = this.$t('Order.shipped');
            }
          })
          this.tableData = data;
          this.tableLoading = false;
        })
    },
    moneyclass({row,column,rowIndex,columnIndex}){
      // if(rowIndex==0&&columnIndex==3){
      //   return "padding-right:40px;"
      // }
    },
    reset() {//重置
      this.billno = "";
      this.materialName = "";
      this.compName = "";
      this.batchNo = "",
      this.mtoNo = "",
      this.billdate = "";
      this.startdate = "";
      this.enddate = "";
      this.unshipped = false;
      this.unconfirmedchanges = true;
      this.confirm = false;
      this.unsignedconfirmation = true;
      this.unshippe = true;
      this.partialDel = true;
      this.allshipments = false;
      this.rowState1= true;
      this.rowState2= false;
      this.rowState3= false;
      this.getorderdata(0);
    },
    query() {//查询
      console.log(this.billdate);
      debugger
      this.getorderdata(0);
      this.currentPage = 1;
    },
    batchshipment() {//批量发货
      var billnos = [];
      debugger
      if (this.selectData.length > 0) {
        var errbillnos = ""
        var errbillnoArray = [];
        this.selectData.forEach((v) => {
          //BUG202305300003  新版供应商，订单详情查询，当选中3笔数据进行批量发货，转到发货页签是勾选整个订单的数据，如下图
          if(v.CurrentState != "2"){
            var dpflag = false;//判断单号是否重复，重复就跳过记录
            debugger
            errbillnoArray.forEach(err => {
              if(err == v.BillNo){
                dpflag = true;
                return;
              }
            });
            if(!dpflag){
              errbillnoArray.push(v.BillNo);
              errbillnos += v.BillNo + ","
            }
          }
          billnos.push({BillNo:v.BillNo,RowCode:v.RowCode}); // 通过_标识，前面是单号，后面是对应行项
        })
        if(errbillnos!=""){
          errbillnos = errbillnos.substring(0,errbillnos.lastIndexOf(","));
          this.$store.commit("TIPPOP",{popType:'warning',popMessage:this.$t("OrderDetailSearch.errcurrentstate").replace("{0}",errbillnos)});
          return;
        }
        sessionStorage.setItem("OrderToDeliverShipment", JSON.stringify(billnos));
        this.$router.push({
          path: "/Home/Deliver",
          query: {
            iswithtax:this.iswithtax
          }
        })
      } else {
        this.$store.commit("TIPPOP",{popType:'warning',popMessage:this.$t("Deliver.warning")});
      }
    },
    cancel() {//取消
      this.batchexport = false;
    },
    changeRadio(val) {
      this.radio = val;
    },
    determine() {//确定
      this.batchexport = false;
      //导出Excel
      //取回当前查询表进行导出
      debugger
      let params = {
        pageindex: 0,
        pagesize: 1000,
        billno: this.billno,
        materialName : this.materialName,
        compName : this.compName,
        batchNo: this.batchNo,
        mtoNo: this.mtoNo,
        startdate: this.startdate,
        enddate: this.enddate,
        unshipped: this.unshipped,
        unconfirmedchanges: this.unconfirmedchanges,
        confirm: this.confirm,
        unsignedconfirmation: this.unsignedconfirmation,
        unshippe: this.unshippe,
        partialDel: this.partialDel,
        allshipments: this.allshipments,
        iswithtax: this.iswithtax,
        isDetial: true, //是否查询明细
        excel: true,
        isRpt: true, //是否报表，明细列表查询也调用此属性执行导出
        isBill: false, //是否单据
        rowState1:this.rowState1, // 正常
        rowState2:this.rowState2, // 终止
        rowState3:this.rowState3, // 转单冻结
        exportFields:"BillNo,BillDate,compName,MaterialId,MaterialName,MaterialSpec,SQuantity,Quantity,TotalSQty,UnTransSQty,SPrice,OAmountWithTax,DeliveryDate,MTONo,BatchNo,DeliverState,RowState,Remark",
        exportFieldArr:JSON.stringify(["BillNo","BillDate","compName","MaterialId","MaterialName","MaterialSpec","SQuantity","Quantity","TotalSQty","UnTransSQty","SPrice","OAmountWithTax","DeliveryDate","MTONo",
        "BatchNo","DeliverState","RowState","Remark"]),
        unshippedtxt:this.$t('Order.unshipped'),
        partialDeltxt:this.$t('Order.partialDel'),
        shippedtxt:this.$t('Order.shipped')
      }
      radioJudgeExcel("getorderdetail", params, this.radio, "purPurchaseOrder", this.selectData, this);
    },
    checkall() {
      if (this.checked) this.$refs.multipleTable.toggleAllSelection();
      else this.$refs.multipleTable.clearSelection();
    },
    changeTaxState(){
      this.getorderdata(0);
    },
    // 选中状态控制只读
    checkboxT(row,rowIndex){
      let checkState = true;
      switch (row.RowState) {
        case this.$t('OrderDetailSearch.txtRowState2'):
        case this.$t('OrderDetailSearch.txtRowState3'):
          checkState = false;
          break;      
        default:
          break;
      }
      return checkState;
    },
    //多选处理
    handleSelectionChange(val) {
      let allowCheckRowCount = 0;
      if(this.tableData.length > 0){        
        for(let k=0; k<this.tableData.length;k++){
          let tempRow = this.tableData[k];
          if(tempRow && tempRow.RowState == this.$t('OrderDetailSearch.txtRowState1')) allowCheckRowCount++;
        }
      }
      this.selectData = val;
      // 部分选中状态先注释，待燕珊确认规格后再作处理——目前只需全选、未选
      if (val.length > 0 && val.length == allowCheckRowCount) { // 全选
        this.checked = true;
      } 
      else { // 未选
        this.checked = false;
      }
    },
    //获取时间
    getTime() {
      if(this.billdate){
        this.startdate = handleShortDate(this.billdate[0], "From");
        this.enddate = handleShortDate(this.billdate[1], "To");
      }
      else{
        this.startdate = "";
        this.enddate = "";
      }
      this.getorderdata(0);
    },
    //翻页处理
    handleCurrentChange(val) {
      this.pageindex = val - 1;
      this.getorderdata(val - 1);
    },
    BatchExport(){
        this.batchexport = true;
    }
  }
}
</script>

<style scoped>
.ordermessage {
  padding: 8px 20px;
}
.detialmessage {
  color: #5d5d5d;
  font-size: 14px;
  width: 20%;
  padding-left: 10px;
  padding-right: 10px;
}

.moneyornumber {
  padding-right: 40px;
}
.checkBox{
    margin: auto 0;
}

.el-checkbox /deep/ .el-checkbox__label {
  color: #5d5d5d;
  font-size: 14px;
}

.stateborder {
  padding: 2px 8px;
  border-radius: 15px;
  border: 1px solid;
}

.el-table /deep/ th.el-table__cell {
  background-color: #f9f9f9;
}
@media screen and (max-width: 1450px) {
  .ordermessage .el-checkbox{
    margin-right: 10px;
  }
  .detialmessage{
    padding:0 5px 0;
  }
}
.el-table /deep/ .el-table__body-wrapper {
  font-size: 13px;
}

.el-table /deep/ .cell {
  overflow: initial;
}

.el-table /deep/ thead {
  font-size: 14px;
  color: #5d5d5d;
}

.el-table /deep/ .el-table__header .cell .el-checkbox:first-child {
  padding-left: 5px;
}

.topbutton {
  color: #0177d5;
  font-size: 14px;
}

.topbutton:hover {
  color: #5aacff;
}

.img {
  padding-right: 8px;
  height: 12px;
  width: 12px;
}

.el-dialog__wrapper /deep/ .el-dialog .el-dialog__header {
  border-bottom: 1px solid rgb(235, 233, 233);
}

.el-dialog__wrapper /deep/ .el-dialog .el-dialog__body {
  border-bottom: 1px solid rgb(235, 233, 233);
}

.batchconfirmation /deep/ .el-dialog .el-dialog__header {
  display: none;
}

.batchconfirmation /deep/ .el-dialog .el-dialog__body {
  text-align: center;
}
</style>
