<template>
    <Frame>
        <PageHeader :title="title" :hasBack="hasBack?!showtable:false" :OpreationMenu="changeshowtable" />
        <div v-if="showtable">
            <div class="search-row">
                <span class="detialmessage">{{ $t('Order.messagedate') }}：<el-date-picker v-model="billdate"
                        type="daterange" style="color:#bbbbbb;" range-separator="~" @change="getTime()"
                        :start-placeholder="$t('comm.startdate')" :unlink-panels="true" :default-value="timeDefault"
                        :end-placeholder="$t('comm.enddate')" size="small"></el-date-picker>
                        <el-button v-if="isAdd" @click="BillAddNew" style="margin-left:50px;">{{ $t('button.addnew') }}</el-button>
                    </span>
                       
                <el-button @click="resetInput" plain>{{ $t('button.reset') }}</el-button>
                <el-button @click="search(0)" type="primary">{{ $t('button.search') }}</el-button>
            </div>
            <div class="download-form">
                <el-table :data="tableData" style="width: 100%" :height="tableHeight" v-loading="tableLoading" ref="tablebody">
                    <el-table-column prop="BillNo" :label="$t('HelpCenter.billNo')">
                        <template slot-scope="scope">
                            <el-link :underline="false" index="/Home/HelpCenter/HelpCenterDetail"
                                @click="viewDetail(scope.$index, scope.row)">
                                {{ scope.row.BillNo}}</el-link>
                        </template>
                    </el-table-column>
                    <el-table-column v-for="v in columnsData" :prop="v.ColumnName" :label="v.DisplayName"></el-table-column>
                    <el-table-column prop="option" :label="$t('HelpCenter.operation')" width="100">
                        <template slot-scope="scope">
                            <el-link :underline="false" index="/Home/HelpCenter/HelpCenterDetail"
                                @click="viewDetail(scope.$index, scope.row)" type="primary">
                                {{ $t('button.check')}}</el-link>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="pagination">
                <el-pagination style="padding-left:50%;width:50%;" @current-change="handleCurrentChange"
                    layout="total,prev,pager,next,jumper" :pager-count="5" :current-page="currentPage"
                    :total="totalCount">
                </el-pagination>
            </div>
        </div>
        <div style="overflow:hidden">
            <iframe class="formframe" :style="'margin-top:1px;height:'+(tableHeight+177)+'px;'" v-show="!showtable" ref="formframe"></iframe>
        </div>
    </Frame>
</template>

<script>
import { handleShortDate } from '@/utils/handleDate';
export default {
    name: "OperationMenu",
    data() {
        return {
            ProgId: '',
            OrgId:'',
            TypeId:'',
            tableData: [],
            columnsData:[],
            value: '',
            billdate: '',
            startdate: '',
            enddate: '',
            tableLoading: false,
            totalCount: 0,
            currentPage: 1,
            pageindex: 0,
            pagesize: 10,
            tableHeight: window.innerHeight - 327,
            title:'',
            fnrowcode:'',
            FormLayoutName:'',
            timeDefault:'',
            showtable:true,
            hasBack:true,
            isAdd:false,
        }
    },
    created() {
        this.timeDefault =new Date();
        this.timeDefault.setMonth(new Date().getMonth()-1);
        let path = this.$route.fullPath;
        this.getProgId(path);
        this.search(0);
    },
    mounted() {
        let that = this
        window.onresize = () => {
            if (that.resizeFlag) {
                clearTimeout(that.resizeFlag);
            }
            that.resizeFlag = setTimeout(() => {
                that.tableHeight = window.innerHeight - 327
                that.resizeFlag = null;
            }, 100);
        }
      
    },
    methods: {
        //从path中裁取progid
        getProgId(path) {
            debugger
            this.ProgId = path.substring(path.lastIndexOf("-") + 1,path.lastIndexOf("|FnRowCode="));
            this.FnRowCode = path.substring(path.lastIndexOf("|FnRowCode=")+11);
            console.log(this.ProgId);
        },
        //搜索按钮的方法
        search(pageindex) {
            this.currentPage = pageindex + 1;
            this.pageindex = pageindex;
            this.tableLoading = true;
            this.axiosInvoke("/SupplierComm/Handler/OrderPurPurchaseHandler.ashx", {
                action: "getoperationmenutable",
                progid: this.ProgId,
                startdate: this.startdate,
                enddate: this.enddate,
                pageindex: pageindex,
                pagesize: this.pagesize,
                FnRowCode:this.FnRowCode
            },
                (res) => {
                    var data = eval(res.data);
                    if(data[0].report){
                        this.showtable = false;
                        this.$refs.formframe.src ='/SupplierComm/'+data[0].report;
                        this.title = data[0].title;
                        this.hasBack = false;
                    }else{
                        this.totalCount = data[0].totalCount;
                        data.shift(0);
                        this.title = data[0].displayname;
                        this.fnrowcode = data[0].FnRowCode;
                        this.FormLayoutName = data[0].FormLayoutName;
                        this.OrgId = data[0].OrgId;
                        this.TypeId = data[0].TypeId;
                        this.isAdd = Boolean(data[0].isAdd);
                        data.shift(0);
                        this.columnsData = data[0];
                        this.$refs.tablebody.doLayout();
                        data.shift(0);
                        debugger
                        this.tableData = data;
                        this.tableLoading = false;
                        console.log(data);
                        this.hasBack = true;
                    }
                   
                })
        },
        //日期选择器触发
        getTime() {
            if(this.billdate){
                this.startdate = handleShortDate(this.billdate[0], "From");
                this.enddate = handleShortDate(this.billdate[1], "To");
            }
            else{
                this.startdate = "";
                this.enddate = "";
            }
            this.search(0);
        },
        //重置按钮
        resetInput() {
            this.billdate = "";
            this.startdate = "";
            this.enddate = "";
            this.search(0);
        },

        //翻页处理
        handleCurrentChange(val) {
            this.pageindex = val - 1;
            this.search(val - 1);
        },
        //查看订单详情==>跳转iframe 展示ext窗口
        viewDetail(index,row){
            this.showtable = false;
            this.$refs.formframe.src ='/SupplierComm/billdetailform.aspx?progId='+this.ProgId+'&pkValue='+
            row.BillNo+'&FormClass='+this.FormLayoutName+'&displayName='+this.title+'&FnRowCode='+this.fnrowcode;
        },
        //跳转Iframe的新增
        BillAddNew(){
            this.showtable = false;
            if(this.OrgId&&this.OrgId!=""&&this.TypeId&&this.TypeId!=""){
                var entryParamInfo = encodeURIComponent("{orgid:'" + this.OrgId + "',TypeId:'" + this.TypeId + "',isDefaultEnter:true}");
                this.$refs.formframe.src ="/SupplierComm/billdetailform.aspx?action=addnew&progId=" + this.ProgId
             + "&FormClass=" +this.FormLayoutName+ "&displayName=" +this.title+ "&entryParamInfo=" + entryParamInfo + '&tag=vuesupplier';
            }else{
                this.$refs.formframe.src ="/SupplierComm/billdetailform.aspx?action=addnew&progId=" + this.ProgId
             + "&FormClass=" +this.FormLayoutName+ "&displayName=" +this.title + '&tag=vuesupplier'
            }
           
        },
        changeshowtable(){
            this.showtable = true;
        }
    },
    watch: {
        '$route.path': function (val) {
            this.getProgId(val);
            this.showtable = true;
            this.billdate = "";
            this.startdate = "";
            this.enddate = "";
            this.timeDefault =new Date();
            this.timeDefault.setMonth(new Date().getMonth()-1);
            this.search(0);
        }
    }
}
</script>

<style scoped>
.el-container /deep/.el-main {
    padding: 0;
    margin: 0;
}

.detialmessage {
    width: 75%;
    padding-left: 5%;
}

.el-input {
    width: 22%;
}


.el-input__inner {
    font-family: "siyuanregular";
    margin: auto 0;
}

.el-input__inner::placeholder {
    font-family: "siyuanregular";
}

.el-select .el-input {
    width: 100%;
}

.el-select {
    width: 10%;
    margin-right: 33%;
}

.label {
    margin: auto 0;
    text-align: right;
    width: 9%;
    padding-right: 1%;
}


.search-row {
    height: 32px;
    margin: 1.5rem 0;
    width: 100%;
    display: flex;
}

.search-row .el-button {
    padding: 9px 15px !important;
}

.download-form {
    width: 95%;
    padding: 25px 2.5%;
}

.pagination {
    width: 100%;
}
.formframe{
    width: calc(100% + 2px);
    border:none;
    overflow-y: auto;
}
</style>