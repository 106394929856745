<template>
    <Frame>
        <PageHeader :title="$t('comm.downloadprint')" :hasBack="false" />
        <div>
            <div class="search-row">
                <div style="display: flex; flex-wrap: nowrap; width: 80%;">
                    <p style="margin-left:2%;" class="label">{{ $t('Download.orderinprogress') }}:</p>
                    <el-input v-model="billNoFrom" :placeholder="$t('Download.inputpls')" @change="inputchange()"
                        size="small"></el-input>
                    <p class="label">{{ $t('Download.To') }}:</p><el-input v-model="billNoTo"
                        :placeholder="$t('Download.inputpls')" @change="inputchange()" size="small"></el-input>
                    <p style="margin-left:2%;" class="label">{{ $t('Download.recupdate') }}:</p>
                    <el-date-picker v-model="date" type="daterange" style="color:#bbbbbb;" range-separator="~"
                        :start-placeholder="$t('comm.startdate')" :end-placeholder="$t('comm.enddate')" :default-value="timeDefault"
                        :unlink-panels="true" @change="gettime()" size="small"></el-date-picker>
                </div>
                <div style="text-align: right; width: 17%;margin-right: 3%;">
                    <el-button style="margin-left:3%;" @click="resetInput" plain>{{ $t('button.reset') }}</el-button>
                    <el-button @click="search(0)" type="primary">{{ $t('button.search') }}</el-button>
                </div>
            </div>
            <div class="download-form">
                <el-table v-loading="loading" :data="tableData" @selection-change="HandleBodySelectChange"
                    @select-all="HandleBodySelectAll" style="width: 100%;" :height="tableHeight">
                    <el-table-column type="selection"></el-table-column>
                    <el-table-column prop="Number" :label="$t('comm.number')" width="100">
                    </el-table-column>
                    <el-table-column prop="BillNo" :label="$t('comm.order')">
                        <template scope="scope">
                        <el-link :underline="false" @click=" ToOrderDetail(scope.row.BillNo)">
                            {{ scope.row.BillNo }}</el-link>
                    </template>
                    </el-table-column>
                    <el-table-column prop="UploadTime" :label="$t('Download.recupdate')">
                    </el-table-column>
                    <el-table-column prop="option" :label="$t('Download.operation')" width="250">
                        <template slot-scope="scope">
                            <el-link :underline="false" @click="ViewPrint(scope.$index, scope.row, 'viewall')"
                                type="primary">{{ $t('Download.viewall') }}
                            </el-link>
                            <el-link style="margin-left:25px;" :underline="false"
                                @click="ViewPrint(scope.$index, scope.row, 'viewchange')" type="primary">
                                {{ $t('Download.viewchange') }}
                            </el-link>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="footer">
                <el-pagination style="width:48%;" @current-change="handleCurrentChange"
                    layout="total,prev,pager,next,jumper" :pager-count="5" :current-page="currentPage"
                    :total="totalCount">
                </el-pagination>
                <div class="download-btn">
                    <el-button @click="batchdown('all')" type="primary">{{ $t('Download.batchdownall') }}</el-button>
                    <el-button @click="batchdown('change')" type="primary">{{ $t('Download.batchdownchange')
}}</el-button>
                </div>
            </div>
        </div>
        <el-dialog :title="dialogtitle" :visible.sync="dialogvisible">
            <el-table v-loading="dialogloading" :data="dialogdata" @select-all="handleSelectAll"
                @selection-change="handleSelectionChange" height="50vh" ref="dialogview">
                <el-table-column type="selection"></el-table-column>
                <el-table-column property="Number" :label="$t('comm.number')" width="100"></el-table-column>
                <el-table-column property="accessoryName" :label="$t('Download.accessoryname')"></el-table-column>
                <el-table-column property="date" :label="$t('Download.recupdate')"></el-table-column>
                <el-table-column prop="option" :label="$t('Download.operation')" width="100">
                    <template slot-scope="scope">
                        <el-link :underline="false" @click="DownLoadPrint(scope.$index, scope.row)" type="primary">
                            {{ $t('Download.clickdownload') }}
                        </el-link>
                    </template>
                </el-table-column>
            </el-table>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="DownLoadSelect">{{ $t('Download.downloadall') }}</el-button>
            </div>
        </el-dialog>
    </Frame>
</template>
<script>

import { handleDate } from '../utils/handleDate.js'
import { uploaddowninfo, downloadprint } from '@/utils/utils'
export default {
    name: "DownloadPrint",
    data() {
        return {
            date: '',
            tableData: [],
            dialogdata: [],
            billNoFrom: "",
            billNoTo: "",
            selectTimeFrom: '',
            selectTimeTo: '',
            loading: true,
            dialogloading: true,
            tableHeight: window.innerHeight - 307,
            totalCount: 0,
            currentPage: 1,
            dialogvisible: false,
            dialogtitle: "",
            pageindex:0,
            pagesize:10,
            dialogbillno: "",
            diaselect: [],
            selectdata: [],
            timeDefault:'',
        }
    },
    watch: {
        dialogvisible: function (val) {
            if (!val) {
                this.dialogloading = true;
            }
        }
    },
    created(){
        if(this.$route.query.BillNo){
            this.billNoFrom=this.$route.query.BillNo;
            this.billNoTo=this.$route.query.BillNo;
        }
        this.getSessionInform("DownloadPrint",(v)=>{
            this.currentPage = v.currentPage;
            this.pageindex = v.pageindex;
            this.pagesize = v.pagesize;
            this.selectTimeTo = v.selectTimeTo,
            this.selectTimeFrom = v.selectTimeFrom,
            this.billNoFrom = v.billNoFrom,
            this.billNoTo = v.billNoTo,
            this.date = v.date
        })
    },
    mounted() {
        let that = this
        window.onresize = () => {
            if (that.resizeFlag) {
                clearTimeout(that.resizeFlag);
            }
            that.resizeFlag = setTimeout(() => {
                that.tableHeight = window.innerHeight - 307
                that.resizeFlag = null;
            }, 100);
        }
        this.timeDefault =new Date();
        this.timeDefault.setMonth(new Date().getMonth()-1);
        this.search(this.pageindex);

    },
    methods: {
        gettime() {
            if(this.date){
                this.selectTimeFrom = handleDate(this.date[0], "From");
                this.selectTimeTo = handleDate(this.date[1], "To");
            }
            else{
                this.selectTimeFrom = "";
                this.selectTimeTo = "";
            }
            this.search(0);
        },
        resetInput() {
            this.billNoFrom = "";
            this.billNoTo = "";
            this.selectTimeFrom = "";
            this.selectTimeTo = "";
            this.date = "";
            this.search(0);
        },
        inputchange() {
            this.search(0);
        },
        search(pageindex) {
            this.pageindex = pageindex
            this.loading = true;
            this.axiosInvoke("/SupplierComm/Handler/VueSysHandler.ashx", {
                action: "getdrawings",
                billNoFrom: this.billNoFrom,
                billNoTo: this.billNoTo,
                selectTimeFrom: this.selectTimeFrom,
                selectTimeTo: this.selectTimeTo,
                pageindex: pageindex,
                pagesize: this.pagesize
            },
                (res) => {
                    var data = eval(res.data);
                    this.totalCount = data[0].totalCount;
                    this.currentPage = pageindex+1;
                    data.shift(0);
                    this.tableData = data;
                    this.loading = false;
                    console.log(data);
                })
        },
        handleCurrentChange(val) {
            this.loading = true;
            this.search(val - 1);
        },
        ViewPrint(index, row, action) {
            //查看所有或者只查看变更
            this.dialogbillno = row.BillNo;
            if(action == "viewall"){
                this.dialogtitle = this.$t('Download.viewalldrawings') + "(" + row.BillNo + ")";
            }else{
                this.dialogtitle = this.$t('Download.viewchangedrawings') + "(" + row.BillNo + ")";
            }
            this.dialogvisible = true;
            console.log(index, row);
            this.axiosInvoke("/SupplierComm/Handler/DownprintHandler.ashx",
                {
                    action: action,
                    billNo: row.BillNo,
                },
                (res) => {
                    var data = eval(res.data);
                    for (let i = 0; i < data.length; i++) {
                        data[i].Number = i + 1;
                    }
                    this.dialogdata = data;
                    this.dialogloading = false;
                    this.$refs.dialogview.toggleAllSelection();
                    this.diaselect = data;
                    console.log(data);
                })
        },
        HandleBodySelectAll(val) {
            this.selectdata = val;
        },
        handleSelectAll(val) {
            this.diaselect = val;
        },
        HandleBodySelectChange(val) {
            this.selectdata = val;
        },
        handleSelectionChange(val) {
            this.diaselect = val;
        },
        batchdown(type) {
            let that = this;
            if (this.selectdata.length == 0) {
                this.$store.commit("TIPPOP",{popType:'warning',popMessage:this.$t('comm.unselectwarning')});
                return
            }
            let ischange = false;
            if (type == "change") {
                ischange = true;
            }
            this.batchdownoption(ischange, that);
        },
        async batchdownoption(ischange, that) {
            let haveflag = false;
            for (let i = 0; i < that.selectdata.length; i++) {
                await this.asyncaxiosInvoke("/SupplierComm/Handler/DownprintHandler.ashx", {
                    action: "batchbilldownload",
                    ischange,
                    BillNo: that.selectdata[i].BillNo
                }, (res) => {
                    var data = eval(res.data);
                    if (data.length > 0) haveflag = true;
                    console.log(haveflag);
                    for (let j = 0; j < data.length; j++) {
                        downloadprint(data[j].accessoryId, data[j].accessoryName);
                        uploaddowninfo(that.selectdata[i].BillNo, data[j].accessoryId, data[j].accessoryName, that);
                    }
                })
            }
            if (!haveflag)
            this.$store.commit("TIPPOP",{popType:'warning',popMessage:this.$t('Download.unselecttip')});
        },
        DownLoadPrint(index, row) {
            //下载图纸
            let that = this;
            downloadprint(row.accessoryId, row.accessoryName);
            uploaddowninfo(this.dialogbillno, row.accessoryId, row.accessoryName, that);
        },
        DownLoadSelect() {
            let that = this;
            if (that.diaselect.length > 0) {
                for (let i = 0; i < that.diaselect.length; i++) {
                    downloadprint(that.diaselect[i].accessoryId, that.diaselect[i].accessoryName);
                    uploaddowninfo(that.dialogbillno, that.diaselect[i].accessoryId, that.diaselect[i].accessoryName, that);
                }
            } else 
            this.$store.commit("TIPPOP",{popType:'warning',popMessage:this.$t('comm.unselectwarning')});
        },
        //跳转到orderDetail
        ToOrderDetail(billno){
            this.recoredPageToJump({
                pageindex:this.pageindex,
                currentPage: this.currentPage,
                pagesize: this.pagesize,
                selectTimeTo: this.selectTimeTo,
                selectTimeFrom: this.selectTimeFrom,
                billNoFrom:this.billNoFrom,
                billNoTo:this.billNoTo,
                date:this.date
            },"DownloadPrint",billno,"OrderDetail");
        },
    }
}
</script>

<style scoped>
.el-input {
    width: 13%;
}

.el-input__inner {
    font-family: "siyuanregular";
    margin: auto 0;
}

.el-table /deep/ .el-table__header .cell .el-checkbox:first-child {
    padding-left: 5px;
}

.el-input__inner::placeholder {
    font-family: "siyuanregular";
}

.el-select .el-input {
    width: 100%;
}

.el-select {
    width: 10%;
    margin-right: 33%;
}

.label {
    white-space: nowrap;
    margin: auto 0.5%;
    text-align: right;
}

::v-deep .el-main {
    margin: 0;
}

.download-btn {
    text-align: right;
    padding-right: 3%;
    width: 47%;
}
.el-pagination{
    padding: 2px 0 2px 25%;
}
@media screen and (max-width: 1280px){
    .el-pagination{
    padding: 2px 0 2px 20%;
}
}
.download-btn .el-button {
    padding: 8px;
}

.search-row {
    margin: 1.5rem 0;
    width: 100%;
    display: flex;
}

.search-row .el-button {
    padding: 9px 15px !important;
    margin-left: 1%;
}

.download-form {
    width: 95%;
    padding: 10px 2.5% 0;
}

.footer {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 10px;
}
</style>