import { Serialize} from '../utils/utils.js'
function BillParams(that,dontchecklength) {
  that.printType = "";
  that.exportType = "";
  var params = [];
  var orgId = "";
  var typeId = "";
  var billnos = [];
  //中间这一段逻辑暂时用不上了，若后续规格异动，继续使用打印并且需要校验组织，单据类型时，可以解注释使用
  /*
  if (that.selectData.length == 0&&!dontchecklength) {
      that.$store.commit("TIPPOP",{popType:'warning',popMessage: that.$t("DeliverCar.message")});
      return;
  }
  */
  if(that.tableData.length == 0){
    that.$store.commit("TIPPOP",{popType:'warning',popMessage: that.$t("Order.nodata")});
    return;
  }
  else {
    let errbillnos = "";
    let errOrgIds = "";
    let errTypeIds = "";
    let orgIdAy = new Array();
    let typeIdAy = new Array();
    params["billOrgId"] = orgId;
    params["billTypeId"] = typeId;
    params["printvisible"] = true;
    //若解下面的注释 记得要删除如下正在使用的代码
    that.selectData.forEach((v) => {
      if(typeof(v.billno) != 'undefined') billnos.push(v.billno);
      else if(typeof(v.BillNo) != 'undefined') billnos.push(v.BillNo);
    })
    //中间这一段逻辑暂时用不上了，若后续规格异动，继续使用打印并且需要校验组织，单据类型时，可以解注释使用
    /*
    that.selectData.forEach((v) => {
      // BUG202305300009 新版供应商，订单详情查询，点击批量打印，确认后提示“'单据编号'字段比较符为'包括'，比较值不能为空！”
      // 增加服务端返回参数大小写的检查，目前单据列表返回小写，详情返回大小，需做兼容
      if(typeof(v.billno) != 'undefined') billnos.push(v.billno);
      else if(typeof(v.BillNo) != 'undefined') billnos.push(v.BillNo);
      //增加orgId和typeId的传值和取数
      if("" != orgId){
        if(typeof(v.orgId) != 'undefined') {
          if(orgId != v.orgId){
            orgIdAy.push(orgId);
            orgIdAy.push(v.orgId);
          }
        }
        else if(typeof(v.OrgId) != 'undefined') {
          if(orgId != v.OrgId){
            orgIdAy.push(orgId);
            orgIdAy.push(v.OrgId);
          }
        }
      }
      else{
        if(typeof(v.orgId) != 'undefined') orgId = v.orgId;
        else if(typeof(v.OrgId) != 'undefined') orgId = v.OrgId;
      }
      
      if(""!=typeId){
        if(typeof(v.typeId) != 'undefined') {
          if(typeId != v.typeId){
            typeIdAy.push(typeId);
            typeIdAy.push(v.typeId);
          }
        }
        else if(typeof(v.TypeId) != 'undefined') {
          if(typeId != v.TypeId){
            typeIdAy.push(typeId);
            typeIdAy.push(v.TypeId);
          }
        }
        
      }
      else{
        if(typeof(v.typeId) != 'undefined') typeId = v.typeId;
        if(typeof(v.TypeId) != 'undefined') typeId = v.TypeId;
      }
    })

    if(null != orgIdAy && orgIdAy.length > 0) {
      orgIdAy.forEach((v) =>{
        if( errOrgIds != ""){
          if(errOrgIds.indexOf(v) == -1) errOrgIds += "," + v;
        }else{
          errOrgIds += "" + v;
        }
      })
    }
    if(null != typeIdAy && typeIdAy.length > 0) {
      typeIdAy.forEach((v) =>{
        if( errTypeIds != ""){
          if(errTypeIds.indexOf(v) == -1) errTypeIds += "," + v;
        }else{
          errTypeIds += "" + v;
        }
      })
    }
    params["billOrgId"] = orgId;
    params["billTypeId"] = typeId;
    params["printvisible"] = true;
    if(errOrgIds != ""&&!dontchecklength){
      that.$store.commit("TIPPOP",{popType:'warning',popMessage:that.$t("Order.billOrgIdErr").replace("{0}",errOrgIds)});
      return false;
    }
    if(errTypeIds != ""&&!dontchecklength){
      that.$store.commit("TIPPOP",{popType:'warning',popMessage:that.$t("Order.billTypeIdErr").replace("{0}",errTypeIds)});
      return false;
    }
    if(errbillnos!=""&&!dontchecklength){
      that.$store.commit("TIPPOP",{popType:'warning',popMessage:that.$t("Order.confirmerr").replace("{0}",errbillnos)});
      return false;
    }
    */
  }
  var BillType = "";
  if(that.BillType)BillType = that.BillType;//为了用于区分退货查询和发货查询开的单据类型
  that.axiosInvoke("/SupplierComm/Handler/VueSysHandler.ashx",
    {
    action: "batchdoprintbill",
    BillNo: that.billno,
    selectKeys:JSON.stringify(billnos),
    lang:localStorage.getItem("language")=="CHS"?"zh-CHS":"zh-CHT",
    ProgId: that.progId, //"purPurchaseOrder",
    BillType
    },
    (res) => {
      let data = eval(res.data);
      debugger
      that.printtemp = data[0];
      that.typegather = data[0].files;
      that.exportType = decodeURIComponent(data[0].exportType);
      that.printType = decodeURIComponent(data[0].printType);
      let exportexist = false;
      let printexist = false;
      that.typegather.forEach((v)=>{
        if(v.value == that.exportType){
          exportexist = true;
        }
        if(v.value == that.printType){
          printexist = true;
        }
      })
      //取回cookie里保存的打印类型后，要和现在可选的打印类型全部比对一遍，若不存在则置为空
      if(!exportexist)that.exportType = "";
      if(!printexist)that.printType = "";
    })
  return params;
}

//打印预览
function PrintView(that,isexcel) {
    debugger
    var BillType = "";
    if(that.BillType)BillType = that.BillType;//为了用于区分退货查询和发货查询开的单据类型
    if(isexcel) that.$cookies.set(BillType+that.progId+"exportType", that.exportType,"365D");
    else that.$cookies.set(BillType+that.progId+"printType", that.printType,"365D");
    var theParam = {
      progId: that.progId, //"purPurchaseOrder",
      lang: that.printtemp.lang,
      file: that.printType,
    //   pid: that.printtemp.pid,  //暂不使用pid，此时页面没有绑定到父类pid，新创建的pid解析时会为空
      date: '',
      billPrintKind: 1, //打印清单选中BillPrintKind.Select
      conditions: '', //this.printtemp.conditions, //打印清单选中列表，不用传conditions条件
      selectKeys: that.printtemp.selectKeys,
      billOrgId: that.billOrgId,
      billTypeId: that.billTypeId
    }
    var url = "";
    if(isexcel) {
      theParam.file = that.exportType;
      url = "/SupplierComm/ExportXlsx.aspx?ispdf=0&isexcel=true&params=" + encodeURI(Serialize(theParam));
    }
    else{
      theParam.file = that.printType;
      url = "/SupplierComm/ExportXlsx.aspx?ispdf=1&params=" + encodeURI(Serialize(theParam));
    }

    window.open(url, "_blank");
  }

  export {
    BillParams,PrintView
} 
