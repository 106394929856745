import { handleShortDate } from './handleDate';
//序列化对象 类似JSON.stringify 但是对象名没有双引号
function Serialize(obj) {
    var result = "";
    if (obj == undefined) {
        result = "null";
    }
    else if (isArray(obj)) {
        result = SerializeArray(obj);
    }
    else if (isDate(obj)) {
        result = formatDateTime(obj);
        result = "\"" + result + "\"";
    }
    else if (isObject(obj)) {
        result = "{";
        var i = 0;
        for (var p in obj) {
            if (p != undefined) {
                if (i > 0) result = result + ",";
                result = result + p.toString() + ":" + Serialize(obj[p]);
                i++;
            }
        }
        result = result + "}";
    }
    else {
        result = obj.toString();
        if (typeof (obj) == "string") {
            var fn = function (str, i) {
                return "\\" + str;
            };
            result = result.replace(/("|\\)/g, fn);
            result = "\"" + result + "\"";
        }
    }
    return result;
}

//将数组对象系列化
function SerializeArray(obj) {
    result = "[";
    for (var i = 0; i < obj.length; i++) {
        var item = obj[i];
        if (i > 0) result = result + ",";
        result = result + CapWeb.Serialize(item);
    }
    result = result + "]";
    return result;
}
function isArray(obj) {
    //return obj instanceof Array;
    if (typeof Array.isArray === 'function')
        return Array.isArray(obj);
    return Object.prototype.toString.apply(obj) === '[object Array]';
};
function isDate(obj) {
    return obj instanceof Date;
};
function isObject(obj) {
    return typeof (obj) == "object";
};
function formatDateTime(value, hasTime) {
    if (!value) return "";
    var fn = function (v) {
        if (v >= 0 && v < 10) v = "0" + v;
        return v;
    };
    var d = [];
    d[0] = fn(value.getFullYear());
    d[1] = fn(value.getMonth() + 1);
    d[2] = fn(value.getDate());
    var result = d.join("-");

    if (hasTime != false) {
        var t = [];
        t[0] = fn(value.getHours());
        t[1] = fn(value.getMinutes());
        t[2] = fn(value.getSeconds());
        result += " " + t.join(":");
    }
    return result;
}
console.log(process.env.NODE_ENV);


/**
 * 服务端传回的错误捕获和程序执行
 * @param {*} res //axios的回调函数的参数
 * @param {*} thenfun //调用axios时传入的回调后的执行方法
 */
function excuteAndErrorcatch(res,thenfun,that){
    let jumptip = false;
        var errortag = false;
        if (res.data.indexOf("xtype:'error'") > -1 || res.data.indexOf("xtype:'script'") > -1 || res.data.indexOf("xtype:'exception'") > -1||res.data.indexOf('customErrors mode="Off"')>-1) {
            if (res.data.indexOf("xtype:'error'") > -1) {
                var message = res.data.substring(res.data.indexOf("reason:'") + 8, res.data.indexOf("',hashcode"));
                if (res.data.indexOf("log:'") > -1) {
                    var info = res.data.substring(res.data.indexOf("log:'") + 5, res.data.indexOf("'}}")).replace(/\\r\\n/g, '<br/>');
                } else {
                    let data = eval("(" + res.data + ")");
                    if (data.errorCode == "pwdChangeRequired") {
                        jumptip = true;
                    }
                    var info = "errorCode:" + data.errorCode;
                }
            }
            else if (res.data.indexOf("xtype:'script'") > -1) {
                var message = "";
                if(res.data.indexOf("throwInfo")>-1) message = res.data.substring(res.data.indexOf("throwInfo") + 11, res.data.indexOf("<br/>"));
                else message = res.data.substring(res.data.indexOf("message:'") + 9, res.data.indexOf("。'"));
                var info = res.data.substring(res.data.indexOf("message:") + 9, res.data.lastIndexOf("'}")).replace(/\\r\\n/g, '<br/>');
            }
            else if (res.data.indexOf("xtype:'exception'") > -1) {
                var message = res.data.substring(res.data.indexOf("message:'") + 9, res.data.indexOf(",ignoreError"));
                var info = res.data.substring(res.data.indexOf("log:'") + 5, res.data.lastIndexOf("'}]}")).replace(/\\r\\n/g, '<br/>');
            }
            else if(res.data.indexOf('customErrors mode="Off"')>-1){
                var message = res.data.substring(res.data.indexOf('"msg">') + 6, res.data.indexOf(" <!--<a"));
                var info = res.data.substring(res.data.indexOf('"msg">') + 6, res.data.indexOf(" <!--<a"));
            }
            // 增加完善特殊字符\的解析处理——ADV202309110018
            var tempMessage = message.replace(/(\\r\\n)|(\\n)/g,'<br/>');
            tempMessage = tempMessage.replace(/\\/g,"");
            var tempInfo = info.replace(/(\\r\\n)|(\\n)/g,'<br/>');
            tempInfo = tempInfo.replace(/\\/g,"");
            var dialog = {
                isShow: true,
                title: that.$t('comm.tip'), //提示
                // ADV202309080011 新版供应商，捕获异常提示信息优化调整，同时兼容PC端和手机端接收后台返回的异常提示信息的解析和显示
                message: tempMessage, 
                info:tempInfo
            }
            if (res.data.indexOf("errorCode:'loginerror'") > -1) {
                //提示登录信息报错时，五秒后自动退回到登录界面
                var timer = setTimeout(() => {
                    that.$router.push(
                        { path: '/Login' }
                    );
                    that.$store.commit("ERRORTIP", false, "", "", "");
                }, 5000)
                dialog.timer = timer;
                if(that.$route.path=="/Login")clearTimeout(timer);
            }
          
            if (!jumptip &&!(res.data.indexOf("errorCode:'loginerror'") > -1 && that.$route.path=="/Login") ) that.$store.commit("ERRORTIP", dialog);
            errortag = true;


        }
        if (thenfun && !errortag) {
            thenfun(res);
        }
        else if (thenfun && errortag) {
            thenfun(res, errortag);
        }
}

/**
 * 访问服务器出错的错误捕获
 * @param {*} err 
 */
function axiosErrorCatcher(err,that){
    console.log(err);
    if(err.response){
        var dialog = {
            isShow: true,
            title: that.$t('comm.tip'), //提示
            message:"服务器已终止，请重启iis服务",
            info:err.response.data,
        }
        that.$store.commit("ERRORTIP", dialog);
    }
   
}

function paramHandler(isfile,params){
    var param;
    if (isfile) {
        param = new FormData();
        param.append("file", params.file);
    }
    else {
        param = new URLSearchParams();
        for (let v in params) {
            param.append(v, params[v]);
        }
    }
    return param;
}

/**
 * 封装后的axios方法 其中会处理c#传回的ext报错信息 将其转化为elementUI的弹窗
 * @param {string} url 需要传入的地址;
 * @param {object} params 传给后端的参数（对象形式or文件形式）;
 * @param {function} thenfun 后端处理完以后的回调函数 如(res)=>{ 需要执行的内容 } 其中res携带了后端传回的内容;
 * @param {bool} isfile 上传的参数是否为文件
 */
function axiosInvoke(url, params, thenfun, isfile) {
    let param = paramHandler(isfile,params);
    this.axios.post(url, param).then(res => {
        excuteAndErrorcatch(res,thenfun,this);
    }).catch(err => {
        axiosErrorCatcher(err,this);
    })
}

/**
 * 同步的axios方法
 * @param {*} url url地址
 * @param {*} params object参数 对象形式
 * @param {*} thenfun 回调后执行的function  如(res)=>{ 需要执行的内容 } 其中res携带了后端传回的内容
 * @param {*} that this
 */
async function asyncaxiosInvoke(url, params, thenfun,isfile) {
    let param = paramHandler(isfile,params);
    await this.axios.post(url, param).then(res => {
        excuteAndErrorcatch(res,thenfun,this);
    }).catch(err => {
        axiosErrorCatcher(err,this);
    })
}


/**
 * 下载图纸的方法
 * @param {*} AccessoryId 附件ID
 * @param {*} AccessoryName 附件名称
 */
function downloadprint(AccessoryId, AccessoryName) {
    setTimeout(() => {
        let iframe = document.createElement("iframe");
        iframe.src = encodeURI('/SupplierComm/Handler/AccessoryHandler.ashx?action=download&accessoryId=' + AccessoryId + '&accessoryName=' + encodeURIComponent(AccessoryName));
        debugger
        iframe.style.display = "none";
        document.body.appendChild(iframe);
    }, 100)
}
/**
 * 上传被下载的图纸的信息
 * @param {*} BillNo 单据号
 * @param {*} accessoryId 附件ID
 * @param {*} accessoryName 附件名称
 * @param {*} that this
 */
function uploaddowninfo(BillNo, accessoryId, accessoryName, that) {
    var needdown = [];
    needdown.push({ AccessoryId: accessoryId, AccessoryName: accessoryName });
    that.axiosInvoke("/SupplierComm/Handler/DownprintHandler.ashx", {
        action: 'updatetime',
        BillNo,
        DownloadInfo: JSON.stringify(needdown)
    })
}


function handlerDetail(path) {
    if (path.indexOf("Detail") > -1) {
        path = path.slice(0, path.indexOf("Detail"));
    }
    return path
}
/**
 * 获取本月日期 返回date数组（true可直接用于date-picker中绑定的值，或用于传参给后台），0是本月起始如期，1是本月结束日期
 * @param {bool} picker 是否转为date-picker中的date格式
 * @returns date形式或者是20230324这种格式
 */
function getThisMonth(picker) {
    var date = new Date();
    var datebegin = new Date(new Date(date.getFullYear() + "-" + (date.getMonth() + 1) + "-01"));
    if (date.getMonth() != 11)
        var dateend = new Date(new Date(date.getFullYear() + "-" + (date.getMonth() + 2) + "-01") - 24 * 3600 * 1000);
    else var dateend = new Date(new Date(date.getFullYear + "-12-31"));
    var startdate = handleShortDate(datebegin, "From");
    var enddate = handleShortDate(dateend, "From");
    if (picker)
        return [datebegin, dateend];
    else
        return [startdate, enddate]
}
/**
 * 对象参数转换为url的参数格式 例{a:1,b:2,c:3} => &a=1&b=2&=3
 * @param {*} paramObj {a:1,b:2,c:3}
 * @returns &a=1&b=2&=3
 */
function objectToUrlParam(paramObj) {
    var paramstr = "";
    for (var v in paramObj) {
        paramstr += "&" + v + "=" + paramObj[v];
    }
    return paramstr;
}

//页面批量导出方法封装
function radioJudgeExcel(action, params, radio, progId, selectdata, that, handler) {
    let Action = 'exportexcel';
    if (!handler) {
        handler = "OrderPurPurchaseHandler"
    }
    if (radio == 'exportTextB') //导出当前所有数据
    {
        let iframe = document.createElement("iframe");
        iframe.src = encodeURI('/SupplierComm/Handler/' + handler + '.ashx?action=' + action + objectToUrlParam(params));
        iframe.style.display = "none";
        document.body.appendChild(iframe);
    }
    else //导出选中数据
    {
        //取回当前选中数据表进行导出
        if (selectdata.length == 0) {
            that.$store.commit("TIPPOP", { popType: 'warning', popMessage: that.$t('comm.unselectwarning') });
            return false;
        } else {
            if (params && params.isBill && !params.isRpt) {
                //仅单据导出
                that.exportExcel(Action, progId, selectdata, params)
            }
            else if (params && !params.isBill && params.isRpt) {
                //仅报表导出
                let tableDataAy = encodeURIComponent(JSON.stringify(selectdata))
                let iframe = document.createElement("iframe");
                iframe.name = "iframe";
                let form = document.createElement("form");
                let input = document.createElement("input");
                input.name = "tableDataAy";
                input.value = tableDataAy;
                form.target = "iframe";
                form.method = "post";
                form.action = encodeURI('/SupplierComm/Handler/' + handler + '.ashx?action=' + action + objectToUrlParam(params));
                form.appendChild(input);
                form.appendChild(iframe);
                form.style.display = "none";
                iframe.style.display = "none";
                document.body.appendChild(form);
                form.submit();
            }
        }
    }
}

/**
 * 跳转页面 记录当前页面信息的方法
 * @param {object} pageParam 页面需要保存的信息
 * @param {String} pageName 页面名称
 * @param {String} BillNo 跳转单号
 * @param {String} toPageName 跳转的页面名称
 */
function recoredPageToJump(pageParam, pageName, BillNo, toPageName) {
    this.$router.push({
        path: "/Home/" + toPageName,
        query: {
            DelBillNo: BillNo
        }
    })
    sessionStorage.setItem(pageName, JSON.stringify(pageParam));
}
/**
 * 读取sesstionStorage里的页面信息 并且读取完就清除sesstionStorage里的对应信息 读取的操作需要自己传进来
 * @param {Stirng} pageName 页面名称
 * @param {function(data)} fun 读取执行的方法  data是sesstion里的内容
 */
function getSessionInform(pageName, fun) {
    if (sessionStorage.getItem(pageName) && sessionStorage.getItem("frompath").indexOf("OrderDetail") > -1) {
        var data = JSON.parse(sessionStorage.getItem(pageName));
        fun(data);
        sessionStorage.removeItem(pageName);
    } else {
        sessionStorage.removeItem(pageName);
    }
}
function isExternal(path) {
    return /^(https?:|mailto:|tel:)/.test(path)
}
function monthDayCounter(month, year) {
    var YEAR = 0;
    var MONTH = Number(month);
    switch (MONTH) {
        case 1:
        case 3:
        case 5:
        case 7:
        case 8:
        case 10:
        case 12:
            return 31;
        case 4:
        case 6:
        case 9:
        case 11:
            return 30;
        case 2:
            if (!year) {
                YEAR = new Date().getFullYear();
            } else {
                YEAR = Number(year);
            }
            if (year % 4 == 0 && year % 100 != 0) {
                return 29;
            } else return 28;

    }
}
/**
 * ADV202306270009 重新调整的批量导出方法
 * @param {*} eipparam 模拟eip点击打印时传入服务的参数
 * @param {*} selectbillnos 选中的单号
 * @param {*} isQueryForm 是否为列表查询
 * @param {*} pid 
 * @param {*} progId 
 */
function BatchExportSession(eipparam,selectbillnos,ProgId,that){
    if(eipparam == "default"){
        eipparam = [
            {field:"BillNo",operator:null,value:null,isCondition:true,used:true,fieldTableIndex:0},
            {field:"BillDate",operator:null,value:null,isCondition:true,used:true,fieldTableIndex:0},
            {field:"BizPartnerName",operator:null,value:"",isCondition:true,used:true,fieldTableIndex:0},
            {field:"PersonName",operator:null,value:"",isCondition:true,used:true,fieldTableIndex:0}
        ]
    }
    that.axiosInvoke("/SupplierComm/Handler/VueSysHandler.ashx",
    {
        action:'printprev',
        conditions:JSON.stringify(eipparam),
        selectKeys:JSON.stringify(selectbillnos),
        ProgId,
        rid:new Date().getTime()
    },(res)=>{
        console.log(res.data);
    })
}

function loadAndUpdateVersion(that){
    if(!sessionStorage.getItem("loginDate",new Date().getTime())) {
        sessionStorage.setItem("loginDate", new Date().getTime());
        window.location.reload(true);  
    }
    if(!that.loginDate){
        that.$cookies.set("loginDate", new Date().getTime(),"600S");
        window.location.reload(true);    
    }
    var packageData = require('../../package.json'); 
    let version = packageData.version;
    let lastBuildTime = packageData.lastBuildTime;
    localStorage.setItem('packageVersion',version); // 记录版本
    localStorage.setItem('lastBuildTime',lastBuildTime); // 记录编译时间
}
function getWebSupplierConfig(that,fun){
    if(sessionStorage.getItem("websupplierconfig")&&sessionStorage.getItem("websupplierconfig")!=""){
        that.$store.commit("SETCONFIGFIELD", JSON.parse(sessionStorage.getItem("websupplierconfig")));
    }
    that.asyncaxiosInvoke("/SupplierComm/Handler/OrderPurPurchaseHandler.ashx",
    {action:"getwebsupplierconfig"},
    (res)=>{
        // ADV202306010014 新版供应商平台配置，增加参数配置栏位【不同收货地址产生不同收货申请单】、是否显示【回复交期】、是否显示【发货基本单位】
        var data = eval("("+res.data+")")
        // 不同地址产生不同收货申请单、支持供应商变更交期、显示基本单位、显示MTO
        // GenrateDifferentTypes、AllowChangeOfDeliveryDate、ShowBasicUnit、ShowMTO
        let ay = new Array();
        if(data.GenrateDifferentTypes != 'undefined') {                    
            ay.push({"GenrateDifferentTypes":data.GenrateDifferentTypes});
        }
        if(data.AllowChangeOfDeliveryDate != 'undefined') {
            ay.push({"AllowChangeOfDeliveryDate":data.AllowChangeOfDeliveryDate});
        }
        if(data.ShowBasicUnit != 'undefined') {
            ay.push({"ShowBasicUnit":data.ShowBasicUnit});
        }
        if(data.ShowMTO != 'undefined') {
            ay.push({"ShowMTO":data.ShowMTO});
        }
        if(ay != '' && ay.length > 0){
            sessionStorage.setItem("websupplierconfig",JSON.stringify(ay));
            that.$store.commit("SETCONFIGFIELD", ay);
        }
        debugger
        if(fun){
           
            fun();
        }
    }
    )
}
export {
    Serialize, axiosInvoke, downloadprint, uploaddowninfo, asyncaxiosInvoke, getSessionInform,
    handlerDetail, getThisMonth, objectToUrlParam, radioJudgeExcel, recoredPageToJump, isExternal, monthDayCounter,
    BatchExportSession,getWebSupplierConfig,loadAndUpdateVersion
} 