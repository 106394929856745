<template>
  <Frame>
    <PageHeader :title="$t('comm.ARSearch')" :hasBack="false" />
    <div class="ARSearch" style="padding:10px 0 0;">
      <div class="ARmessage">
        <span class="detialmessage">{{ $t('ARSearch.messagedate') }}：<el-date-picker v-model="date" type="daterange"
            style="width:20%;color:#bbbbbb;" range-separator="~" :start-placeholder="$t('comm.startdate')" :default-value="timeDefault"
            :unlink-panels="true" :end-placeholder="$t('comm.enddate')" @change="gettime()" size="small"></el-date-picker></span>
        <span class="detialmessage">{{ $t('ARSearch.compName') }}：<el-input
            :placeholder="$t('ARSearch.inputpls')" v-model="compName" style="width:20%;color:#bbbbbb;" @change="search(0)" size="small"></el-input></span>
        <span style="padding-left:120px;padding-bottom:20px;">
          <el-button type="primary" @click="query()" style="padding: 8px 20px;">{{ $t('button.query') }}</el-button>
        </span>
      </div>
      <el-table ref="multipleTable" :data="tableData" :height="tableHeight" v-loading="tableLoading"
        :summary-method="getSummaries" show-summary style="margin:54px 0 0;width:100%" tooltip-effect="dark">
        <el-table-column prop="billtype" :label="$t('ARSearch.documenttype')" ></el-table-column>
        <el-table-column prop="billno" :label="$t('ARSearch.messagenumber')" ></el-table-column>
        <el-table-column prop="CompName" :label="$t('ARSearch.compName')" ></el-table-column>
        <el-table-column prop="billdate" :label="$t('ARSearch.date')" ></el-table-column>
        <el-table-column prop="amountreceivable" align="right" :label="$t('ARSearch.amountreceivable')" >
          <template slot-scope="scope">
            <span>{{ scope.row.amountreceivable | MoneyFormat }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="currentamount" align="right" :label="$t('ARSearch.currentamount')" >
          <template slot-scope="scope">
            <span>{{ scope.row.currentamount | MoneyFormat }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="discountamount" align="right" :label="$t('ARSearch.discountamount')" >
          <template slot-scope="scope">
            <span>{{ scope.row.discountamount | MoneyFormat }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="unoffsetoamount" align="right" :label="$t('ARSearch.lateamount')" >
          <template slot-scope="scope">
            <span>{{ scope.row.unoffsetoamount | MoneyFormat }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </Frame>
</template>

<script>
import { handleShortDate } from '../utils/handleDate.js'
import SvgIcon from '../components/SvgIcon'
export default {
  name: 'ARSearch',
  components: { SvgIcon },
  data() {
    return {
      tableLoading: false,
      tableHeight: window.innerHeight - 268,
      date: '',
      compName:'',
      startdate: '',
      enddate: '',
      timeDefault:'',
      tableData: [{
        billtype: '',
        billno: '',
        billdate: '',
        amountreceivable: '',
        currentamount: '',
        discountamount: '',
        unoffsetoamount: ''
      }]
    }
  },
  mounted() {
    let that = this;
    window.onresize = () => {
      if (that.resizeFlag) {
        clearTimeout(that.resizeFlag);
      }
      that.resizeFlag = setTimeout(() => {
        that.tableHeight = window.innerHeight - 268;
        that.resizeFlag = null;
      }, 100);
    }
    this.timeDefault =new Date();
    this.timeDefault.setMonth(new Date().getMonth()-1);
    //同旧版 进入页面时默认取近一个月的数据
    this.startdate = handleShortDate(new Date(new Date().getTime() - 30 * 3600 * 24 * 1000), "From");
    this.enddate = handleShortDate(new Date(), "From");
    this.date = [new Date(new Date().getTime() - 30 * 3600 * 24 * 1000), new Date()];
    this.getinfo();
  },
  methods: {
    search(){
      this.getinfo();
    },
    gettime() {
      if(!this.date) {
        this.startdate = "00000000";
        this.enddate = "99999900";
        this.getinfo();
        return;
      }
      this.startdate = handleShortDate(this.date[0], "From");
      this.enddate = handleShortDate(this.date[1], "To");
      this.getinfo();
    },
    getinfo() {
      this.tableLoading = true;
      this.axiosInvoke("/SupplierComm/Handler/OrderPurPurchaseHandler.ashx",
        {
          action: "getapfunddetailrpt",
          dateRangeEnd: this.startdate,
          dateRangeBegin: this.enddate,
          compName:this.compName
        },
        (res,errflag) => {
        if(errflag) { this.tableLoading = false; this.tableData = null; return;}
        var data = eval(res.data);
        this.tableData = data;
        for(var i=0;i<this.tableData.length;i++){
          if(this.tableData[i].billtype == "") this.tableData[i].billtype = this.$t('ARSearch.billTypeAuto') // "期初应付";
          this.tableData[i].billtype = this.tableData[i].billtype.replace(this.$t('ARSearch.typeNameYF'),this.$t('ARSearch.typeNameYS'));
        }
        this.tableLoading = false;
      }
      )
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = this.$t("ARSearch.summary");
          return;
        }
        if (index == 1 || index == 2 || index == 3) return;
        sums[index] = 0
        data.forEach(v=>{
          if(v[column.property] != "-----") {
            sums[index] += Number(v[column.property].replace(/,/g,""))*100000;
          }
        })
        let sumstr = String(sums[index]/100000);
        sums[index] = sumstr.substring(0,sumstr.indexOf(".")).replace(/\B(?=(\d{3})+(?!\d))/g,",")+sumstr.substring(sumstr.indexOf(".")); 
      });
      return sums;
    },
    query() {//查询
      this.getinfo();
    },
    //加载el-tooltip信息，鼠标聚焦时显示完整信息
    renderTitle (h,{column}) {
      let titleValue = '';
      let columnRealWidth = column.realWidth;
      switch(column.property){
          case'billtype':
              titleValue = this.$t("ARSearch.documenttype");
          break;
          case'billno':
              titleValue = this.$t("ARSearch.messagenumber");
          break;
          case'CompName':
              titleValue = this.$t("ARSearch.compName");
          break;
          case'billdate':
              titleValue = this.$t("ARSearch.date");
          break;
          case'amountreceivable':
              titleValue = this.$t("ARSearch.amountreceivable");
          break;
          case'currentamount':
              titleValue = this.$t("ARSearch.currentamount");
          break;
          case'discountamount':
              titleValue = this.$t("ARSearch.discountamount");
          break;
          case'unoffsetoamount':
              titleValue = this.$t("ARSearch.lateamount");
          break;
          default:
              titleValue = 'ARSearch默认栏位信息（测试）'
              break;
      }
      return this.renderTooltip(h,titleValue,columnRealWidth)
    }
  }
}
</script>

<style scoped>
.detialmessage {
  color: #5d5d5d;
  font-size: 14px;
  width: 20%;
  padding-left: 10px;
  padding-right: 10px;
}

.ARmessage {
  padding: 10px 20px;
}

.el-checkbox /deep/ .el-checkbox__label {
  color: #5d5d5d;
  font-size: 14px;
}

.el-table /deep/ .el-table__header .cell {
    overflow: hidden;
    white-space: nowrap;
}

.el-table /deep/ th.el-table__cell {
  background-color: #f9f9f9;
}

.el-table /deep/ .el-table__body-wrapper {
  font-size: 13px;
}

.el-table /deep/ thead {
  font-size: 14px;
  color: #5d5d5d;
}

.topbutton {
  color: #777777;
  font-size: 14px;
}
</style>
